import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useSubscription } from "@apollo/client";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslations } from "use-intl";

import { IEdgeType } from "../../../types/paginated.type";
import { Image as DBImage } from "../../../types/image.type";
import { ImageType } from "../../../types/image-type.enum";
import { Album } from "../../../types/album.type";
import { useAuth, useIsInIframe } from "../../../hooks";
import { getImageUrl } from "../../../lib/utils";
import { Loading, LogoBadge, QRCode } from "../../../common";
import { AnimatedThumbnail, EnterFullScreen, ImageCaption, Slide } from ".";
import {
  addedImageSubscription,
  removedImageSubscription,
} from "../../../graphql/imageQueries";

interface PhotoWallProps {
  album: Album;
  images: IEdgeType<DBImage>[];
  loadMore: (cursor: string) => void;
  hasNextPage: boolean;
}

const PhotoWall: React.FC<PhotoWallProps> = React.memo(
  ({ album, images, loadMore, hasNextPage }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const { user } = useAuth();
    const [allImages, setAllImages] = useState<IEdgeType<DBImage>[]>(images);
    const [animatedImages, setAnimatedImages] = useState<DBImage[]>([]);
    const t = useTranslations("PhotoWall");
    const isInIframe = useIsInIframe();

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const lastTimeoutStartRef = useRef<number>(0);
    const lastIntervalTimeRef = useRef<number>(0);

    const albumUrl = useMemo(
      () => `${window.location.origin}/album/${album?.shortId}`,
      [album?.shortId]
    );

    const getIntervalTime = useCallback(
      (image: DBImage) =>
        image.type === ImageType.VIDEO
          ? album.videoDuration * 1000
          : album.imageDuration * 1000,
      [album.videoDuration, album.imageDuration]
    );

    const preloadNextAsset = useCallback((nextImage: DBImage) => {
      if (!nextImage) return Promise.resolve();

      const nextUrl = getImageUrl(nextImage.url);

      return new Promise<void>((resolve, reject) => {
        if (nextImage.type === ImageType.VIDEO) {
          const video = document.createElement("video");
          video.src = nextUrl;
          video.preload = "auto";
          video.onloadeddata = () => {
            video.remove();
            resolve();
          };
          video.onerror = reject;
        } else {
          const img = new Image();
          img.src = nextUrl;
          img.onload = () => resolve();
          img.onerror = reject;
        }
      });
    }, []);

    const handleImageAdded = useCallback(
      (imageAdded: DBImage) => {
        if (album && imageAdded?.album.id === album.id) {
          setAnimatedImages((prevImages) => [...prevImages, imageAdded]);
          setAllImages((currentImages) => {
            const nextImages = [...currentImages];
            const nextPosition = currentSlide + 1;
            nextImages.splice(nextPosition, 0, {
              node: imageAdded,
              cursor: imageAdded.id,
            });
            return nextImages;
          });
          preloadNextAsset(imageAdded).catch(console.error);
        }
      },
      [album, currentSlide, preloadNextAsset]
    );

    const handleImageRemoved = useCallback(
      (imageRemoved: DBImage) => {
        if (album && imageRemoved?.album.id === album.id) {
          setAllImages((currentImages) => {
            const nextImages = currentImages.filter(
              (image) => image.node.id !== imageRemoved.id
            );
            if (currentSlide >= nextImages.length) {
              setCurrentSlide(0);
            }
            return nextImages;
          });
        }
      },
      [album, currentSlide]
    );

    useEffect(() => {
      const currentImage = allImages[currentSlide]?.node;
      if (!currentImage) return;

      const intervalTime = getIntervalTime(currentImage);
      const currentTime = Date.now();

      const scheduleNextSlide = () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          setCurrentSlide((prevSlide) => (prevSlide + 1) % allImages.length);
        }, intervalTime);
        lastTimeoutStartRef.current = currentTime;
        lastIntervalTimeRef.current = intervalTime;
      };

      // Check if there's an active timeout and it hasn't finished yet
      if (
        timeoutRef.current &&
        currentTime - lastTimeoutStartRef.current < lastIntervalTimeRef.current
      ) {
        // Don't schedule a new timeout, let the existing one finish
        return;
      }

      scheduleNextSlide();

      if (currentSlide === allImages.length - 1 && hasNextPage) {
        loadMore(allImages[allImages.length - 1].cursor);
      }

      //Preload next image or video
      const nextSlide = (currentSlide + 1) % allImages.length;
      const nextImage = allImages[nextSlide]?.node;
      if (nextImage) {
        preloadNextAsset(nextImage).catch((error) => {
          console.error("Failed to preload next asset:", error);
        });
      }

      // Cleanup function
      return () => {
        // Only clear the timeout if it has completed its intended duration
        if (
          timeoutRef.current &&
          Date.now() - lastTimeoutStartRef.current >=
            lastIntervalTimeRef.current
        ) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
      };
    }, [
      currentSlide,
      allImages,
      hasNextPage,
      loadMore,
      getIntervalTime,
      preloadNextAsset,
    ]);

    useEffect(() => {
      setAllImages((currentAllImages) => {
        const newImagesMap = new Map(images.map((img) => [img.node.id, img]));
        const updatedImages = currentAllImages.filter((img) =>
          newImagesMap.has(img.node.id)
        );
        const newImages = images.filter(
          (img) =>
            !currentAllImages.some((current) => current.node.id === img.node.id)
        );
        return [...updatedImages, ...newImages];
      });
    }, [images]);

    useSubscription(addedImageSubscription, {
      skip: !album || isInIframe,
      onData: ({ data }) => {
        if (data?.data?.imageAdded) {
          handleImageAdded(data.data.imageAdded);
        }
      },
      variables: {
        albumId: album?.id,
        ...(user.isGuest && { guestUserId: user.id }),
      },
    });

    useSubscription(removedImageSubscription, {
      skip: !album || isInIframe,
      onData: ({ data }) => {
        if (data?.data?.imageRemoved) {
          handleImageRemoved(data.data.imageRemoved);
        }
      },
      variables: {
        albumId: album?.id,
        ...(user.isGuest && { guestUserId: user.id }),
      },
    });

    const onAnimationComplete = useCallback((completedImage: DBImage) => {
      setAnimatedImages((current) =>
        current.filter((img) => img.id !== completedImage.id)
      );
    }, []);

    const currentImage = allImages[currentSlide]?.node;
    if (!currentImage) return <Loading />;

    const imageUrl = getImageUrl(currentImage.url);
    const thumbnailUrl = getImageUrl(currentImage.thumbnailUrl);

    return (
      <div className="fixed inset-0 z-50 flex flex-wrap w-screen h-screen overflow-hidden">
        {!isInIframe && <LogoBadgeWrapper album={album} />}
        {album.wallDisplayCode && (
          <div className="absolute right-0 top-0 z-50 md:flex hidden">
            <QRCode size={242} label={t("scan")} url={albumUrl} />
          </div>
        )}
        {!isInIframe && <EnterFullScreen />}
        {animatedImages.map((img) => (
          <AnimatedThumbnail
            key={img.id}
            image={img}
            onComplete={onAnimationComplete}
          />
        ))}
        <AnimatePresence initial={false}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            key={currentImage.id + "-bg"}
            className="absolute inset-0 z-10"
          >
            <div
              className="h-full w-full bg-cover bg-center"
              style={{
                backgroundImage: `url(${
                  currentImage.type === ImageType.VIDEO
                    ? thumbnailUrl
                    : imageUrl
                })`,
              }}
            />
          </motion.div>
        </AnimatePresence>
        {currentImage.description &&
          currentImage.contributor &&
          album.wallDisplayCaptions &&
          !isInIframe && <ImageCaption image={currentImage} />}
        <div className="relative flex w-full h-full justify-center items-center z-30">
          <AnimatePresence initial={false}>
            {allImages.length > 0 && (
              <Slide
                key={currentSlide}
                image={currentImage}
                currentIndex={currentSlide}
              />
            )}
          </AnimatePresence>
        </div>
        <div
          key={currentImage.id + "-overlay"}
          className="absolute inset-0 z-20 bg-black/85"
        />
      </div>
    );
  }
);

const LogoBadgeWrapper = React.memo(({ album }: { album: Album }) => (
  <div className="z-50">
    {album.logo ? (
      <img
        className="h-20 object-contain rounded-b-lg absolute left-8"
        src={getImageUrl(album.logo)}
        alt="Album logo"
      />
    ) : (
      <LogoBadge album={album} />
    )}
  </div>
));

export default PhotoWall;
