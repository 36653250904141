import { ShortLogo } from ".";
import { useAuth, useIsSmallScreen, useLocalizedNavigate } from "../hooks";
import { LocalizedLink } from "../router";
import { Album, PublicAlbum } from "../types/album.type";

const LogoBadge = ({ album }: { album?: Album | PublicAlbum }) => {
  const { user } = useAuth();
  const navigate = useLocalizedNavigate();
  const isSmallScreen = useIsSmallScreen();

  // Determine if the user is the creator of the album
  const isCreator = album && user && album.user.id === user.id;

  const handleClick = () => {
    if (!isCreator && isSmallScreen) {
      navigate(-1);
    }
  };

  const BadgeContent = () => (
    <div
      className="h-[47px] w-[53px] absolute left-8 top-0 z-30"
      onClick={handleClick}
    >
      <div className="relative w-full h-full flex items-center justify-center overflow-hidden rounded-b-lg">
        <ShortLogo />
        <div className=" -z-0 blur-lg opacity-50 bg-white absolute inset-0"></div>
      </div>
    </div>
  );

  return isCreator ? (
    <LocalizedLink to={"/"}>
      <BadgeContent />
    </LocalizedLink>
  ) : (
    <BadgeContent />
  );
};

export default LogoBadge;
