import { CellContext, ColumnDef } from "@tanstack/react-table";
import { Text } from "../common";
import { Album } from "../types/album.type";
import { Payment } from "../types/payment.type";
import moment from "moment";

export const badgePaymentsColumns: ColumnDef<Payment, any>[] = [
  {
    header: "Purchase date",
    accessorKey: "createdAt",
    cell: (props: CellContext<Payment, string>) => (
      <Text size="sm">{moment(props.getValue()).format("YYYY-MM-DD LT")}</Text>
    ),
    enableColumnFilter: false, // disable column filtering for this column
  },
  {
    header: "Event date",
    accessorKey: "album",
    accessorFn: (data) => data.album,
    cell: (props: CellContext<Payment, Album>) => (
      <Text size="sm">
        {props.getValue()?.eventDate
          ? moment(props.getValue()?.eventDate).format("YYYY-MM-DD LT")
          : "-"}
      </Text>
    ),
    enableColumnFilter: false, // disable column filtering for this column
  },
  {
    header: "Payment ID",
    accessorKey: "paymentId",
    cell: (props: CellContext<Payment, string>) => (
      <Text size="sm">{`${
        props.getValue()?.length > 0 ? props.getValue() : "-"
      }`}</Text>
    ),
    enableColumnFilter: false, // disable column filtering for this column
  },
  {
    header: "Payment status",
    accessorKey: "paymentStatus",
    cell: (props: CellContext<Payment, string>) => (
      <Text size="sm">{props.getValue()}</Text>
    ),
    enableColumnFilter: false, // disable column filtering for this column
  },
  {
    header: "Album email",
    accessorKey: "album",
    accessorFn: (data) => data.album,
    cell: (props: CellContext<Payment, Album>) => (
      <Text size="sm">{`${props.getValue()?.user?.email ?? "-"}`}</Text>
    ),
    enableColumnFilter: false, // disable column filtering for this column
  },
  {
    header: "Album type",
    accessorKey: "album",
    accessorFn: (data) => data.album,
    cell: (props: CellContext<Payment, Album>) => (
      <Text size="sm">{`${props.getValue()?.type ?? "-"}`}</Text>
    ),
    enableColumnFilter: false, // disable column filtering for this column
  },
  {
    header: "Plan",
    accessorKey: "album",
    accessorFn: (data) => data.album,
    cell: (props: CellContext<Payment, Album>) => (
      <Text size="sm">{`${props.getValue()?.plan ?? "-"}`}</Text>
    ),
    enableColumnFilter: false, // disable column filtering for this column
  },
  {
    header: "Size",
    accessorKey: "album",
    accessorFn: (data) => data.album,
    cell: (props: CellContext<Payment, Album>) => (
      <Text size="sm">{`${props.getValue()?.maxSize ?? "-"}`}</Text>
    ),
    enableColumnFilter: false, // disable column filtering for this column
  },
  {
    header: "Album",
    accessorKey: "album",
    accessorFn: (data) => data.album,
    cell: (props: CellContext<Payment, Album>) => (
      <Text size="sm">{`${props.getValue()?.name ?? "-"}`}</Text>
    ),
    enableColumnFilter: false, // disable column filtering for this column
  },
  {
    header: "Processed",
    accessorKey: "processed",
    enableColumnFilter: true,
    cell: (props: CellContext<Payment, boolean>) => (
      <Text size="sm">{props.getValue() ? "Yes" : "No"}</Text>
    ),
    filterFn: "equalsString",
    meta: {
      filterType: "select",
      filterOptions: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
    },
  },
];
