import React, { useEffect, useState, useMemo } from "react";
import { useTranslations } from "use-intl";
import { useSelector } from "react-redux";
import { Checkbox, Tooltip } from "@mantine/core";
import { Button, InfoToolitp, Text, Title } from "../../../common";
import { PlanProducts } from "../../../config/pricing.config";
import { RootState } from "../../../store";

interface PlanCardProps {
  plan: string;
  priceDescription: string;
  planDescription: string;
  included: Array<{ title: string; details?: string }>;
  badgeText?: string;
  storageOptions: number[];
  currency?: "$" | "€";
  discountedPrice?: number;
  productNamespace: keyof typeof PlanProducts;
  onPurchase: (productId: string, withBadge?: boolean) => void;
  popular?: number;
  loading: boolean;
  defaultStorage?: number;
}

const PlanCard: React.FC<PlanCardProps> = ({
  plan,
  priceDescription,
  planDescription,
  included,
  badgeText,
  storageOptions,
  currency = "$",
  discountedPrice,
  productNamespace,
  onPurchase,
  popular,
  loading,
  defaultStorage,
}) => {
  const t = useTranslations("Pricing");
  const album = useSelector((state: RootState) => state.album.album);

  const defaultIndex = useMemo(
    () => storageOptions.indexOf(defaultStorage || storageOptions[0]),
    [storageOptions, defaultStorage]
  );

  const [choosenStorage, setChoosenStorage] = useState(
    storageOptions[defaultIndex]
  );
  const [buyBadge, setBuyBadge] = useState(false);
  const [upgrading, setUpgrading] = useState(false);

  const product = useMemo(
    () => PlanProducts[productNamespace][choosenStorage],
    [productNamespace, choosenStorage]
  );

  useEffect(() => {
    if (upgrading && !loading) {
      setUpgrading(false);
    }
  }, [loading, upgrading]);

  const handleUpgrade = async () => {
    setUpgrading(true);
    if (product) {
      onPurchase(product.priceId, buyBadge);
      window.dataLayer.push({
        event: "planPurchase",
        transactionId: album?.id,
        transactionTotal: product.price || 0,
      });
    }
  };

  const renderStorageOptions = () => (
    <div className="flex gap-2 mt-2">
      {storageOptions.map((option) => {
        const isPopular = popular === option;
        return (
          <Tooltip
            key={option}
            disabled={!isPopular}
            label={t("popularStorage")}
          >
            <div
              onClick={() => setChoosenStorage(option)}
              className={`relative px-4 text-sm py-2 border rounded-full cursor-pointer ${
                choosenStorage === option
                  ? "border-medium-black bg-light-gray"
                  : "border-black/10"
              }`}
            >
              {option}
              {isPopular && <span className="absolute -top-2 right-0">🔥</span>}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );

  const renderIncludedItems = () => (
    <ul className="mt-2 list-image-checkmark">
      {storageOptions.length > 1 && (
        <li className="pt-2 ml-5">
          <div className="flex items-center gap-[6px]">
            <Text>
              {t.rich("gbForPhotosVideos", {
                size: () => (
                  <span className="font-semibold">{choosenStorage}GB</span>
                ),
              })}
            </Text>
            <InfoToolitp text={t("gbTooltip")} />
          </div>
        </li>
      )}
      {included.map((item, index) => (
        <li className="pt-2 ml-5" key={index}>
          <div className="flex items-center gap-[6px]">
            <Text>
              {item.title}
              {item.details && <InfoToolitp text={item.details} />}
            </Text>
          </div>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="md:rounded-lg bg-white md:py-6 py-4 px-6 flex-1">
      <div className="flex gap-4 justify-between">
        <Title wrap="nowrap" size="H5">
          {plan}
        </Title>
        {badgeText && (
          <div className="rounded-lg px-4 py-1 bg-light-beige flex items-center">
            <Text wrap="nowrap" size="xs" className="font-semibold">
              {badgeText}
            </Text>
          </div>
        )}
      </div>
      <div className="flex pb-4 pt-3 items-center">
        <Title size="H2">
          {currency}
          {product?.price}
        </Title>
        {discountedPrice && (
          <span className="ml-2 font-poppins text-2xl text-black/50 line-through">
            {currency}
            {discountedPrice}
          </span>
        )}
        <Text className="pl-2" size="sm">
          {priceDescription}
        </Text>
      </div>
      <Text color="gray" size="sm">
        {planDescription}
      </Text>
      <div className="mt-4">
        <Text color="gray" size="sm">
          {storageOptions.length > 1
            ? t.rich("selectStorage", {
                link: (chunk) => (
                  <a href="#faq" className="underline text-sm">
                    {chunk}
                  </a>
                ),
              })
            : t("storageAmmount")}
        </Text>
        {renderStorageOptions()}
      </div>
      <div className="w-full border-b border-light-beige my-6" />
      <div>
        <Text className="font-semibold" size="sm">
          {t("whatsIncluded")}
        </Text>
        {renderIncludedItems()}
      </div>
      <div className="w-full border-b border-light-beige my-6" />
      <div className="flex gap-2 items-center">
        <Checkbox
          color="#222"
          checked={buyBadge}
          onChange={(event) => setBuyBadge(event.currentTarget.checked)}
        />
        {t.rich("orderBadge", { currency: () => currency })}
        <InfoToolitp text={t("orderBadgeTooltip")} />
      </div>
      <div className="mt-6">
        <Button
          className="purchase-button"
          loading={upgrading}
          fullWidth
          title={buyBadge ? t("orderAlsoBadge") : t("upgrade")}
          onClick={handleUpgrade}
        />
      </div>
    </div>
  );
};

export default PlanCard;
