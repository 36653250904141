import { useAuth } from "../hooks";
import { Album, PublicAlbum } from "../types/album.type";
import { LocalizedLink } from "../router";

const ShortLogo = ({
  small = false,
  album,
}: {
  small?: boolean;
  album?: Album | PublicAlbum;
}) => {
  const { user } = useAuth();

  // Determine if the user is the creator of the album
  const isCreator = album && user && album.user.id === user.id;

  const LogoContent = () => (
    <span
      className={`z-10 font-poppins ${
        small ? "text-lg" : "text-[24px]"
      } font-semibold`}
    >
      R.
    </span>
  );

  return isCreator ? (
    <LocalizedLink to={"/"}>
      <LogoContent />
    </LocalizedLink>
  ) : (
    <LogoContent />
  );
};

export default ShortLogo;
