import { useTranslations } from "use-intl";
import Text from "./Text";
import { Modal } from "@mantine/core";
import Title from "./Title";
import Button from "./Button";
import { useState, useEffect } from "react";

const isIOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream
  );
};

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return (
    ua.includes("safari") && !ua.includes("chrome") && !ua.includes("android")
  );
};

const getIOSVersion = () => {
  const match = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return match ? parseInt(match[1], 10) : null;
};

const isIOS18 = () => {
  const version = getIOSVersion();
  return version !== null && version >= 18;
};

const TechnicalIssueModalAlert = () => {
  const [isOpen, setIsOpen] = useState(false);
  const t = useTranslations("TechnicalIssueAlert");

  useEffect(() => {
    const shouldShowAlert =
      isIOS() &&
      isSafari() &&
      isIOS18() &&
      !localStorage.getItem("technicalIssueAlertClosed");
    setIsOpen(shouldShowAlert);
  }, []);

  const close = () => {
    localStorage.setItem("technicalIssueAlertClosed", "true");
    setIsOpen(false);
  };

  return (
    <Modal
      radius={8}
      opened={isOpen}
      onClose={close}
      withCloseButton={false}
      centered
      closeOnClickOutside={false}
    >
      <div className="my-4">
        <Title size="H4">{t("title")}</Title>
        <Text className="mt-2">{t("start")}</Text>
        <ul className="list-disc my-6 ml-6 space-y-2">
          <li>
            <Text>{t("one")}</Text>
          </li>
          <Text className="-ml-4">{t("orAnd")}</Text>
          <li>
            <Text>{t("two")}</Text>
          </li>
        </ul>
        <Text>{t("conculsion")}</Text>
      </div>
      <div className="flex justify-end pt-4">
        <Button onClick={close} title={t("ok")} />
      </div>
    </Modal>
  );
};

export default TechnicalIssueModalAlert;
