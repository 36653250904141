import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Input,
  RoundedBox,
  ShareAlbumModal,
  Text,
  Title,
} from "../../../common";
import { openHowTo } from "../../../store/howto.reducer";
import { RootState } from "../../../store";
import { useTranslations } from "use-intl";
import { useDisclosure } from "@mantine/hooks";
import { CopyButton } from "@mantine/core";
import { IconCopy } from "@tabler/icons-react";
import PhotoWallPreview from "./PhotoWallPreview";

const ShareBlock = ({
  albumUrl,
  t,
  opened,
  open,
  close,
}: {
  albumUrl: string;
  t: any;
  opened: boolean;
  open: () => void;
  close: () => void;
}) => (
  <div>
    <Input
      className="flex-1 bg-light-gray rounded-lg px-4 py-3"
      styles={{
        input: {
          border: "none",
          fontSize: 16,
          backgroundColor: "transparent",
          height: "auto",
          minHeight: "auto",
          lineHeight: 0,
          padding: 0,
        },
      }}
      onChange={() => {}}
      value={albumUrl}
    />
    <div className="flex justify-end">
      <CopyButton value={albumUrl}>
        {({ copied, copy }) => (
          <Text
            size="sm"
            onClick={copy}
            className="font-semibold ml-4 cursor-pointer mt-2 flex gap-2 items-center"
          >
            <IconCopy size={16} className="stroke-black" />
            {copied ? t("copied") : t("copy")}
          </Text>
        )}
      </CopyButton>
    </div>
    <ShareAlbumModal
      photoWall
      albumUrl={albumUrl}
      opened={opened}
      close={close}
    />
    <div className="flex gap-6 mt-6 flex-wrap">
      <div className="flex-1">
        <Button
          fullWidth
          type="outline"
          data-ph-capture-attribute-event="openPhotoWallClickedDashboard"
          title={t("open")}
          color="black"
          onClick={() => window.open(albumUrl)}
        />
      </div>
      <div className="flex-1">
        <Button fullWidth type="outline" onClick={open} title={t("share")} />
      </div>
    </div>
  </div>
);

const PhotoWallDashboard = () => {
  const dispatch = useDispatch();
  const album = useSelector((root: RootState) => root.album.album);
  const t = useTranslations("Dashboard");
  const albumUrl = `${window.location.origin}/wall/${album?.shortId}`;
  const [shareOpened, { close: closeShare, open: openShare }] =
    useDisclosure(false);

  const openHowItWorksPhotowall = () => {
    dispatch(openHowTo("photowall"));
  };

  return (
    <RoundedBox roundedOnSmallScreens={false} className="">
      <div className="flex justify-between gap-6">
        <div className="">
          <Title size="H5">{t("yourPhotoWall")}</Title>
        </div>
        <div>
          <span
            className="cursor-pointer font-semibold whitespace-nowrap text-black hover:text-very-black"
            onClick={openHowItWorksPhotowall}
          >
            {t("howItWorks")}
          </span>
        </div>
      </div>
      <div>
        <Text color="gray" className="mt-2">
          {t("yourPhotoWallDescription")}
        </Text>
      </div>
      <div className="flex flex-col xl:flex-row gap-6 md:gap-16 pt-10 xl:items-center justify-center">
        <div className="flex flex-1">
          <PhotoWallPreview album={album} />
        </div>
        <div className="flex-1">
          <ShareBlock
            albumUrl={albumUrl}
            t={t}
            opened={shareOpened}
            close={closeShare}
            open={openShare}
          />
        </div>
      </div>
    </RoundedBox>
  );
};

export default PhotoWallDashboard;
