import { PublicAlbum } from "../../../types/album.type";
import { EventType } from "../../../types/event.enum";
import MemorialAlbumHeader from "./MemorialAlbumHeader";
import EventAlbumHeader from "./EventAlbumHeader";

const AlbumHeader = ({
  album,
  disabled,
  onUploadComplete,
  hasImages,
  loadData,
  fileUploadTooltip,
}: {
  hasImages?: boolean;
  album: PublicAlbum;
  disabled?: boolean | undefined;
  onUploadComplete: () => void;
  loadData: () => Promise<number | void>;
  fileUploadTooltip?: string;
}) => {
  if (album.type === EventType.MEMORIAL) {
    return (
      <MemorialAlbumHeader
        loadData={loadData}
        album={album}
        disabled={disabled}
        onUploadComplete={onUploadComplete}
        fileUploadTooltip={fileUploadTooltip}
      />
    );
  } else {
    return (
      <EventAlbumHeader
        fileUploadTooltip={fileUploadTooltip}
        loadData={loadData}
        hasImages={hasImages}
        disabled={disabled}
        onUploadComplete={onUploadComplete}
        album={album}
      />
    );
  }
};

export default AlbumHeader;
