import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { IconQrcode, IconTruckDelivery } from "@tabler/icons-react";
import { BadgeOrdersTable, BadgeTable } from "./components";
import { Tabs } from "@mantine/core";
import { Helmet } from "react-helmet-async";

const BadgesPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const defaultTab = searchParams.get("tab") || "badges";

  const clearQueryParams = () => {
    navigate(location.pathname, { replace: true });
  };

  return (
    <div className="flex-1 md:px-6 px-4 flex flex-col">
      <Helmet>
        <title>Rompolo - Badges</title>
      </Helmet>
      <Tabs
        keepMounted={false}
        defaultValue={defaultTab}
        color="#222"
        radius="md"
        onChange={() => {
          clearQueryParams();
        }}
      >
        <Tabs.List>
          <Tabs.Tab
            leftSection={<IconQrcode className="stroke-black" size={16} />}
            value={"badges"}
          >
            Badges
          </Tabs.Tab>
          <Tabs.Tab
            leftSection={
              <IconTruckDelivery className="stroke-black" size={16} />
            }
            value={"orders"}
          >
            Badge orders
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="badges">
          <BadgeTable />
        </Tabs.Panel>
        <Tabs.Panel value="orders">
          <BadgeOrdersTable />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default BadgesPage;
