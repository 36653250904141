import { useDisclosure } from "@mantine/hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslations } from "use-intl";
import { Badge } from "../../../types/badge.type";
import { useSearchParamsState } from "../../../hooks";
import { useQuery } from "@apollo/client";
import { PaginatedType } from "../../../types/paginated.type";
import { OperationType } from "../../../types/operation-type.enum";
import { badgesQuery } from "../../../graphql/badgeQueries";
import { ErrorService } from "../../../services";
import { Button, Input, ReactTable } from "../../../common";
import { Loader, Pagination } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import { badgeColumns } from "../../../columns/BadgeColumns";
import CreateBadge from "./CreateBadge";
import debounce from "lodash/debounce";

const BadgeTable = () => {
  const t = useTranslations("Badges");
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedBadge, setSelectedBadge] = useState<Badge>();
  const [queryParams, setQueryParams] = useSearchParamsState({
    search: "",
    page: 1,
    limit: 12,
  });
  const [searchValue, setSearchValue] = useState(queryParams.search);

  const { loading, error, data, refetch } = useQuery<
    { badges: PaginatedType<Badge> },
    {
      first?: number;
      offset?: number;
      paymentId?: string;
      details?: string;
      shortId?: string;
      albumId?: string;
      operationType?: OperationType;
    }
  >(badgesQuery, {
    variables: {
      first: queryParams.limit,
      offset: (queryParams.page - 1) * queryParams.limit,
      ...(queryParams.search && {
        paymentId: queryParams.search,
        details: queryParams.search,
        shortId: queryParams.search,
        albumId: queryParams.search,
        operationType: OperationType.OR,
      }),
    },
    fetchPolicy: "no-cache",
  });

  // Set search value to queryParams.search if it is different from searchValue
  useEffect(() => {
    if (queryParams.search !== searchValue) {
      setSearchValue(queryParams.search);
    }
  }, [queryParams.search]);

  const loadMore = (newPage: number) => {
    setQueryParams({ page: newPage });
  };

  useEffect(() => {
    if (error) {
      ErrorService.showError(t("errorFetching"));
    }
  }, [error, t]);

  const onCreated = () => {
    refetch();
  };

  const debouncedSetQueryParams = useMemo(
    () =>
      debounce((search: string) => {
        setQueryParams({ search, page: 1 });
      }, 300),
    []
  );

  useEffect(() => {
    debouncedSetQueryParams(searchValue);
  }, [searchValue]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const search = event.target.value;
      setSearchValue(search);
    },
    []
  );

  const totalCount = data?.badges.totalCount ?? 0;

  return (
    <div>
      <CreateBadge
        badge={selectedBadge}
        opened={opened}
        close={() => {
          close();
          setSelectedBadge(undefined);
        }}
        onCreated={onCreated}
      />
      <div className="flex md:items-center gap-4 flex-col md:flex-row mt-8">
        <div className="flex flex-col md:flex-row gap-6 w-full flex-1">
          <Input
            rightSection={loading && <Loader color="#222222" size={16} />}
            onChange={handleSearchChange}
            value={searchValue}
            placeholder={t("search")}
            className="w-full flex-1"
          />
          <Button
            onClick={open}
            icon={<IconPlus stroke={3} size={16} className=" stroke-white" />}
            title={t("addNew")}
          />
        </div>
      </div>
      <div className=" mt-8">
        <ReactTable<Badge>
          search={queryParams.search}
          loading={loading}
          onRowClick={(badge) => {
            setSelectedBadge(badge);
            open();
          }}
          columns={badgeColumns}
          data={data?.badges.nodes ?? []}
          initialPageSize={queryParams.limit}
          emptyMessage={t("noBadges")}
        />
        <Pagination
          className="mt-6"
          classNames={{
            control:
              "text-black hover:bg-black hover:text-white border-medium-black data-[active=true]:bg-black data-[active=true]:text-white",
          }}
          size={"sm"}
          value={queryParams.page}
          total={Math.ceil(totalCount / queryParams.limit)}
          onChange={loadMore}
        />
      </div>
    </div>
  );
};

export default BadgeTable;
