import axios from "axios";
import { AuthService } from "../services";

const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  withCredentials: true,
  timeout: 0,
});

axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    console.log(error.response.status, "error");

    // Check if the error is due to a 401 Unauthorized response
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const accessToken = await AuthService.refreshToken();
        if (accessToken) {
          // Resend the original request with the new token
          return axiosClient(originalRequest);
        } else {
          // Token refresh failed, redirect to login or handle accordingly
          throw new Error("Failed to refresh token");
        }
      } catch (refreshError) {
        const error = refreshError as Error;
        // Handle errors in token refresh or request retry
        throw new Error(error.message);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
