import { useDisclosure } from "@mantine/hooks";
import { IconX } from "@tabler/icons-react";
import { useEffect } from "react";

const Info = ({
  children,
  name,
  withClose = true,
  className,
}: {
  children: React.ReactNode;
  name: string;
  withClose?: boolean;
  className?: string;
}) => {
  const alreadyShownAndClosed = localStorage.getItem(name) ?? "true";
  const [opened, { close }] = useDisclosure(JSON.parse(alreadyShownAndClosed));

  useEffect(() => {
    if (!opened) {
      localStorage.setItem(name, JSON.stringify(opened));
    }
  }, [opened]);

  if (opened) {
    return (
      <div
        className={`p-6 flex gap-2 bg-light-beige rounded-xl ${
          className || ""
        }`}
      >
        <div>{children}</div>
        {withClose && (
          <div>
            <IconX
              onClick={() => close()}
              size={24}
              className=" stroke-black cursor-pointer"
            />
          </div>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};

export default Info;
