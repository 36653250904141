import { useFormikContext } from "formik";
import { CreateAlbumInput } from "../../types/album.type";
import Text from "../Text";
import { useLocale, useTranslations } from "use-intl";
import { DateFont } from "../../types/font.enum";
import moment from "moment";
import { EventType } from "../../types/event.enum";

const FontPreview = () => {
  const t = useTranslations("AlbumSettings");
  const formik = useFormikContext<CreateAlbumInput>();
  const dateFont =
    formik.values.dateFont === DateFont.Title
      ? formik.values.titleFont
      : formik.values.descriptionFont;
  const locale = useLocale();
  const eventDate = formik.values.eventDate
    ? moment(formik.values.eventDate).locale(locale).format("MMMM DD, YYYY")
    : undefined;
  const fromDate = formik.values.yearsFrom
    ? moment(formik.values.yearsFrom).locale(locale).format("MMMM DD, YYYY")
    : undefined;
  const toDate = formik.values.yearsTo
    ? moment(formik.values.yearsTo).locale(locale).format("MMMM DD, YYYY")
    : undefined;

  return (
    <div>
      <Text className="mb-4 " color="gray">
        {t("preview")}
      </Text>
      <div className=" rounded-lg bg-light-gray flex-1 py-10 px-10 flex flex-col items-center justify-center">
        {formik.values.type === EventType.MEMORIAL && (
          <Text
            font={formik.values.titleFont}
            className="uppercase tracking-wider mb-2"
          >
            {t("inLovingMemory")}
          </Text>
        )}
        <Text
          font={formik.values.titleFont}
          size="3xl"
          className="text-center font-bold"
        >
          {formik.values.name}
        </Text>
        {formik.values.type !== EventType.MEMORIAL
          ? eventDate && (
              <Text
                font={dateFont}
                className="mt-2 font-bold text-center uppercase"
              >
                {eventDate}
              </Text>
            )
          : fromDate &&
            toDate && (
              <Text font={dateFont} className="mt-2">
                {fromDate} - {toDate}
              </Text>
            )}
        <Text
          font={formik.values.descriptionFont}
          className=" line-clamp-2 text-center mt-6"
        >
          {formik.values.description}
        </Text>
      </div>
    </div>
  );
};

export default FontPreview;
