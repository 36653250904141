import { useLocale } from "use-intl";
import { Album, CreateAlbumInput } from "../../types/album.type";
import moment from "moment";
import Text from "../Text";
import { DateFont } from "../../types/font.enum";
import { useFormikContext } from "formik";

const AlbumDetails = ({
  album,
  center = true,
}: {
  album?: Album;
  center?: boolean;
}) => {
  const locale = useLocale();
  const eventDate =
    album?.eventDate &&
    moment(album.eventDate).locale(locale).format("MMMM DD, YYYY");
  const centerClassName = center ? "text-center" : "";
  const formik = useFormikContext<CreateAlbumInput>();

  const titleFont = formik?.values?.titleFont ?? album?.titleFont;
  const descriptionFont =
    formik?.values?.descriptionFont ?? album?.descriptionFont;
  const dateFontType = formik?.values?.dateFont ?? album?.dateFont;

  const dateFont =
    dateFontType === DateFont.Title ? titleFont : descriptionFont;

  return (
    <div className="">
      <Text
        size={5}
        font={titleFont}
        className={`font-bold ${centerClassName} line-clamp-1 overflow-hidden text-ellipsis`}
      >
        {formik?.values?.name ?? album?.name}
      </Text>
      {eventDate && (
        <Text
          font={dateFont}
          size={4}
          className={`font-bold ${centerClassName} uppercase`}
        >
          {eventDate}
        </Text>
      )}
      {(formik?.values?.description ?? album?.description) && (
        <Text
          font={descriptionFont}
          size={4}
          className={`line-clamp-3 mt-1 ${centerClassName}`}
          color="gray"
        >
          {formik?.values?.description ?? album?.description}
        </Text>
      )}
    </div>
  );
};

export default AlbumDetails;
