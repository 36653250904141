import { useEffect, useMemo, useState } from "react";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ScrollArea } from "@mantine/core";
import Filter from "./Filter";
import Pagination from "./Pagination";
import { Loading, Text } from "..";

// Define the props for the ReactTable component
interface ReactTableProps<T> {
  columns: ColumnDef<T, any>[];
  data: T[];
  loading?: boolean;
  page?: number;
  search?: string;
  withFilters?: boolean;
  emptyMessage: string;
  filterOnChange?: (fieldName: string, value: string | null) => void;
  debug?: boolean;
  initialPageSize?: number;
  onRowClick?: (row: T) => void;
  withPagination?: boolean;
  visibleColumns?: Record<string, boolean>;
  onPageChange?: (page: number) => void;
  manualFiltering?: boolean; // New prop to control manual (server-side) filtering
  useQueryParams?: boolean; // New prop to control whether to use query params for filtering
  initialState?: {
    columnFilters?: { id: string; value: any }[];
    globalFilter?: string;
  };
}

function ReactTable<T>({
  columns,
  data,
  loading = false,
  page = 1,
  search,
  withFilters = false,
  emptyMessage,
  filterOnChange,
  debug = false,
  initialPageSize = 20,
  onRowClick,
  withPagination = false,
  visibleColumns = {},
  onPageChange,
  manualFiltering = false,
  initialState,
}: ReactTableProps<T>) {
  const [columnVisibility, setColumnVisibility] = useState(visibleColumns);

  // Deserialize URL query to filters
  const deserializeColumnFilters = () => {
    const params = new URLSearchParams(window.location.search);
    const filters: { id: string; value: any }[] = [];
    const rangeFilters: Record<string, any> = {};

    Array.from(params.entries()).forEach(([key, value]) => {
      if (key !== "search" && key !== "tab") {
        if (key.endsWith("From") || key.endsWith("To")) {
          const baseKey = key.replace(/(From|To)$/, "");
          const rangeType = key.endsWith("From") ? "from" : "to";
          if (!rangeFilters[baseKey]) {
            rangeFilters[baseKey] = {};
          }
          rangeFilters[baseKey][rangeType] = value;
        } else {
          filters.push({ id: key, value });
        }
      }
    });

    // Add range filters to the main filters array
    Object.entries(rangeFilters).forEach(([key, value]) => {
      filters.push({ id: key, value });
    });

    return filters;
  };

  // Deserialize global filter from URL
  const deserializeGlobalFilter = () => {
    if (manualFiltering) return "";
    const params = new URLSearchParams(window.location.search);
    return params.get("search") || "";
  };

  const table = useReactTable<T>({
    data,
    columns,
    state: {
      columnVisibility,
      globalFilter: search,
      pagination: {
        pageIndex: page - 1,
        pageSize: initialPageSize,
      },
    },
    initialState: {
      globalFilter: initialState?.globalFilter ?? deserializeGlobalFilter(),
      columnFilters: initialState?.columnFilters ?? deserializeColumnFilters(),
    },
    ...(withFilters &&
      !manualFiltering && {
        getFilteredRowModel: getFilteredRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
      }),
    getColumnCanGlobalFilter: () => true,
    onColumnVisibilityChange: setColumnVisibility,
    defaultColumn: { maxSize: 300 },
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
    debugTable: debug,
    debugHeaders: debug,
    debugColumns: debug,
    // Enable manual filtering if specified
    manualFiltering,
  });

  const state = table.getState();

  // Calculate the number of columns for the empty message
  const colSpans = useMemo(() => {
    return table.getHeaderGroups().reduce((acc, headerGroup) => {
      return (
        acc +
        headerGroup.headers.filter((header) => header.subHeaders.length === 0)
          .length
      );
    }, 0);
  }, [columns]);

  // Serialize filters to URL
  useEffect(() => {
    // TODO: not working when object is for example { from: 1, to: 10 } in Images count filter
    if (manualFiltering) return;
    const currentColumnFilters = state.columnFilters;
    const params = new URLSearchParams(window.location.search);

    // Get all filter keys currently in the URL
    const currentFilterKeys = new Set(
      Array.from(params.keys()).filter(
        (key) => key !== "search" && key !== "tab"
      )
    );

    // Update or add new filters
    currentColumnFilters.forEach(({ id, value }) => {
      if (value !== undefined && value !== null && value !== "") {
        params.set(id, value as string);
        currentFilterKeys.delete(id);
      }
    });

    // Remove any filters that are no longer active
    currentFilterKeys.forEach((key) => {
      params.delete(key);
    });

    // Update the URL
    const newUrl = params.toString()
      ? `?${params.toString()}`
      : window.location.pathname;
    window.history.replaceState(null, "", newUrl);
  }, [state.columnFilters, manualFiltering]);

  const renderPagination = () => (
    <div className="mt-6">
      <Pagination
        pageSize={table.getState().pagination.pageSize}
        totalCount={table.getState().pagination.pageSize * table.getPageCount()}
        currentPage={table.getState().pagination.pageIndex + 1}
        onPageChange={(page) => {
          onPageChange && onPageChange(page);
        }}
      />
    </div>
  );

  const handleFilterChange = (columnId: string, value: any) => {
    if (manualFiltering && filterOnChange) {
      // For server-side filtering, call the provided callback
      filterOnChange(columnId, value);
    }
    const stringifiedValue =
      typeof value === "object" ? JSON.stringify(value) : value;
    table.getColumn(columnId)?.setFilterValue(stringifiedValue);
  };

  return (
    <>
      <ScrollArea className="flex">
        <table className="table-auto w-full rounded-lg overflow-hidden">
          <thead className="bg-light-beige/50 h-[56px]">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header, index) => (
                  <th
                    className="px-6 py-3"
                    key={header.id + index}
                    colSpan={header.colSpan}
                  >
                    <div className="flex gap-2 items-center">
                      <Text wrap="nowrap" size="sm" className="font-semibold">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </Text>
                      {header.column.getCanFilter() &&
                        !loading &&
                        withFilters &&
                        !header.isPlaceholder && (
                          <Filter
                            key={`filter-${header.id}`}
                            column={header.column}
                            onChange={(value) =>
                              handleFilterChange(header.column.id, value)
                            }
                            type={header.column.columnDef.meta?.filterType}
                            customFilterOptions={
                              header.column.columnDef.meta?.filterOptions
                            }
                          />
                        )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white">
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row, rowIndex) => (
                <tr
                  key={`row-${row.id}-${rowIndex}`}
                  className="hover:bg-light-gray"
                  onDoubleClick={() => onRowClick?.(row.original)}
                >
                  {row.getVisibleCells().map((cell, cellIndex) => (
                    <td
                      className="px-6 py-4 cursor-pointer"
                      key={`cell-${row.id}-${cell.column.id}-${cellIndex}`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={colSpans}>
                  {loading ? (
                    <Loading />
                  ) : (
                    <Text className="text-center py-10">{emptyMessage}</Text>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </ScrollArea>
      {withPagination && renderPagination()}
    </>
  );
}

export default ReactTable;
