import { useTranslations } from "use-intl";
import { Image } from "../../types/image.type";
import Text from "../Text";
import moment from "moment";
import { useAuth } from "../../hooks";
import { Album, PublicAlbum } from "../../types/album.type";
import { Role } from "../../types/user.type";
import { getImageUrl } from "../../lib/utils";
import { saveAs } from "file-saver";
import Button from "../Button";
import {
  IconArchive,
  IconCheck,
  IconChevronDown,
  IconDownload,
  IconPencil,
  IconTrash,
} from "@tabler/icons-react";
import { Loader, Modal, Popover } from "@mantine/core";
import useImageActions from "../../hooks/useImageActions";
import { useDisclosure } from "@mantine/hooks";
import { ErrorService } from "../../services";
import CaptionEdit from "../CaptionEdit";
import { useFormik } from "formik";
import * as Yup from "yup";
import Back from "../Back";
import { ImageType } from "../../types/image-type.enum";

const ImageGalleryHeaderDesktop = ({
  image,
  album,
  onUpdated,
  onRemoved,
}: {
  image: Image;
  album: Album | PublicAlbum;
  onUpdated: (image: Image) => void;
  onRemoved: (id: string) => void;
}) => {
  const { user } = useAuth();
  const [removeOpened, { open: openRemove, close: closeRemove }] =
    useDisclosure(false);
  const [editOpened, { open: openEdit, close: closeEdit }] =
    useDisclosure(false);

  const t = useTranslations("Photos");
  const {
    remove,
    unPublish,
    publish,
    approve,
    update,
    loadingUnPublish,
    loadingPublish,
    loadingApprove,
    loadingRemove,
    loadingUpdate,
  } = useImageActions(album?.id);

  const editEnabled =
    user.id === image.userId ||
    user.id === album.user.id ||
    user.role === Role.ADMIN ||
    (user.isGuest && user.id === image.guestUserId);

  const isNotAlbumCreator = user?.id !== album?.user.id;

  const handleDownload = () => {
    //If image open in tab if video save as
    if (image.type === ImageType.PICTURE) {
      const imageUrl = getImageUrl(image.url);
      const parts = image.url.split(".");
      const extension = parts[parts.length - 1];
      saveAs(imageUrl + "?fetch-from-server", `${image.id}.${extension}`);
    } else {
      // For videos
      let downloadUrl =
        import.meta.env.VITE_API_URL +
        `/image/download/${album.id}/${image.id}`;
      // If user is guest, add guest user id to download url
      if (user && user.isGuest) {
        downloadUrl = downloadUrl + `?guestUserId=${user.id}`;
      }
      //Open url in new tab
      window.open(downloadUrl, "_blank");
    }
  };

  const handleRemove = async () => {
    try {
      const resp = await remove(image.id);
      if (resp) {
        onRemoved(image.id);
        closeRemove();
      }
    } catch (error) {
      ErrorService.showError(t("errorRemoval"));
    }
  };

  const handleUnPublish = async () => {
    try {
      const resp = await unPublish(image.id);
      if (resp) {
        onUpdated(resp);
        closeRemove();
        ErrorService.showMessage(t("imageUnpublished"));
      }
    } catch (error) {
      ErrorService.showError(t("errorUnpublishing"));
    }
  };

  const handlePublish = async () => {
    try {
      const resp = await publish(image.id);
      if (resp) {
        onUpdated(resp);
        ErrorService.showMessage(t("imagePublished"));
      }
    } catch (error) {
      ErrorService.showError(t("errorPublishing"));
    }
  };

  const handleApprove = async () => {
    try {
      const resp = await approve(image.id);
      if (resp) {
        onUpdated(resp);
        ErrorService.showMessage(t("imageApproved"));
      }
    } catch (error) {
      ErrorService.showError(t("errorApproving"));
    }
  };

  const onRemoveClick = () => {
    openRemove();
  };

  const handleEdit = () => {
    openEdit();
  };

  const onSave = ({
    caption,
    hashtags,
    contributor,
  }: {
    caption: string;
    hashtags: string[];
    contributor?: string;
  }) => {
    formik.setFieldValue("description", caption);
    formik.setFieldValue("contributor", contributor);
    formik.setFieldValue("hashtags", hashtags);
    formik.submitForm();
  };

  const formik = useFormik({
    initialValues: {
      description: image?.description ?? "",
      contributor: image?.contributor ?? "",
      hashtags: [...(image?.hashtagOnImages?.map((h) => h.hashtag.id) ?? [])],
    },
    validationSchema: Yup.object().shape({
      description: Yup.string().max(2000, t("fieldIsTooLong")),
      contributor: Yup.string().max(200, t("fieldIsTooLong")),
      hashtags: Yup.array().nullable(),
    }),
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      update(image.id, values)
        .then((res) => {
          if (res) {
            onUpdated(res);
            closeEdit();
            ErrorService.showMessage(t("imageUpdated"));
          }
        })
        .catch(() => {
          ErrorService.showError(t("errorUpdating"));
        });
    },
  });

  return (
    <>
      <CaptionEdit
        loading={loadingUpdate}
        album={album}
        image={image}
        withContributor
        opened={editOpened}
        onClose={closeEdit}
        currentCaption={formik.values.description ?? ""}
        albumHashtags={album.hashtags ?? []}
        onSave={onSave}
        selectedHashtags={image.hashtagOnImages?.map((h) => h.hashtag) ?? []}
        currentContributor={formik.values.contributor}
      />
      <Modal
        centered
        radius={8}
        withCloseButton={false}
        opened={removeOpened}
        onClose={closeRemove}
      >
        <Back onClick={closeRemove} />
        <Text color="gray" className="mt-4">
          {isNotAlbumCreator ? t("sureToDelete") : t("deleteOrUnpublish")}
        </Text>
        <div className="flex items-end justify-end gap-2 mt-6">
          {!isNotAlbumCreator && (
            <Button
              loading={loadingUnPublish}
              small
              onClick={handleUnPublish}
              type="outline"
              title={t("unpublish")}
            />
          )}
          <Button
            loading={loadingRemove}
            small
            onClick={handleRemove}
            color="red"
            title={t("remove")}
          />
        </div>
      </Modal>
      <div className="flex w-full items-center pb-10 gap-6 justify-between">
        <div className="flex gap-4">
          {image.contributor && (
            <Text>
              <span className=" text-medium-black">{t("addedBy")}:</span>{" "}
              {image.contributor}
            </Text>
          )}
          <Text>
            <span className=" text-medium-black">{t("addedOn")}:</span>{" "}
            {moment(image.createdAt).format("DD.MM.YYYY")}
          </Text>
        </div>
        <div className="flex gap-4">
          <Button
            type="outline"
            title={t("download")}
            onClick={handleDownload}
            icon={<IconDownload size={16} className=" stroke-black" />}
          />
          {editEnabled && (
            <>
              <Button
                type="outline"
                title={t("editCaption")}
                onClick={handleEdit}
                icon={<IconPencil size={16} className=" stroke-black" />}
              />
              <Popover
                width={200}
                position="bottom"
                styles={{
                  dropdown: {
                    transform: "translateX(-16px)",
                  },
                }}
                offset={16}
                shadow="md"
              >
                <Popover.Target>
                  <Button
                    type="outline"
                    title={t("moreActions")}
                    iconRight={
                      <IconChevronDown size={16} className=" stroke-black" />
                    }
                  />
                </Popover.Target>
                <Popover.Dropdown className="p-0 rounded-xl">
                  <div className="flex flex-col gap-6 p-6">
                    {!isNotAlbumCreator && (
                      <>
                        {image.published ? (
                          <Text
                            onClick={
                              loadingUnPublish ? undefined : handleUnPublish
                            }
                            className={`flex ${
                              loadingUnPublish
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            } font-semibold items-center gap-2`}
                          >
                            {loadingUnPublish ? (
                              <Loader color="#222" size={16} />
                            ) : (
                              <IconArchive
                                size={16}
                                className=" stroke-black"
                              />
                            )}
                            {t("unpublish")}
                          </Text>
                        ) : (
                          <Text
                            onClick={loadingPublish ? undefined : handlePublish}
                            className={`flex ${
                              loadingPublish
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            } font-semibold items-center gap-2`}
                          >
                            {loadingPublish ? (
                              <Loader color="#222" size={16} />
                            ) : (
                              <IconArchive
                                size={16}
                                className=" stroke-black"
                              />
                            )}
                            {t("publish")}
                          </Text>
                        )}
                        {!image.approved && (
                          <Text
                            onClick={loadingApprove ? undefined : handleApprove}
                            className={`flex ${
                              loadingApprove
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            } font-semibold items-center gap-2`}
                          >
                            {loadingApprove ? (
                              <Loader color="#222" size={16} />
                            ) : (
                              <IconCheck size={16} className=" stroke-black" />
                            )}
                            {t("approve")}
                          </Text>
                        )}
                      </>
                    )}
                    <Text
                      color="red"
                      onClick={loadingRemove ? undefined : onRemoveClick}
                      className={`flex ${
                        loadingRemove ? "cursor-not-allowed" : "cursor-pointer"
                      } font-semibold items-center gap-2`}
                    >
                      {loadingRemove ? (
                        <Loader size={16} color="red" />
                      ) : (
                        <IconTrash size={16} className=" stroke-red" />
                      )}
                      {t("remove")}
                    </Text>
                  </div>
                </Popover.Dropdown>
              </Popover>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ImageGalleryHeaderDesktop;
