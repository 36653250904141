import { useQuery } from "@apollo/client";
import {
  findHourlyImageCount,
  findTotalImageCount,
  findTotalSize,
} from "../../graphql/imageQueries";
import { Title } from "../../common";
import {
  IconBrandStripe,
  IconCurrencyDollar,
  IconFileDatabase,
  IconPhoto,
  IconUsers,
} from "@tabler/icons-react";
import { LineChart } from "@mantine/charts";
import { useEffect, useState } from "react";
import { ErrorService } from "../../services";
import { findTotalUserCount } from "../../graphql/userQueries";
import { Loader } from "@mantine/core";
import moment from "moment-timezone";
import { findTotalIncomeQuery } from "../../graphql/paymentsQueries";
import { AccumulatedIncome } from "./components";
import { Helmet } from "react-helmet-async";

const AdminStatiscticsPage = () => {
  const [hourlyImageCount, setHourlyImageCount] = useState<
    {
      count: number;
      hour: string;
    }[]
  >([]);
  const {
    loading: totalSizeLoading,
    error: totalSizeError,
    data: totalSize,
    // refetch: refetchTotalSize,
  } = useQuery<{ findTotalSize: number }, { first?: number; after?: string }>(
    findTotalSize,
    {
      pollInterval: 30000,
    }
  );
  const {
    loading: totalIncomeLoading,
    error: totalIncomeError,
    data: totalIncome,
  } = useQuery<{
    findTotalPaymentIncome: {
      paymentCount: number;
      totalIncome: number;
    };
  }>(findTotalIncomeQuery, {
    pollInterval: 30000,
  });
  const {
    loading: totalImageCountLoading,
    error: totalImageCountError,
    data: totalImageCount,
    // refetch: refetchTotalImageCount,
  } = useQuery<{ findTotalImageCount: number }, {}>(findTotalImageCount, {
    pollInterval: 30000,
  });
  const {
    loading: hourlyImageCountLoading,
    error: hourlyImageCountError,
    data: hourlyImageCountRaw,
    // refetch: refetchHourlyImageCount,
  } = useQuery<{ findHourlyImageCount: { count: number; hour: Date }[] }, {}>(
    findHourlyImageCount,
    {
      pollInterval: 30000,
    }
  );

  useEffect(() => {
    if (hourlyImageCountRaw) {
      const dataWithLocalTime = hourlyImageCountRaw.findHourlyImageCount.map(
        (i) => ({
          ...i,
          hour: moment.tz(i.hour, "Europe/Riga").locale("lv").format(),
        })
      );
      setHourlyImageCount(dataWithLocalTime);
    }
  }, [hourlyImageCountRaw]);

  const {
    loading: totalUserCountLoading,
    error: totalUserCountError,
    data: totalUserCount,
    // refetch: refetchTotalUserCount,
  } = useQuery<{ findTotalUserCount: number }, {}>(findTotalUserCount, {
    pollInterval: 30000,
  });

  useEffect(() => {
    if (
      hourlyImageCountError ||
      totalImageCountError ||
      totalSizeError ||
      totalIncomeError
    ) {
      ErrorService.showError("Error fetching statistics.");
    }
  }, [
    hourlyImageCountError,
    totalImageCountError,
    totalSizeError,
    totalUserCountError,
    totalIncomeError,
  ]);

  return (
    <div className="flex md:px-6 px-4 flex-col min-h-full">
      <Helmet>
        <title>Rompolo - Statistics</title>
      </Helmet>
      <Title size="H5" className=" h-[44px] flex items-center">
        📉 Statistics
      </Title>

      <div className="flex md:flex-row flex-col mt-8 gap-8">
        <div className="flex gap-6 flex-col">
          <StatisticsBox
            icon={<IconFileDatabase />}
            loading={totalSizeLoading}
            data={totalSize?.findTotalSize.toFixed(3) + "GB"}
            title="Total size"
          />
          <StatisticsBox
            icon={<IconPhoto />}
            loading={totalImageCountLoading}
            data={totalImageCount?.findTotalImageCount}
            title="Total image count:"
          />
          <StatisticsBox
            icon={<IconUsers />}
            loading={totalUserCountLoading}
            data={totalUserCount?.findTotalUserCount}
            title="Total user count:"
          />
          <StatisticsBox
            icon={<IconCurrencyDollar />}
            loading={totalIncomeLoading}
            data={totalIncome?.findTotalPaymentIncome?.totalIncome.toFixed(2)}
            title="Monthly income:"
          />
          <StatisticsBox
            icon={<IconBrandStripe />}
            loading={totalIncomeLoading}
            data={totalIncome?.findTotalPaymentIncome?.paymentCount}
            title="Payment count:"
          />
        </div>
        <div className="flex-1 md:p-0 pb-4">
          {hourlyImageCountLoading ? (
            <Loader color="#222" size="lg" />
          ) : (
            <>
              <Title size="H4" className="flex gap-2 items-center mb-6">
                Hourly Image Uploads
              </Title>
              <LineChart
                withXAxis={false}
                h={300}
                w={"100%"}
                data={hourlyImageCount ?? []}
                dataKey="hour"
                curveType="bump"
                series={[
                  { name: "count", label: "Image Uploads", color: "indigo.6" },
                ]}
              />
            </>
          )}
          <div className=" mt-16">
            <AccumulatedIncome />
          </div>
        </div>
      </div>
    </div>
  );
};

const StatisticsBox = ({
  loading,
  data,
  title,
  icon,
}: {
  loading: boolean;
  data: any;
  title: string;
  icon: React.ReactNode;
}) => {
  return (
    <div className=" rounded-xl bg-white p-4 px-6 shadow-sm">
      {loading ? (
        <Loader color="#222" size={"lg"} />
      ) : (
        <>
          <Title size="H4" className="flex gap-2 items-center">
            {icon}
            {title}
          </Title>
          <Title className="mt-2" size="H2">
            {data}
          </Title>
        </>
      )}
    </div>
  );
};

export default AdminStatiscticsPage;
