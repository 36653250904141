import React, { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslations } from "use-intl";
import { Loading, Text } from "../../../common";
import { Album } from "../../../types/album.type";
import { apolloClient } from "../../../lib/apolloClient";
import { IEdgeType, PaginatedType } from "../../../types/paginated.type";
import { Image, ImageSearchProps } from "../../../types/image.type";
import { albumImagesQuery } from "../../../graphql/imageQueries";
import { getImageUrl } from "../../../lib/utils";
import { ImageType } from "../../../types/image-type.enum";
import { VideoComponent } from "../../../common/Image";

const PhotoWallPreview: React.FC<{ album?: Album }> = ({ album }) => {
  const t = useTranslations("Dashboard");
  const [images, setImages] = useState<IEdgeType<Image>[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (album) {
      loadInitialData();
      setCurrentSlide(0);
    } else {
      setLoading(false);
    }
  }, [album]);

  const loadInitialData = async () => {
    if (album) {
      try {
        const resp = await apolloClient.query<
          { albumImages: PaginatedType<Image> },
          ImageSearchProps
        >({
          query: albumImagesQuery,
          variables: {
            albumId: album.id,
            first: 5,
            published: true,
            approved: true,
          },
          fetchPolicy: "no-cache",
        });

        if (resp.data?.albumImages) {
          setImages(
            resp.data.albumImages.edges.filter((edge) => edge && edge.node)
          );
        }
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getIntervalTime = useCallback(
    (image: Image | undefined) => {
      if (!album || !image) return 5000; // Default to 5 seconds if no album or image is provided
      return image.type === ImageType.VIDEO
        ? album.videoDuration * 1000
        : album.imageDuration * 1000;
    },
    [album]
  );

  useEffect(() => {
    if (images.length === 0) return;

    const currentImage = images[currentSlide]?.node;
    if (!currentImage) return;

    const timeoutId = setTimeout(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, getIntervalTime(currentImage));

    return () => clearTimeout(timeoutId);
  }, [currentSlide, images, getIntervalTime]);

  if (loading) {
    return <Loading />;
  }

  const FallbackUI = () => (
    <div className="h-[250px] max-h-[250px] rounded-lg overflow-hidden relative flex items-center justify-center bg-light-gray shadow-lg">
      <Text color="gray" className="text-center px-10">
        {t("previewHere")}
      </Text>
    </div>
  );

  if (images.length === 0 || error || !album) {
    return <FallbackUI />;
  }

  const currentImage = images[currentSlide]?.node;
  if (!currentImage) {
    return <FallbackUI />;
  }

  const imageUrl = getImageUrl(currentImage.url);
  const thumbnailUrl = getImageUrl(currentImage.thumbnailUrl);

  return (
    <div className="rounded-lg h-[250px] max-h-[250px] overflow-hidden relative flex items-center justify-center bg-light-gray shadow-lg flex-1">
      <AnimatePresence initial={false}>
        <motion.div
          key={currentImage.id + "-bg"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="absolute inset-0 z-10"
        >
          <div
            className="h-full w-full bg-cover bg-center"
            style={{
              backgroundImage: `url(${thumbnailUrl})`,
            }}
          />
        </motion.div>
      </AnimatePresence>
      <AnimatePresence initial={false}>
        <motion.div
          className="absolute w-[90%] h-[90%] flex-1 flex justify-center items-center z-[15]"
          key={currentImage.id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 1,
          }}
        >
          {currentImage.type === ImageType.VIDEO ? (
            <VideoComponent
              videoUrl={imageUrl}
              thumbnailUrl={thumbnailUrl}
              isVisible={true}
              className="object-contain rounded-lg mx-auto"
              withPlayIcon={false}
              withSoundIcon={false}
              withControls={false}
            />
          ) : (
            <img
              src={thumbnailUrl}
              alt={currentImage.description || ""}
              className="h-full object-contain rounded-lg"
            />
          )}
        </motion.div>
      </AnimatePresence>
      <div className="absolute inset-0 z-[12] bg-black/50" />
    </div>
  );
};

export default PhotoWallPreview;
