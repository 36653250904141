import { useTranslations } from "use-intl";
import { DownloadAll, RoundedBox, Text } from "../../../common";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const StorageLimit = () => {
  const t = useTranslations("Album");
  const album = useSelector((state: RootState) => state.album.album);

  return (
    <RoundedBox color="light-orange" className="mt-4">
      <Text className="font-semibold">{t("storageLimit")}</Text>
      <Text className="mt-2">{t("storageLimitDescription")}</Text>
      <div className="mt-4 flex flex-col md:flex-row gap-6">
        {album && (
          <DownloadAll title={t("downloadAll")} type="button" album={album} />
        )}
      </div>
    </RoundedBox>
  );
};

export default StorageLimit;
