import { useLocale, useTranslations } from "use-intl";
import { User } from "../types/user.type";
import { Text } from ".";
import { Divider, Popover } from "@mantine/core";
import { IconChevronDown, IconUser } from "@tabler/icons-react";
import { LocalizedLink } from "../router";
import { AuthService } from "../services";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useIsSmallScreen } from "../hooks";
import { useSelector } from "react-redux";
import { RootState } from "../store";

const Account = ({ user }: { user: User }) => {
  const t = useTranslations("Account");
  const album = useSelector((state: RootState) => state.album.album);
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const isSmallScreen = useIsSmallScreen();
  const locale = useLocale();

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      width={isSmallScreen ? window.innerWidth - 2 * 16 : 250}
      styles={{
        dropdown: {
          transform: "translateX(-16px)",
        },
      }}
      offset={16}
      position="bottom"
      shadow="md"
    >
      <Popover.Target>
        <div onClick={() => setOpened((o) => !o)}>
          <div className="gap-2 items-center cursor-pointer hidden md:flex">
            <div className=" rounded-lg w-8 h-8 bg-light-beige flex justify-center items-center">
              {user.fullName && (
                <Text className="font-semibold text-xs">
                  {user.fullName[0]}
                </Text>
              )}
            </div>
            <div>
              <Text className=" font-semibold">{t("myAccount")}</Text>
            </div>
            <div>
              <IconChevronDown
                width={16}
                height={16}
                className="stroke-black mt-1"
              />
            </div>
          </div>
          <div
            className={`md:hidden flex items-center justify-center rounded-lg ${
              opened && "bg-light-beige"
            }  w-8 h-8`}
          >
            <IconUser size={24} className="stroke-black" />
          </div>
        </div>
      </Popover.Target>
      <Popover.Dropdown className="p-0 rounded-xl">
        <div className="flex flex-col gap-6 p-6">
          <Text color="gray" className="text-ellipsis overflow-hidden">
            {user.email}
          </Text>
          <LocalizedLink
            onClick={() => setOpened(false)}
            className="font-semibold"
            to="account"
          >
            {t("settings")}
          </LocalizedLink>
          {album && (
            <LocalizedLink
              onClick={() => setOpened(false)}
              className="font-semibold"
              to="pricing"
            >
              {t("plans")}
            </LocalizedLink>
          )}
          <Link
            target="_blank"
            onClick={() => setOpened(false)}
            className="font-semibold"
            to={`https://rompolo.com/${locale}/contact-us`}
          >
            {t("helpSupport")}
          </Link>
          <Divider />
          <Text
            onClick={() => {
              AuthService.logout().then(() => navigate("/signin"));
            }}
            className=" cursor-pointer font-semibold"
          >
            {t("logout")}
          </Text>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

export default Account;
