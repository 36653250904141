import { useState } from "react";
import { useTranslations } from "use-intl";
import Text from "./Text";
import { IconChevronDown } from "@tabler/icons-react";
import { Popover } from "@mantine/core";
import moment from "moment";

const SortBy = ({
  orderBy,
  onChange,
  albumCreatedAt,
  className,
}: {
  albumCreatedAt: Date;
  orderBy: "createdAt" | "exifCreatedAt";
  onChange: (value: "createdAt" | "exifCreatedAt") => void;
  className?: string;
}) => {
  const [opened, setOpened] = useState(false);
  const targetDate = moment("2024-10-09").startOf("day");
  const t = useTranslations("Sort");

  // Check if the album was created after the target date, as we started to store original creation date only later
  const isNewAlbum = () => {
    const albumCreationDate = moment(albumCreatedAt).startOf("day");
    return albumCreationDate.isSameOrAfter(targetDate);
  };

  if (!isNewAlbum()) {
    return null;
  }

  const orderOptions = {
    createdAt: t("mostRecent"),
    exifCreatedAt: t("timeCaptured"),
  };

  const handleOptionClick = (value: "createdAt" | "exifCreatedAt") => {
    onChange(value);
    setOpened(false);
  };

  return (
    <Popover
      opened={opened}
      onChange={setOpened}
      styles={{
        dropdown: {
          transform: "translateX(-24px)",
          padding: 24,
        },
      }}
      width={250}
      position="bottom"
      shadow="md"
    >
      <div className={`flex gap-2 ${className || ""}`}>
        <Text size={"sm"}>{t("sortBy")}:</Text>
        <Popover.Target>
          <Text
            size={"sm"}
            className="font-semibold flex items-center gap-1 cursor-pointer"
            onClick={() => setOpened((o) => !o)}
          >
            {orderOptions[orderBy]}
            <IconChevronDown
              width={16}
              height={16}
              className=" stroke-black "
            />
          </Text>
        </Popover.Target>
      </div>
      <Popover.Dropdown className="rounded-xl">
        <div className="flex flex-col gap-6">
          <div
            className=" cursor-pointer"
            onClick={() => handleOptionClick("createdAt")}
          >
            <Text className="font-semibold">{t("mostRecent")}</Text>
            <Text color="gray" size={"sm"} className="mt-1">
              {t("mostRecentDescription")}
            </Text>
          </div>
          <div
            className=" cursor-pointer"
            onClick={() => handleOptionClick("exifCreatedAt")}
          >
            <Text className="font-semibold">{t("timeCaptured")}</Text>
            <Text color="gray" size={"sm"} className="mt-1">
              {t("timeCapturedDescription")}
            </Text>
          </div>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

export default SortBy;
