import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslations } from "use-intl";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import Google from "../../assets/google.svg?react";

import { AuthenticationResponse } from "../../types/authentication.response.type";
import { setUser } from "../../store/user.reducer";
import {
  googleSignUpMutation,
  setup2FAMutation,
  signInMutation,
  verify2FAMutation,
} from "../../graphql/authQueries";
import { ErrorService } from "../../services";
import { apolloClientWithoutErrorLink } from "../../lib/apolloClient";
import {
  AuthPageWrapper,
  Button,
  Error,
  Input,
  PasswordInput,
  Text,
} from "../../common";
import { LocalizedLink } from "../../router";

interface FormValues {
  email: string;
  password: string;
}

const SignInPage: React.FC = () => {
  const t = useTranslations("SignIn");
  const dispatch = useDispatch();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [twoFactorQrCode, setTwoFactorQrCode] = useState<string>();
  const [twoFactorCode, setTwoFactorCode] = useState<string>();
  const [twoFactorRequired, setTwoFactorRequired] = useState(false);
  const [twoFactorSetupRequired, setTwoFactorSetupRequired] = useState(false);
  const [twoFactorTempToken, setTwoFactorTempToken] = useState<string>();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t("invalidEmail")).required(t("required")),
    password: Yup.string().required(t("required")),
  });

  const handleGoogleLogin = async (tokenResponse: TokenResponse) => {
    try {
      setGoogleLoading(true);
      const { data } = await apolloClientWithoutErrorLink.mutate<
        { signUpWithGoogle: AuthenticationResponse },
        { input: { accessToken: string } }
      >({
        mutation: googleSignUpMutation,
        variables: { input: { accessToken: tokenResponse.access_token } },
      });
      dispatch(setUser(data?.signUpWithGoogle.user ?? null));
    } catch (error) {
      setError(t("googleAuthenticationError"));
    } finally {
      setGoogleLoading(false);
    }
  };

  const onGoogleError = (
    error: Pick<TokenResponse, "error" | "error_description" | "error_uri">
  ) => {
    console.error(error);
    ErrorService.showError(t("googleAuthenticationError"));
  };

  const login = useGoogleLogin({
    onSuccess: handleGoogleLogin,
    onError: onGoogleError,
  });

  const handle2FASetupRequest = async (tempToken: string) => {
    try {
      setLoading(true);
      const { data } = await apolloClientWithoutErrorLink.mutate<{
        setup2FA: { twoFactorQrCode: string };
      }>({
        mutation: setup2FAMutation,
        context: {
          headers: {
            authorization: `Bearer ${tempToken}`,
          },
        },
      });
      if (data?.setup2FA.twoFactorQrCode) {
        setTwoFactorQrCode(data.setup2FA.twoFactorQrCode);
      }
    } catch (error) {
      setError(t("signInError"));
    } finally {
      setLoading(false);
    }
  };

  const finish2FASetup = async (code: string) => {
    try {
      setLoading(true);
      const { data } = await apolloClientWithoutErrorLink.mutate<
        { verify2FA: AuthenticationResponse },
        { input: { token: string } }
      >({
        variables: { input: { token: code } },
        mutation: verify2FAMutation,
        context: {
          headers: {
            authorization: `Bearer ${twoFactorTempToken}`,
          },
        },
      });
      dispatch(setUser(data?.verify2FA.user ?? null));
    } catch (error) {
      setError(t("signInError"));
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (values: FormValues) => {
    if (twoFactorSetupRequired && twoFactorQrCode) {
      if (twoFactorCode) {
        await finish2FASetup(twoFactorCode);
      }
    } else {
      try {
        setLoading(true);
        const { data } = await apolloClientWithoutErrorLink.mutate<
          { signIn: AuthenticationResponse },
          {
            input: { email: string; password: string; twoFactorToken?: string };
          }
        >({
          mutation: signInMutation,
          variables: {
            input: { ...values, twoFactorToken: twoFactorCode },
          },
        });

        if (data?.signIn) {
          if (data.signIn.requires2FASetup) {
            setTwoFactorSetupRequired(true);
            if (data.signIn.tempToken) {
              setTwoFactorTempToken(data.signIn.tempToken);
              await handle2FASetupRequest(data.signIn.tempToken);
            } else {
              setError(t("signInError"));
            }
          } else if (data.signIn.requires2FA) {
            setTwoFactorRequired(true);
          } else {
            dispatch(setUser(data.signIn.user ?? null));
          }
        }
      } catch (error) {
        setError(t("signInError"));
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <AuthPageWrapper title={t("welcomBack")} subtitle={t("account")}>
      <Helmet>
        <title>Rompolo - {t("signIn")}</title>
      </Helmet>
      <Button
        loading={googleLoading}
        onClick={() => {
          setGoogleLoading(true);
          login();
        }}
        isBold={false}
        fullWidth
        type="outline"
        icon={<Google className="w-5 h-5" />}
        title={t("continueWithGoogle")}
      />
      <div className="flex items-center justify-center my-6">
        <hr className="border-very-light-black w-full" />
        <Text color="gray" className="mx-4">
          {t("or")}
        </Text>
        <hr className="border-very-light-black w-full" />
      </div>

      <Formik
        validateOnChange={false}
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={{ email: "", password: "" }}
        onSubmit={handleSubmit}
      >
        {({ errors, handleChange, submitForm }) => (
          <Form>
            {error && <Error message={error} />}
            <Input
              name="email"
              onChange={handleChange}
              className="mb-3"
              placeholder={t("email")}
              error={errors.email}
            />
            <PasswordInput
              name="password"
              onChange={handleChange}
              className="mb-3"
              placeholder={t("password")}
              error={errors.password}
            />
            {twoFactorQrCode && (
              <div className="my-4">
                <Text>{t("setup2FA")}</Text>
                <img className="mt-4" src={twoFactorQrCode} alt="2FA QR Code" />
              </div>
            )}
            {(twoFactorRequired || twoFactorSetupRequired) && (
              <div className="my-4">
                <Input
                  name="twoFactorCode"
                  onChange={(event) => setTwoFactorCode(event.target.value)}
                  className="mb-3"
                  placeholder={t("2FA")}
                />
              </div>
            )}
            <div className="mb-3">
              <LocalizedLink to="/forgot-password">
                <Text>{t("forgotPassword")}</Text>
              </LocalizedLink>
            </div>

            <Button
              small={false}
              loading={loading}
              onClick={submitForm}
              fullWidth
              title={t("continue")}
            />
            <div className="pt-8">
              <Text className="text-center">
                {t.rich("dontHaveAccount", {
                  signup: (chunks) => (
                    <LocalizedLink to="/signup" className="underline">
                      {chunks}
                    </LocalizedLink>
                  ),
                })}
              </Text>
            </div>
          </Form>
        )}
      </Formik>
    </AuthPageWrapper>
  );
};

export default SignInPage;
