import React from "react";
import { Logo, Title, Text, Trustpilot } from ".";
import { useTranslations } from "use-intl";

type AuthPageWrapperProps = {
  title: string;
  subtitle: string;
  children: React.ReactNode;
};

const AuthPageWrapper = ({
  title,
  subtitle,
  children,
}: AuthPageWrapperProps) => {
  const t = useTranslations("AuthPage");

  return (
    <div className="flex flex-1 md:flex-row flex-col">
      <div className="block md:hidden bg-light-green pt-4 px-4">
        <div className="w-full">
          <Logo />
        </div>
        <div className="flex gap-4 mt-6 items-center justify-center">
          <img
            src="/signup-mobile.webp"
            alt={t("shareMoments")}
            className="w-[102px] h-[102px]"
          />
          <ul className=" list-image-checkmark-green space-y-1 text-black text-sm *:ml-4">
            <li>
              <span className="">{t("one")}</span>
            </li>
            <li>
              <span className="">{t("two")}</span>
            </li>
            <li>
              <span className=" ">{t("three")}</span>
            </li>
            <li>
              <span className="">{t("four")}</span>
            </li>
          </ul>
        </div>
        <div className="-ml-4 pt-6 flex justify-center">
          <Trustpilot />
        </div>
      </div>
      <div className="flex-1 flex justify-center">
        <div className="md:pt-10 pt-4 md:w-[340px] 2xl:w-[450px] px-4">
          <div className="md:block hidden">
            <Logo />
          </div>
          <div className="pt-8 md:pt-20">
            <Title size="H3">{title}</Title>
            <Text className="pt-2" color="gray">
              {subtitle}
            </Text>
            <div className="pt-8 pb-8">{children}</div>
          </div>
        </div>
      </div>
      <div className="md:block hidden flex-1 relative">
        <img
          className="w-full h-full object-cover absolute"
          src={"/signup-desktop.webp"}
          alt={t("shareMoments")}
        />
        <div className="absolute bottom-10 left-10 right-10 z-30">
          <div className="flex gap-10 flex-wrap">
            <div className="flex-1 flex flex-col justify-end">
              <Trustpilot theme="dark" />
              <div className="min-w-[300px] md:max-w-[380px] 3xl:max-w-[550px]">
                <span className=" text-white md:text-[30px] 3xl:text-[40px] font-semibold leading-[42px] tracking-tight  font-poppins">
                  {t("shareMoments")}
                </span>
              </div>
            </div>
            <div className="flex xl:justify-end items-end">
              <ul className=" list-image-checkmark-white space-y-1 text-white 3xl:text-[22px] font-semibold *:ml-4">
                <li>
                  <span className="">{t("one")}</span>
                </li>
                <li>
                  <span className="">{t("two")}</span>
                </li>
                <li>
                  <span className=" ">{t("three")}</span>
                </li>
                <li>
                  <span className="">{t("four")}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="absolute w-full h-[50%] bg-gradient-to-t from-black to-transparent bottom-0" />
      </div>
    </div>
  );
};

export default AuthPageWrapper;
