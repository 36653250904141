import { useCallback, useEffect, useRef, useState } from "react";
import { useWindowVirtualizer } from "@tanstack/react-virtual";
import { throttle, debounce } from "lodash";
import { Image } from "../../types/image.type";
import {
  ImageGallerySwipable,
  ImageGridItem,
  MobileImageGallery,
  Selected,
} from ".";
import { IEdgeType } from "../../types/paginated.type";
import { Album, PublicAlbum } from "../../types/album.type";
import { useIsSmallScreen } from "../../hooks";

// Outside the component
function estimateSize(size: number) {
  return () => size;
}

function calculateDimensions(containerWidth: number) {
  const columnCount =
    containerWidth < 600
      ? 2
      : containerWidth < 900
      ? 3
      : containerWidth < 1200
      ? 3
      : containerWidth < 1500
      ? 4
      : containerWidth < 1800
      ? 5
      : containerWidth < 2100
      ? 6
      : containerWidth < 2400
      ? 7
      : 8;

  const itemWidth = (containerWidth - (columnCount - 1) * 16) / columnCount; //Horizontal gap included omitting first column
  const itemHeight = itemWidth; // Equal to width for square items

  return { itemWidth, itemHeight, columnCount };
}

const ImageGrid = ({
  items,
  hasNextPage,
  loading,
  loadMore,
  onRemoved,
  onUpdated,
  count,
  withCaptions = true,
  withContributors = true,
  albumMode = false,
  album,
}: {
  withCaptions?: boolean;
  withContributors?: boolean;
  loadMore: (cursor: string) => void;
  hasNextPage: boolean;
  items: IEdgeType<Image>[];
  loading: boolean;
  onRemoved: (id: string | string[]) => void;
  onUpdated: (image: Image | Image[]) => void;
  count: number;
  albumMode?: boolean;
  album: Album | PublicAlbum;
}) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const initialDimensions = calculateDimensions(
    parentRef?.current?.offsetWidth ?? window.innerWidth <= 768
      ? window.innerWidth - 32 //padding
      : albumMode
      ? Math.min(window.innerWidth - 16 * 2, 1100 - 16 * 2) //Take 1100 - padding or smaller screen when album mode is enabled
      : window.innerWidth - 240 - 80 //Sidemenu width and padding, if album mode is enabled, we don't need to subtract the sidemenu width
  );
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [columnCount, setColumnCount] = useState(initialDimensions.columnCount);
  const [itemWidth, setItemWidth] = useState(initialDimensions.itemWidth);
  const [itemHeight, setItemHeight] = useState(initialDimensions.itemHeight);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const isSmallScreen = useIsSmallScreen();

  const estimateRowSize = useCallback(estimateSize(itemWidth), [itemHeight]);

  const rowVirtualizer = useWindowVirtualizer({
    count: items.length,
    estimateSize: estimateRowSize,
    overscan: 18,
    initialOffset: 0,
    scrollMargin: 0,
    lanes: columnCount,
    gap: 16,
  });

  /**
   * Removes a single image from the selected images set.
   *
   * @param {string} id - The ID of the image to remove.
   * @return {string[]} The updated array of selected image IDs.
   */
  const handleSingleRemove = (id: string) => {
    onRemoved(id);
    setSelectedImages((prevSelectedImages) => {
      const newSelection = new Set(prevSelectedImages);
      if (newSelection.has(id)) {
        newSelection.delete(id);
      }
      return Array.from(newSelection);
    });
  };

  const handleImageSelect = (imageId: string) => {
    setSelectedImages((prevSelectedImages) => {
      const newSelection = new Set(prevSelectedImages);
      if (newSelection.has(imageId)) {
        newSelection.delete(imageId);
      } else {
        newSelection.add(imageId);
      }
      return Array.from(newSelection);
    });
  };

  const handleImageSelectAll = (ids: string[]) => {
    setSelectedImages(ids);
  };

  const handleResize = useCallback(
    debounce(() => {
      console.log("resize");

      const {
        itemWidth: newWidth,
        itemHeight: newHeight,
        columnCount: newColumnCount,
      } = calculateDimensions(
        parentRef?.current?.offsetWidth ?? window.innerWidth
      );
      setColumnCount(newColumnCount);
      setItemWidth(newWidth);
      setItemHeight(newHeight);
    }, 300),
    []
  );

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const throttledLoadMoreItems = useCallback(
    throttle((cursor: string) => {
      loadMore(cursor);
    }, 300),
    [loadMore]
  );

  const handleLoadMore = useCallback(() => {
    const [lastImageInGrid] = [...rowVirtualizer.getVirtualItems()].reverse();

    if (!hasNextPage || loading || !lastImageInGrid) return;

    //last item in grid reached in viewport
    if (lastImageInGrid.index >= items.length - 1) {
      const lastItemCursor = items[items.length - 1].cursor; //Take last image loaded from server, at the moment, to avoid glitches with duplicates when we load with wrong cursor
      throttledLoadMoreItems(lastItemCursor);
    }
  }, [
    hasNextPage,
    loading,
    items,
    rowVirtualizer.getVirtualItems(),
    throttledLoadMoreItems,
  ]);

  //Handle when the user scrolls to the bottom of the page
  useEffect(() => {
    handleLoadMore();
  }, [rowVirtualizer.getVirtualItems()]);

  // If grid gallery is open and the items are removed, close the gallery
  useEffect(() => {
    if (items.length === 0 && isOpen) {
      setIsOpen(false);
    }
  }, [items]);

  return (
    <div ref={parentRef} className="flex-1 flex w-full pt-6 pb-6">
      {isOpen && !isSmallScreen && (
        <ImageGallerySwipable
          album={album}
          onRemoved={onRemoved}
          onClose={(currentIndex: number) => {
            setCurrentIndex(currentIndex);
            setIsOpen(false);
          }}
          loadMore={loadMore}
          images={items}
          imageIndex={currentIndex}
          onUpdated={onUpdated}
        />
      )}
      {isOpen && items.length > 0 && isSmallScreen && (
        <MobileImageGallery
          album={album}
          onRemoved={onRemoved}
          onClose={(currentIndex: number) => {
            setCurrentIndex(currentIndex);
            setIsOpen(false);
          }}
          loadMore={loadMore}
          images={items}
          imageIndex={currentIndex}
          onUpdated={onUpdated}
          count={count}
        />
      )}
      <div
        id="image-grid"
        className="flex-1 relative"
        style={{
          width: "100%",
          height: `${rowVirtualizer.getTotalSize()}px`,
          position: "relative",
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const index = virtualRow.index;
          return (
            <ImageGridItem
              totalColumnCount={columnCount}
              albumMode={albumMode}
              withContributors={withContributors}
              album={album}
              key={items[index].cursor}
              item={items[index]}
              virtualRow={virtualRow}
              withCaptions={withCaptions}
              itemHeight={itemHeight}
              itemWidth={itemWidth}
              handleImageSelect={handleImageSelect}
              selectedImages={selectedImages}
              isSmallScreen={isSmallScreen}
              itemsLength={items.length}
              measureElement={rowVirtualizer.measureElement}
              onRemoved={handleSingleRemove}
              onUpdated={onUpdated}
              onClick={() => {
                setCurrentIndex(index);
                setIsOpen(true);
              }}
            />
          );
        })}
        <Selected
          albumMode={albumMode}
          selectedImages={selectedImages}
          allImages={items}
          selectImages={handleImageSelectAll}
          onUpdated={onUpdated}
          onRemoved={onRemoved}
        />
      </div>
    </div>
  );
};

export default ImageGrid;
