import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useQuery } from "@apollo/client";
import { useTranslations } from "use-intl";
import { Input, ReactTable } from "../../../common";
import { Loader, Pagination } from "@mantine/core";
import { badgePaymentsColumns } from "../../../columns/BadgePaymentsColumns";
import BadgePaymentDetails from "./BadgePaymentDetails";
import { useSearchParamsState } from "../../../hooks";
import { badgePaymentsQuery } from "../../../graphql/paymentsQueries";
import { ErrorService } from "../../../services";
import { Payment } from "../../../types/payment.type";
import { PaginatedType } from "../../../types/paginated.type";
import { OperationType } from "../../../types/operation-type.enum";
import { parseBooleanOrUndefined } from "../../../lib/utils";
import debounce from "lodash/debounce";

const BadgeOrdersTable: React.FC = () => {
  const t = useTranslations("Badges");
  const [queryParams, setQueryParams] = useSearchParamsState<{
    search: string;
    page: number;
    limit: number;
    processed?: string;
  }>({
    search: "",
    page: 1,
    limit: 12,
    processed: undefined,
  });

  const [selectedPayment, setSelectedPayment] = useState<Payment | null>(null);
  const [searchValue, setSearchValue] = useState(queryParams.search);

  // Set search value to queryParams.search if it is different from searchValue
  useEffect(() => {
    if (queryParams.search !== searchValue) {
      setSearchValue(queryParams.search);
    }
  }, [queryParams.search]);

  const { loading, error, data, refetch } = useQuery<
    { findPaymentsWithBadges: PaginatedType<Payment> },
    {
      search?: string;
      first?: number;
      offset?: number;
      paymentId?: string;
      customer?: string;
      albumId?: string;
      processed?: boolean;
      operationType?: OperationType;
    }
  >(badgePaymentsQuery, {
    variables: {
      first: queryParams.limit,
      offset: (queryParams.page - 1) * queryParams.limit,
      search: queryParams.search,
      processed: parseBooleanOrUndefined(queryParams.processed),
    },
    fetchPolicy: "cache-and-network",
  });

  const initialState = useMemo(
    () => ({
      columnFilters: queryParams.processed
        ? [{ id: "processed", value: queryParams.processed }]
        : [],
      globalFilter: queryParams.search,
    }),
    [queryParams.processed, queryParams.search]
  );

  const loadMore = useCallback(
    (newPage: number) => {
      setQueryParams({ page: newPage });
    },
    [setQueryParams]
  );

  const debouncedSetQueryParams = useMemo(
    () =>
      debounce((search: string) => {
        setQueryParams({ search, page: 1 });
      }, 300),
    []
  );

  useEffect(() => {
    debouncedSetQueryParams(searchValue);
  }, [searchValue]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const search = event.target.value;
      setSearchValue(search);
    },
    []
  );

  useEffect(() => {
    if (error) {
      ErrorService.showError(t("errorFetching"));
    }
  }, [error, t]);

  const handleRowClick = useCallback((payment: Payment) => {
    setSelectedPayment(payment);
  }, []);

  const closeModal = useCallback(() => {
    setSelectedPayment(null);
  }, []);

  const onPaymentUpdated = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleFilterChange = useCallback(
    (fieldName: string, value: any) => {
      setQueryParams({ [fieldName]: value || undefined, page: 1 });
    },
    [setQueryParams]
  );

  const totalCount = data?.findPaymentsWithBadges.totalCount ?? 0;

  return (
    <div className="mt-8">
      {selectedPayment && (
        <BadgePaymentDetails
          payment={selectedPayment}
          opened={!!selectedPayment}
          close={closeModal}
          onUpdated={onPaymentUpdated}
        />
      )}
      <div className="flex md:items-center gap-4 flex-col md:flex-row mt-8">
        <div className="flex flex-col md:flex-row gap-6 w-full flex-1">
          <Input
            rightSection={loading && <Loader color="#222222" size={16} />}
            onChange={handleSearchChange}
            value={searchValue}
            placeholder={t("search")}
            className="w-full flex-1"
          />
        </div>
      </div>
      <div className="mt-8">
        <ReactTable<Payment>
          initialState={initialState}
          manualFiltering={true}
          withFilters
          loading={loading}
          onRowClick={handleRowClick}
          columns={badgePaymentsColumns}
          data={data?.findPaymentsWithBadges.nodes ?? []}
          initialPageSize={queryParams.limit}
          emptyMessage={"No payments found"}
          filterOnChange={handleFilterChange}
        />
        <Pagination
          className="mt-6"
          classNames={{
            control:
              "text-black hover:bg-black hover:text-white border-medium-black data-[active=true]:bg-black data-[active=true]:text-white",
          }}
          size="sm"
          value={queryParams.page}
          total={Math.ceil(totalCount / queryParams.limit)}
          onChange={loadMore}
        />
      </div>
    </div>
  );
};

export default BadgeOrdersTable;
