import React, { useState } from "react";
import { Checkbox, Modal, Title } from "@mantine/core";
import { useTranslations } from "use-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { adminUpdateUserMutation } from "../../../graphql/userQueries";
import { ErrorService } from "../../../services";
import {
  Button,
  Input,
  PasswordInput,
  SelectInput,
  DateInput,
  Text,
} from "../../../common";
import { AdminUpdateUserInput, Role, User } from "../../../types/user.type";
import { IconX } from "@tabler/icons-react";

interface UpdateUserModalProps {
  opened: boolean;
  close: () => void;
  onUpdated: () => void;
  user: User | null;
}

const UpdateUserModal: React.FC<UpdateUserModalProps> = ({
  opened,
  close,
  onUpdated,
  user,
}) => {
  const tV = useTranslations("Validation");

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [updateUser, { loading }] = useMutation<
    { adminUpdateUser: User },
    { input: AdminUpdateUserInput }
  >(adminUpdateUserMutation);

  const onSubmit = () => {
    setShowConfirmation(true);
  };

  const handleUpdateUser = (values: AdminUpdateUserInput) => {
    updateUser({ variables: { input: values } })
      .then((response) => {
        const updatedUser = response.data?.adminUpdateUser;
        if (updatedUser) {
          ErrorService.showMessage("✅ User updated successfully");
          onUpdated();
          close();
        } else {
          ErrorService.showError("Error on user update");
        }
      })
      .catch((error) => {
        ErrorService.showError("Error on user update - " + error.message);
      })
      .finally(() => {
        setShowConfirmation(false);
      });
  };

  const roles = [
    { value: Role.ADMIN, label: "Admin" },
    { value: Role.USER, label: "User" },
  ];

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().max(200, tV("tooLong")),
    email: Yup.string().email(tV("invalidEmail")),
    password: Yup.string()
      .min(8, tV("passwordMinLength"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        tV("passwordError")
      ),
    role: Yup.string().oneOf(Object.values(Role), tV("invalidType")),
    verified: Yup.date().nullable(),
    onboarded: Yup.date().nullable(),
    banned: Yup.boolean(),
  });

  return (
    <Modal
      opened={opened}
      onClose={close}
      centered
      radius={8}
      padding={40}
      size={600}
      withCloseButton={false}
    >
      <Formik
        initialValues={{
          id: user?.id || "",
          fullName: user?.fullName || "",
          email: user?.email || "",
          password: "",
          role: user?.role || Role.USER,
          verified: user?.verified ?? null,
          onboarded: user?.onboarded ?? null,
          banned: user?.banned ?? false,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
        enableReinitialize
      >
        {(props) => (
          <>
            <Modal
              radius={8}
              withCloseButton={false}
              opened={showConfirmation}
              onClose={() => setShowConfirmation(false)}
              centered
            >
              <Text color="gray">{"Sure to update user?"}</Text>
              <div className="flex items-end justify-end gap-2 mt-6">
                <Button
                  small
                  onClick={() => {
                    setShowConfirmation(false);
                  }}
                  type="outline"
                  title={"Cancel"}
                />
                <Button
                  loading={loading}
                  small
                  onClick={() => handleUpdateUser(props.values)}
                  color="red"
                  title={"Update user"}
                />
              </div>
            </Modal>
            <div className="mb-6">
              <div className="flex justify-between">
                <Title size="H5">Update user</Title>
                <IconX
                  onClick={close}
                  size={24}
                  className="cursor-pointer stroke-medium-black"
                />
              </div>
            </div>
            <form className="flex flex-col gap-4">
              <Input
                name="fullName"
                label="Full name"
                placeholder="Enter full name"
                onChange={props.handleChange}
                value={props.values.fullName}
                error={props.errors.fullName}
              />
              <Input
                name="email"
                label="Email"
                placeholder="Enter email"
                onChange={props.handleChange}
                value={props.values.email}
                error={props.errors.email}
              />
              <PasswordInput
                name="password"
                label="Password"
                placeholder="Enter new password"
                onChange={props.handleChange}
                value={props.values.password}
                error={props.errors.password}
              />
              <SelectInput
                name="role"
                label="Role"
                placeholder="Select role"
                onChange={(value) => props.setFieldValue("role", value)}
                error={props.errors.role}
                data={roles}
                value={props.values.role}
              />
              <DateInput
                disabled
                name="verified"
                label={"Verified date"}
                onChange={(value) => props.setFieldValue("verified", value)}
                value={
                  props.values.verified ? new Date(props.values.verified) : null
                }
                error={props.errors.verified as string | undefined}
              />
              <DateInput
                disabled
                name="onboarded"
                label={"Onboarded date"}
                onChange={(value) => props.setFieldValue("onboarded", value)}
                value={
                  props.values.onboarded
                    ? new Date(props.values.onboarded)
                    : null
                }
                error={props.errors.onboarded as string | undefined}
              />
              <div className="flex gap-4">
                <Checkbox
                  name="banned"
                  checked={props.values.banned}
                  onChange={props.handleChange}
                  label="Banned"
                />
              </div>
              <div className="flex justify-end mt-4">
                <Button
                  onClick={props.handleSubmit}
                  loading={loading}
                  title="Update user"
                />
              </div>
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default UpdateUserModal;
