import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslations } from "use-intl";
import { Button } from "..";
import { FileButton, Tooltip } from "@mantine/core";
import { IconUpload } from "@tabler/icons-react";
import { AxiosError } from "axios";
import { debounce } from "lodash";
import pLimit from "p-limit";

import { useAuth } from "../../hooks";
import { ErrorService } from "../../services";
import axiosClient from "../../lib/axiosClient";
import { compressImage } from "../../lib/fileCompressor";
import { checkMediaType } from "../../lib/utils";
import { Album, PublicAlbum } from "../../types/album.type";
import { Hashtag } from "../../types/hashtag.type";
import FileUploadDialog, { ALLOWED_MAX_SIZE } from "./FileUploadDialog";

// Define props for the FileUpload component
interface FileUploadProps {
  album: Album | PublicAlbum;
  loadData?: () => Promise<number | void>;
  disabled?: boolean;
  resize?: boolean;
  fullWidth?: boolean;
  tooltip?: string;
  onUploadComplete: () => void;
}

// Define the structure for file status
interface FileStatus {
  description?: string;
  contributor?: string;
  hashtags?: Hashtag[];
  file: File;
  status: "success" | "error" | "pending";
  statusCode?: number;
  uploadProgress?: number;
}

// Main FileUpload component
const FileUpload: React.FC<FileUploadProps> = ({
  album,
  loadData,
  disabled = false,
  resize = true,
  fullWidth = false,
  tooltip,
  onUploadComplete,
}) => {
  const { user } = useAuth();

  // Get contributor name from local storage or session
  const getContributorName = () => {
    if (user && user.fullName) {
      return user.fullName;
    } else if (localStorage.getItem("contributor")) {
      return localStorage.getItem("contributor") ?? "";
    } else {
      return "";
    }
  };

  const t = useTranslations();
  const [files, setFiles] = useState<FileStatus[]>([]);
  const [loading, setLoading] = useState(false);
  const [contributor, setContributor] = useState<string>(getContributorName());
  const uploadInputRef = useRef<HTMLButtonElement>(null);
  const resetRef = useRef<() => void>(null);
  const limit = pLimit(5);

  // Debounced error processing function
  const processError = useCallback(
    debounce((error: AxiosError) => {
      if (error?.code === "507" || error?.response?.status === 507) {
        ErrorService.showError(t("Files.storageLimitExceeded"), 6000);
      } else {
        ErrorService.showError(t("Files.errorUploading"));
      }
    }, 500),
    [t]
  );

  //Save contributor name to local storage
  const saveContributorName = () => {
    if (contributor) {
      localStorage.setItem("contributor", contributor);
    }
  };

  // Function to close dialog and reload images
  // This will be called also when unsuccessfully uploading images
  const closeAndReloadImages = useCallback(() => {
    //If user is guest save name to local storage
    if (user.isGuest) {
      saveContributorName();
    }
    loadData?.(); //Reload images, to load new images uploaded
    setFiles([]);

    // If any of images is sucessfully uploaded, then call onUploadComplete
    if (files.some((i) => i.status === "success")) {
      onUploadComplete();
    }
  }, [files, loadData, onUploadComplete]);

  // Set contributor name if user is logged in
  useEffect(() => {
    if (user) {
      setContributor(getContributorName());
    }
  }, [user]);

  // Check if all files were uploaded successfully
  useEffect(() => {
    if (files.length > 0 && files.every((i) => i.status === "success")) {
      closeAndReloadImages();
    }
  }, [files]);

  // Effect to fetch server name on component mount
  useEffect(() => {
    const fetchServerName = async () => {
      try {
        const response = await axiosClient.get("/health");
        console.log("Connected to server:", response.headers["x-server-name"]);
      } catch (error) {
        console.error("Error fetching server name:", error);
      }
    };
    fetchServerName();
  }, []);

  // Function to upload a single image
  const uploadImage = useCallback(
    async (imageToUpload: FileStatus, index: number) => {
      try {
        // File preparation and compression
        const file = imageToUpload.file;
        const fileType = await checkMediaType(file);
        let compressedFile: Blob | File = file;

        if (fileType === "image" && resize) {
          compressedFile = await compressImage(file);
        } else if (fileType === "video" && file.type.startsWith("image/")) {
          compressedFile = new File(
            [file],
            file.name.replace(/\..+$/, ".mp4"),
            { type: "video/mp4" }
          );
        }

        // Prepare form data
        const formData = new FormData();
        formData.append("files", compressedFile, (compressedFile as File).name);
        if (imageToUpload.description) {
          formData.append(`description-0`, imageToUpload.description);
        }
        if (contributor) {
          formData.append(`contributor-0`, contributor);
        }
        if (imageToUpload.hashtags) {
          formData.append(`hashtags-0`, JSON.stringify(imageToUpload.hashtags));
        }

        // Determine upload endpoint
        const imageUploadEndpoint =
          user.isGuest || user.id !== album.user.id
            ? `/image/public-upload/${album.id}`
            : `/image/${album.id}`;

        // Send upload request
        const response = await axiosClient.post(imageUploadEndpoint, formData, {
          headers: { ...(user.isGuest && { guestuserid: user.id }) },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / (progressEvent?.total ?? 0)
            );
            setFiles((prevFiles) =>
              prevFiles.map((file, i) =>
                i === index
                  ? { ...file, uploadProgress: percentCompleted }
                  : file
              )
            );
          },
        });

        // Handle upload errors
        if (response.data?.errors?.length > 0) {
          throw new AxiosError(
            response.data.errors[0].message,
            response.data.errors[0].status.toString()
          );
        }

        // Update file status on success
        setFiles((prevFiles) =>
          prevFiles.map((file, i) =>
            i === index ? { ...file, status: "success" } : file
          )
        );
      } catch (error) {
        // Handle and process errors
        const axiosError = error as AxiosError;
        processError(axiosError);
        setFiles((prevFiles) =>
          prevFiles.map((file, i) =>
            i === index
              ? {
                  ...file,
                  status: "error",
                  statusCode: parseInt(axiosError.code ?? ""),
                  uploadProgress: 0,
                }
              : file
          )
        );
      }
    },
    [resize, contributor, album, user, processError]
  );

  // Function to upload all images
  const uploadImages = useCallback(async () => {
    if (files.length === 0) return;

    try {
      // Set all pending files to "pending" status
      setFiles((prevFiles) =>
        prevFiles.map((file) =>
          file.status !== "success" ? { ...file, status: "pending" } : file
        )
      );
      setLoading(true);

      // Upload files concurrently with a limit
      await Promise.all(
        files.map((file, index) =>
          limit(() => {
            if (
              file.status !== "success" &&
              file.file.size <= ALLOWED_MAX_SIZE
            ) {
              return uploadImage(file, index);
            }
            return Promise.resolve();
          })
        )
      );
    } catch (error) {
      ErrorService.showError(t("Files.errorUploading"));
    } finally {
      setLoading(false);
    }
  }, [files, uploadImage, closeAndReloadImages, t]);

  // Function to handle file selection
  const handleFilesChange = useCallback(
    (selectedFiles: File[]) => {
      const updatedImages = [...files];
      selectedFiles.forEach((f) => {
        const index = updatedImages.findIndex(
          (file) => file.file.name === f.name
        );
        if (index === -1) {
          const newFilename = f.name.replace(/[^a-zA-Z0-9._-]/gi, ""); //Replace special characters
          updatedImages.push({
            file: new File([f], newFilename, { type: f.type }),
            status: "pending",
            description: "",
            contributor: "",
            uploadProgress: 0,
          });
        }
      });
      setFiles(updatedImages);
      resetRef.current?.();
    },
    [files]
  );

  // Render the component
  return (
    <div className="w-full md:w-auto">
      {files.length > 0 && (
        <FileUploadDialog
          setFiles={setFiles}
          loading={loading}
          setContributor={setContributor}
          contributor={contributor}
          album={album}
          files={files}
          onClose={closeAndReloadImages}
          onSubmit={uploadImages}
          openFileInput={() => uploadInputRef.current?.click()}
        />
      )}
      <FileButton
        resetRef={resetRef}
        disabled={disabled}
        onChange={handleFilesChange}
        accept="image/png,image/webp,image/jpeg,video/mp4,video/avi,video/mkv,video/quicktime"
        multiple
      >
        {(props) => (
          <Tooltip
            multiline
            maw={250}
            color="#222"
            disabled={!tooltip}
            label={tooltip}
          >
            <Button
              fullWidth={fullWidth}
              ref={uploadInputRef}
              icon={<IconUpload size={16} className="stroke-white" />}
              disabled={disabled}
              small={false}
              loading={loading}
              {...props}
              title={t("Photos.upload")}
            />
          </Tooltip>
        )}
      </FileButton>
    </div>
  );
};

export default FileUpload;
