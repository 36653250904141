import { Album, PublicAlbum } from "../../types/album.type";
import { IEdgeType } from "../../types/paginated.type";
import { Image as DBImage } from "../../types/image.type";
import { useLockBodyScroll } from "../../hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { Virtual } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import {
  GalleryImage,
  ImageDetails,
  ImageGalleryHeader,
  ShowDetailsButton,
} from ".";
import { getImageUrl } from "../../lib/utils";
import { useState } from "react";
import { useSwipeable } from "react-swipeable";

const MobileImageGallery = ({
  images,
  imageIndex = 0,
  loadMore,
  onClose,
  onUpdated,
  onRemoved,
  album,
  count,
}: {
  album: Album | PublicAlbum;
  images: IEdgeType<DBImage>[];
  imageIndex: number;
  loadMore: (cursor: string) => void;
  onClose: (currentIndex: number) => void;
  onUpdated: (image: DBImage) => void;
  onRemoved: (id: string) => void;
  count: number;
}) => {
  const [currentImage, setCurrentImage] = useState<number>(imageIndex);
  const [showDetails, setShowDetails] = useState(false);

  const handlers = useSwipeable({
    onSwipedUp: () => setShowDetails(true),
  });

  useLockBodyScroll(true);

  const handleUpdated = (image: DBImage) => {
    const current = images[currentImage].node;
    if (current.approved !== image.approved) {
      //Image was approved, then close the details
      setShowDetails(false);
    }
    onUpdated(image);
  };

  return (
    <div className="fixed w-screen h-dvh inset-0 z-50 bg-black">
      <ShowDetailsButton showDetails={setShowDetails} />
      <ImageGalleryHeader
        onClose={() => {
          onClose(currentImage);
        }}
        currentImage={currentImage}
        count={count}
        image={images[currentImage]?.node}
      />
      <div {...handlers}>
        <Swiper
          modules={[Virtual]}
          virtual
          direction={"horizontal"}
          pagination={{
            clickable: true,
          }}
          initialSlide={imageIndex}
          style={{ height: "calc(100dvh - 50px)" }}
          onSlideChange={(swiper) => {
            // Set current slide number to state
            setCurrentImage(swiper.activeIndex);

            // Load more when there are only 3 images left till the end
            if (swiper.activeIndex >= images.length - 3) {
              loadMore(images[images.length - 1].cursor);
            }
          }}
        >
          {images.map((image) => (
            <SwiperSlide key={image.node.id} className="flex items-center">
              {({ isVisible }) => (
                <GalleryImage
                  isVisible={isVisible}
                  key={image.node.id}
                  image={image.node}
                  url={getImageUrl(image.node.url)}
                  thumbnailUrl={getImageUrl(image.node.thumbnailUrl)}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* ImageDetails sidebar for desktop and slide up for mobile */}
      {images[currentImage] && (
        <ImageDetails
          album={album}
          onRemoved={(id: string) => {
            onRemoved(id);
            setShowDetails(false);
          }}
          showDetails={showDetails}
          image={images[currentImage].node}
          onUpdated={handleUpdated}
          onClose={() => setShowDetails(false)}
        />
      )}
    </div>
  );
};

export default MobileImageGallery;
