import { Switch, SwitchProps } from "@mantine/core";
import Text from "./Text";
import InfoToolitp from "./InfoToolitp";

const Toggle = ({
  onChange,
  checked,
  label,
  fontSize = 14, // Default font size is 14
  tooltip,
  ...props
}: {
  onChange: (value: boolean) => void;
  checked: boolean;
  label: string;
  fontSize?: number; // Parameter to control font size
  tooltip?: string;
} & Omit<SwitchProps, "onChange">) => {
  return (
    <div className="flex gap-2 items-center">
      <Switch
        {...props}
        color="#222222"
        onChange={(event) => onChange(event.currentTarget.checked)}
        styles={{
          label: {
            fontSize: fontSize,
          },
        }}
        defaultChecked
        checked={checked}
      />
      <Text size={fontSize}>
        <span>
          {label}
          {tooltip && (
            <span className=" whitespace-nowrap">
              <InfoToolitp text={tooltip} />
            </span>
          )}
        </span>
      </Text>
    </div>
  );
};

export default Toggle;
