import { useTranslations } from "use-intl";
import {
  AlbumWillExpire,
  DownloadAll,
  FileUpload,
  ImageGrid,
  Loading,
  Text,
  Title,
  Toggle,
  HashtagSelector,
  TechnicalIssueAlert,
  BadgeAlbumSize,
  SortBy,
} from "../../common";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  Expired,
  ImageGridFilters,
  NoPhotos,
  StorageLimit,
} from "./components";
import { useLazyQuery } from "@apollo/client";
import { IEdgeType, PaginatedType } from "../../types/paginated.type";
import { Image, ImageSearchProps } from "../../types/image.type";
import { albumImagesQuery, findAlbumSizes } from "../../graphql/imageQueries";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ErrorService } from "../../services";
import { apolloClient } from "../../lib/apolloClient";
import { useLoadCounts, useSearchParamsState } from "../../hooks";
import { AlbumSizes } from "../../types/album.type";
import { Helmet } from "react-helmet-async";
import _ from "lodash";

type Filters = {
  filterPublished?: boolean;
  filterApproved?: boolean;
  withCaptions: boolean;
  selectedHashtags: string[];
  orderBy: "createdAt" | "exifCreatedAt";
};

/**
 * Component uses for main queries apolloClient not useLazyQuery hook, as due to bug,
 *  query is getting called twice and with previous merged variables
 */
const PhotosVideosPage = () => {
  const t = useTranslations("Photos");
  const loadCount = 20;

  const album = useSelector((root: RootState) => root.album.album);
  const [images, setImages] = useState<IEdgeType<Image>[]>([]);
  const [filteredImages, setFilteredImages] = useState<IEdgeType<Image>[]>([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [isStorageLimitReached, setIsStorageLimitReached] = useState(false);
  const [filters, setFiltersQuery] = useSearchParamsState<Filters>({
    filterApproved: true,
    filterPublished: true,
    withCaptions: false,
    selectedHashtags: [],
    orderBy: "createdAt",
  });
  const [selectedHashtags, setSelectedHashtags] = useState<string[]>(
    filters.selectedHashtags
  ); //We will use it, as proxy for select ed hashtags in url, as it triggers unessecery rerenders when we update anyhting what is not hashtags

  const lastCursor = useRef<string | null>(null);
  const albumIsExpired = album?.expirationDate
    ? new Date(album?.expirationDate) < new Date()
    : false;

  const { loadCounts, unPublishedCount, publishedCount, notApprovedCount } =
    useLoadCounts(album?.id ?? null);

  //Find album sizes
  const [loadAlbumSize, { data: albumSizes }] = useLazyQuery<
    { findAlbumSize: AlbumSizes },
    { albumId: string }
  >(findAlbumSizes);

  useEffect(() => {
    if (error) {
      ErrorService.showError(t("errorFetching"));
    }
  }, [error]);

  // Using proxy for selectedHashtags, to decide when to update state, when hashtags really change
  useEffect(() => {
    if (
      JSON.stringify(filters.selectedHashtags) !==
      JSON.stringify(selectedHashtags)
    ) {
      setSelectedHashtags(filters.selectedHashtags);
    }
  }, [filters.selectedHashtags]);

  useEffect(() => {
    if (albumSizes && albumSizes.findAlbumSize) {
      const { currentSize, maxSize } = albumSizes.findAlbumSize;
      const currentGb = parseFloat(
        (currentSize / 1024 / 1024 / 1024).toPrecision(2)
      );
      const maxGb = parseFloat((maxSize / 1024 / 1024 / 1024).toFixed(2));
      setIsStorageLimitReached(currentGb >= maxGb);
      console.log("album sizes updated", "current", currentGb, "max", maxGb);
    }
  }, [albumSizes]);

  //Filter images based on the consolidated filters
  useEffect(() => {
    if (album && !loading) {
      setLoading(true);
      loadInitialData(false); //Do not refresh counts and sizes, as we are loading initial data
    }
  }, [
    filters.filterApproved,
    filters.filterPublished,
    filters.orderBy,
    selectedHashtags,
  ]);

  //Refresh counts and sizes and images, as album has changed
  useEffect(() => {
    if (album && !loading) {
      loadInitialData(true);
    }
  }, [album]);

  useEffect(() => {
    // Filter images based on the consolidated filters
    const filtered = images.filter(({ node }) => {
      const publishedFilterCheck =
        filters.filterPublished !== undefined
          ? node.published === filters.filterPublished
          : true;
      const approvedFilterCheck =
        filters.filterApproved !== undefined
          ? node.approved === filters.filterApproved
          : true;
      return publishedFilterCheck && approvedFilterCheck;
    });

    //If filtered images are empty and images are present, load initial data, as due to mass unpublish or delete there could be such situation
    if (filtered.length === 0 && images.length > 0) {
      loadInitialData();
    }

    //Set filtered images
    setFilteredImages(filtered);
  }, [images]);

  /**
   * Loads initial data for the album.
   *
   * @param {boolean} refreshCountsAndSizes - If true, it will refresh counts and sizes
   * @return {Promise<number | undefined>} A promise that resolves with the total count of images in the album, or undefined if there is an error.
   */
  const loadInitialData = useCallback(
    async (refreshCountsAndSizes = true) => {
      if (album) {
        if (refreshCountsAndSizes) {
          loadAlbumSize({
            variables: { albumId: album.id },
            fetchPolicy: "network-only",
          });
          loadCounts();
        }

        console.log("load initial");
        lastCursor.current = null; //Nulify last cursor as we are loading again initial data
        return apolloClient
          .query<{ albumImages: PaginatedType<Image> }, ImageSearchProps>({
            query: albumImagesQuery,
            variables: {
              orderBy: filters.orderBy,
              albumId: album.id,
              first: loadCount,
              published: filters.filterPublished,
              approved: filters.filterApproved,
              hashtags: selectedHashtags,
            },
            fetchPolicy: "no-cache",
          })
          .then((resp) => {
            if (resp.data?.albumImages) {
              setImages(resp.data?.albumImages.edges);
              setHasNextPage(resp.data.albumImages.hasNextPage);
              return resp.data?.albumImages.totalCount;
            } else {
              return undefined;
            }
          })
          .catch((err) => {
            setError(err.message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [
      album,
      filters.orderBy,
      filters.filterApproved,
      filters.filterPublished,
      selectedHashtags,
    ]
  );

  const loadMore = useCallback(
    (cursor: string) => {
      if (album) {
        if (!hasNextPage || lastCursor.current === cursor || loading) return;
        setLoading(true);
        console.log("loadmore");

        apolloClient
          .query<{ albumImages: PaginatedType<Image> }, ImageSearchProps>({
            query: albumImagesQuery,
            variables: {
              orderBy: filters.orderBy,
              after: cursor,
              albumId: album.id,
              first: loadCount,
              published: filters.filterPublished,
              approved: filters.filterApproved,
              hashtags: selectedHashtags,
            },
            fetchPolicy: "no-cache",
          })
          .then((resp) => {
            if (resp.data?.albumImages) {
              lastCursor.current = cursor;
              //Merge new images with old images
              setImages((img) => {
                const concatenatedArray = [
                  ...img,
                  ...resp.data.albumImages.edges,
                ];
                const mergedArray = _.uniqBy(concatenatedArray, "cursor"); //Ensuring that we do not have duplicates
                return mergedArray;
              });
              setHasNextPage(resp.data.albumImages.hasNextPage);
            }
          })
          .catch((err) => {
            setError(err.message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [album, filters, hasNextPage, loading]
  );

  const onRemoved = useCallback(
    (ids: string | string[]) => {
      // Ensure ids is always an array
      const idsArray = Array.isArray(ids) ? ids : [ids];

      // Filter out images with ids present in idsArray
      const updatedImages = images.filter(
        (image) => !idsArray.includes(image.cursor)
      );

      ErrorService.showMessage(t("imageRemoved")); // Update message as needed
      setImages(updatedImages);
      loadCounts(); // Refresh counts

      // Load album size if needed, once after all removals
      if (album) {
        loadAlbumSize({
          variables: { albumId: album.id },
          fetchPolicy: "network-only",
        });
      }

      // Load initial data if we delete all currently loaded images, and total published count is more than removed count
      if (
        idsArray.length === images.length &&
        publishedCount > idsArray.length
      ) {
        loadInitialData();
      }
    },
    [images, album, publishedCount]
  );

  // Method called when updated images, same method called also from selected bulk actions
  const onUpdated = useCallback(
    (updatedImages: Image | Image[]) => {
      // Ensure updatedImages is always an array
      const updatedImagesArray = Array.isArray(updatedImages)
        ? updatedImages
        : [updatedImages];

      // Create a new array by merging the updated images into the existing images
      const newImages = images.map((imageEdge) => {
        const updatedImage = updatedImagesArray.find(
          (img) => img.id === imageEdge.cursor
        );
        return updatedImage
          ? { cursor: imageEdge.cursor, node: updatedImage }
          : imageEdge;
      });

      setImages(newImages);
      loadCounts(); // Refresh counts
    },
    [images]
  );

  const onHashtagSelected = useCallback(
    (hashtag?: string) => {
      const { selectedHashtags, ...params } = filters;

      if (!hashtag) {
        setFiltersQuery({ ...params, selectedHashtags: [] });
        return;
      }

      if (selectedHashtags.includes(hashtag)) {
        // If the clicked hashtag is already selected, deselect it
        setFiltersQuery({
          ...params,
          selectedHashtags: [],
        });
      } else {
        // If a different hashtag is clicked, select only that one
        setFiltersQuery({
          ...params,
          selectedHashtags: [hashtag],
        });
      }
    },
    [filters]
  );

  const count = useMemo(() => {
    return filters.filterPublished === false
      ? unPublishedCount
      : filters.filterApproved === false
      ? notApprovedCount
      : publishedCount;
  }, [filters, publishedCount, unPublishedCount, notApprovedCount]);

  const fileUploadTooltip = albumIsExpired
    ? t("albumExpiredTooltip")
    : isStorageLimitReached
    ? t("storageLimitTooltip")
    : undefined;

  return (
    <div className="flex-1 md:px-6 flex flex-col px-4">
      <Helmet>
        <title>Rompolo - {t("metaTitle")}</title>
      </Helmet>
      <div className="flex lg:flex-row flex-col justify-between items-end gap-4">
        <div>
          <div className="flex items-center gap-4 flex-wrap">
            <Title wrap="nowrap" size="H4">
              {t("photosVideos")}
            </Title>
            {album && albumSizes && (
              <BadgeAlbumSize sizes={albumSizes.findAlbumSize} />
            )}
          </div>
          <Text className="mt-2" color="gray">
            {t("photosVideosDescription")}
          </Text>
        </div>
        <div className="md:hidden block">
          <AlbumWillExpire withDownload={false} />
        </div>

        {album && (
          <div className="flex flex-col md:flex-row gap-4 w-full md:w-auto">
            <div>
              <FileUpload
                resize={album.optimizeImages}
                onUploadComplete={() => {}}
                tooltip={fileUploadTooltip}
                loadData={loadInitialData}
                album={album}
                disabled={isStorageLimitReached || albumIsExpired}
              />
            </div>
            <div>
              <DownloadAll
                type="button"
                title={t("downloadAll")}
                album={album}
              />
            </div>
          </div>
        )}
      </div>
      <TechnicalIssueAlert />
      {isStorageLimitReached && <StorageLimit />}
      {album && (
        <>
          <div className="flex flex-wrap-reverse items-center gap-6 justify-between md:mt-10 mt-6">
            <ImageGridFilters
              setFilter={setFiltersQuery}
              published={filters.filterPublished}
              approved={filters.filterApproved}
              publishedCount={publishedCount}
              unPublishedCount={unPublishedCount}
              notApprovedCount={notApprovedCount}
            />
            <div className="flex items-center md:gap-8 gap-6 flex-wrap-reverse">
              <Toggle
                fontSize={14}
                onChange={(val) =>
                  setFiltersQuery({
                    withCaptions: val,
                  })
                }
                checked={filters.withCaptions}
                label={t("withCaptions")}
              />
              {/* We show only to albums created after new feature were created */}
              {album.createdAt && (
                <SortBy
                  albumCreatedAt={new Date(album.createdAt)}
                  onChange={(orderBy) => {
                    setFiltersQuery({ orderBy });
                  }}
                  className="mt-[2px]"
                  orderBy={filters.orderBy}
                />
              )}
            </div>
          </div>
          {album?.hashtags && album?.hashtags?.length > 0 && (
            <HashtagSelector
              customColorOverlay={false}
              wrap={false}
              withSeeAll
              className="mt-4"
              disabled={selectedHashtags.length === 0 && images.length === 0}
              withStroke
              album={album}
              onClick={onHashtagSelected}
              selectedHashtags={selectedHashtags}
            />
          )}
          {albumIsExpired}
          {albumIsExpired && filteredImages?.length === 0 && <Expired />}
          {filteredImages?.length === 0 && !loading && !albumIsExpired && (
            <NoPhotos />
          )}
          <ImageGrid
            album={album}
            withCaptions={filters.withCaptions}
            loading={loading}
            hasNextPage={hasNextPage}
            loadMore={loadMore}
            items={filteredImages}
            onRemoved={onRemoved}
            onUpdated={onUpdated}
            count={count}
          />
          {loading && (
            <div
              className="py-10 w-full flex items-center justify-center"
              id="loading"
            >
              <Loading />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PhotosVideosPage;
