import React from "react";
import { Modal, Title } from "@mantine/core";
import { useTranslations } from "use-intl";
import { Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { createUserMutation } from "../../../graphql/userQueries";
import { ErrorService } from "../../../services";
import { Button, Input, PasswordInput, SelectInput } from "../../../common";
import { Role, User } from "../../../types/user.type";
import { IconX } from "@tabler/icons-react";

interface CreateUserModalProps {
  opened: boolean;
  close: () => void;
  onCreated: () => void;
}

const CreateUserModal: React.FC<CreateUserModalProps> = ({
  opened,
  close,
  onCreated,
}) => {
  const tV = useTranslations("Validation");

  const [createUser, { loading }] = useMutation<
    { createUser: User },
    { input: { email: string; password: string; fullName: string } }
  >(createUserMutation);

  const onSubmit = (values: {
    email: string;
    password: string;
    fullName: string;
  }) => {
    createUser({ variables: { input: values } })
      .then((response) => {
        const createdUser = response.data?.createUser;
        if (createdUser) {
          ErrorService.showMessage("✅ User created");
          onCreated();
          close();
        } else {
          ErrorService.showError("Error on user creation");
        }
      })
      .catch((error) => {
        ErrorService.showError("Error on user creation - " + error.message);
      });
  };

  const roles = [
    { value: "ADMIN", label: "Admin" },
    { value: "USER", label: "User" },
  ];

  return (
    <Modal
      opened={opened}
      onClose={close}
      centered
      radius={8}
      padding={40}
      size={600}
      withCloseButton={false}
    >
      <Formik
        initialValues={{
          email: "",
          password: "",
          fullName: "",
          role: Role.USER,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(tV("invalidEmail"))
            .required(tV("required")),
          password: Yup.string()
            .min(8, tV("passwordMinLength"))
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
              tV("passwordError")
            )
            .required(tV("required")),
          fullName: Yup.string().required(tV("required")),
          role: Yup.string()
            .oneOf(Object.values(Role), tV("invalidType"))
            .required(tV("required")),
        })}
        onSubmit={onSubmit}
        validateOnChange={false}
        enableReinitialize
      >
        {(props) => (
          <>
            <div className="mb-6">
              <div className="flex justify-between">
                <Title size="H5">Create user</Title>
                <IconX
                  onClick={close}
                  size={24}
                  className=" cursor-pointer stroke-medium-black"
                />
              </div>
            </div>
            <form className="flex flex-col gap-4">
              <Input
                name="fullName"
                label={"Full name"}
                placeholder={"Enter full name"}
                onChange={props.handleChange}
                error={props.errors.fullName}
              />
              <Input
                name="email"
                label={"Email"}
                placeholder={"Enter email"}
                onChange={props.handleChange}
                error={props.errors.email}
              />
              <SelectInput
                name="role"
                label={"Role"}
                placeholder={"Select role"}
                onChange={(value) => props.setFieldValue("role", value)}
                error={props.errors.role}
                data={roles}
                value={props.values.role}
              />
              <PasswordInput
                name="password"
                label={"Password"}
                placeholder={"Enter password"}
                onChange={props.handleChange}
                error={props.errors.password}
              />
              <div className="flex justify-end mt-4">
                <Button
                  onClick={props.handleSubmit}
                  loading={loading}
                  title={"Create user"}
                />
              </div>
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateUserModal;
