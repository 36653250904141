import { useCallback, useEffect, useMemo, useState } from "react";
import { checkMediaType } from "../../lib/utils";
import { IconAlertTriangle, IconEdit, IconTrash } from "@tabler/icons-react";
import { CaptionEdit } from "..";
import { useTranslations } from "use-intl";
import { ALLOWED_MAX_SIZE } from "./FileUploadDialog";
import { Hashtag } from "../../types/hashtag.type";
import { Album, PublicAlbum } from "../../types/album.type";
import { Loader, RingProgress } from "@mantine/core";

const FileToUpload = ({
  file,
  onRemove,
  onDataChange,
  dimensions,
  description,
  selectedHashtags,
  album,
  status,
  uploadProgress,
  loading,
  statusCode,
}: {
  onRemove: () => void;
  file: File;
  onDataChange: (value?: string, hashtags?: Hashtag[]) => void;
  dimensions: { width: number; height: number };
  description: string;
  album: Album | PublicAlbum;
  selectedHashtags: Hashtag[];
  status: "success" | "error" | "pending";
  statusCode?: number;
  uploadProgress?: number;
  loading?: boolean;
}) => {
  const [fileType, setFileType] = useState<"image" | "video" | "unknown">();
  const url = useMemo(() => URL.createObjectURL(file), [file]);
  const [isTextareaOpen, setIsTextareaOpen] = useState(false);
  const t = useTranslations("Photos");

  useEffect(() => {
    const getFileType = async () => {
      const fileType = await checkMediaType(file);
      setFileType(fileType);
      if (fileType === "unknown") {
        if (file.type.includes("image")) {
          setFileType("image");
        }
      }
    };

    getFileType();
  }, [file]);

  const fileIsTooLarge = file.size > ALLOWED_MAX_SIZE;
  const showAddCaptionButton = !fileIsTooLarge;

  const handleSave = useCallback(
    ({ caption, hashtags }: { caption: string; hashtags: string[] }) => {
      setIsTextareaOpen(true);
      const hashtagsToSave = hashtags.map(
        (id) => album.hashtags?.find((h) => h.id === id)!
      );
      onDataChange(caption, hashtagsToSave);
      handleClose();
    },
    []
  );

  const handleClose = useCallback(() => {
    setIsTextareaOpen(false);
  }, [selectedHashtags]);

  const edited = !!description || selectedHashtags.length !== 0;
  const isInErrorState = status === "error";
  const isSuccessState = status === "success";

  return (
    <div className={`flex flex-col mb-4 break-inside-avoid relative`}>
      <CaptionEdit
        album={album}
        opened={isTextareaOpen}
        onClose={handleClose}
        onSave={handleSave}
        currentCaption={description}
        albumHashtags={album.hashtags ?? []}
        selectedHashtags={selectedHashtags}
      />
      <div
        style={{
          width: dimensions.width,
          height: dimensions.height,
        }}
        className={`relative rounded-xl overflow-hidden`}
      >
        {!isSuccessState && !loading && (
          <div
            onClick={() => {
              onRemove();
            }}
            className=" bg-white rounded-sm flex w-6 h-6 items-center justify-center absolute top-3 right-3  cursor-pointer z-10"
          >
            {<IconTrash className=" stroke-red" size={16} />}
          </div>
        )}
        {/* If success, then add overlay */}
        {isSuccessState && (
          <div className="z-10 absolute inset-0 bg-light-gray/60 rounded-xl"></div>
        )}
        {fileType === "image" && (
          <img
            className={`absolute inset-0 w-full h-full object-cover rounded-xl ${
              isInErrorState && "border border-red"
            }`}
            src={url}
            alt={"file"}
          />
        )}
        {fileType === "video" && (
          <video
            loop
            playsInline
            autoPlay
            muted
            className={`absolute inset-0 w-full h-full object-cover rounded-xl ${
              isInErrorState && "border border-red"
            }`}
            src={url}
            onError={(e) => console.error("Video failed to load:", e)}
          />
        )}
        {showAddCaptionButton &&
          !isSuccessState &&
          !isInErrorState &&
          !loading && (
            <div
              onClick={() => !isTextareaOpen && setIsTextareaOpen(true)}
              className={`shadow-lg parent h-[35px] absolute left-4 bottom-3 md:bottom-4 rounded-xl overflow-hidden ${
                edited ? " bg-white" : " bg-black/30"
              } flex items-start justify-center cursor-pointer`}
            >
              <span
                className={`${
                  isTextareaOpen && "hidden"
                } flex items-center font-semibold text-sm mdLtext-base py-2 px-4 ${
                  edited ? "text-black" : "text-white"
                }   text-center`}
              >
                {edited ? (
                  <>
                    <IconEdit size={16} className=" stroke-black mr-1" />
                    {t("editCaption")}
                  </>
                ) : (
                  t("addCaption")
                )}
              </span>
            </div>
          )}
        {(fileIsTooLarge || isInErrorState) && (
          <div className="absolute left-4 bottom-3 md:bottom-4 rounded-lg bg-red/40 px-2 md:px-4 py-2 md:max-h-[35px] max-h-[33px] flex gap-1 items-center">
            <IconAlertTriangle size={16} className=" stroke-white" />
            <span className="font-semibold text-xs md:text-sm text-white whitespace-nowrap">
              {fileIsTooLarge && t("fileTooBig")}
              {isInErrorState &&
                (statusCode === 507
                  ? t("errorUploadingNotEnoughStorageStatus")
                  : t("errorUploadingStatus"))}
            </span>
          </div>
        )}

        {status === "success" && (
          <div className=" z-10 absolute left-4 bottom-3 md:bottom-4 rounded-lg bg-green/40 px-2 md:px-4 py-2 md:max-h-[35px] max-h-[33px] flex gap-1 items-center">
            <span className="font-semibold text-xs md:text-sm text-white whitespace-nowrap">
              {t("uploaded")}
            </span>
          </div>
        )}
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center z-10">
            {uploadProgress !== undefined &&
            uploadProgress > 0 &&
            uploadProgress < 100 ? (
              <RingProgress
                size={60}
                thickness={5}
                sections={[{ value: uploadProgress, color: "#222222" }]}
              />
            ) : (
              loading &&
              status === "pending" && <Loader size={60} color={"#222222"} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileToUpload;
