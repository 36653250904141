export interface User {
  id: string;
  email?: string;
  fullName?: string;
  role?: Role;
  verified?: Date;
  onboarded?: Date;
  isGuest?: boolean;
  newsAndReleases?: boolean;
  googleId?: number;
  banned?: boolean;
}

export interface UpdateUserInput {
  fullName?: string;
  email?: string;
  onboarded?: Date;
  updatesOnContributions?: boolean;
  newsAndReleases?: boolean;
}

export interface AdminUpdateUserInput {
  id: string;
  fullName?: string;
  password?: string;
  email?: string;
  role?: Role;
  onboarded?: Date | null;
  updatesOnContributions?: boolean;
  newsAndReleases?: boolean;
  verified?: Date | null;
  banned?: boolean;
}

export enum Role {
  USER = "USER",
  ADMIN = "ADMIN",
}
