import React, { useMemo, ChangeEvent } from "react";
import { Box, Popover, Select } from "@mantine/core";
import { Column } from "@tanstack/react-table";
import { Input } from "..";
import { IconFilter } from "@tabler/icons-react";
import CountFilter from "./CountFilter";

interface FilterOption {
  label: string;
  value: string;
}

interface FilterProps {
  column: Column<any, unknown>;
  type?: "select" | "input" | "range";
  onChange: (value: any) => void;
  customFilterOptions?: FilterOption[];
}

const Filter: React.FC<FilterProps> = ({
  column,
  type,
  onChange,
  customFilterOptions,
}) => {
  const columnFilterValue = column.getFilterValue() as string;

  const sortedUniqueValues = useMemo(() => {
    if (customFilterOptions) {
      return customFilterOptions;
    }
    return Array.from(column.getFacetedUniqueValues().keys())
      .sort()
      .reduce<{ label: string; value: string }[]>((result, k) => {
        if (k !== null) {
          if (typeof k === "boolean") {
            result.push({ label: k ? "Yes" : "No", value: String(k) });
          } else if (Array.isArray(k)) {
            k.forEach((v) => {
              const filterExists = result.find((r) => r.value === v);
              if (!filterExists && v) {
                result.push({ label: v, value: String(v) });
              }
            });
          } else if (k) {
            result.push({ label: k, value: String(k) });
          }
        } else {
          result.push({ label: "Empty", value: String(k) });
        }
        return result;
      }, []);
  }, [column.getFacetedUniqueValues()]);

  const handleFilterChange = (value: string | null) => {
    onChange(value ?? undefined);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleFilterChange(event.target.value ?? undefined);
  };

  return (
    <Popover width={250} position="bottom" withArrow shadow="md">
      <Popover.Target>
        <div
          className={`p-2 rounded-lg cursor-pointer ${
            columnFilterValue ? "bg-light-beige" : ""
          }`}
        >
          <IconFilter size={16} className="stroke-black " />
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Box>
          {type === "range" ? (
            <CountFilter column={column as any} onChange={handleFilterChange} />
          ) : type === "input" ? (
            <Input
              type="text"
              value={(columnFilterValue ?? "") as string}
              onChange={handleInputChange}
              placeholder={`Search... (${
                column.getFacetedUniqueValues().size
              })`}
            />
          ) : (
            <Select
              placeholder="Search..."
              clearable
              onChange={handleFilterChange}
              searchable
              data={sortedUniqueValues}
              value={columnFilterValue ?? null}
              onClear={() => handleFilterChange(null)}
            />
          )}
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};

export default Filter;
