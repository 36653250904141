import { useTranslations } from "use-intl";
import {
  AlbumWillExpire,
  HowItWorksGeneral,
  HowItWorksPhotoWall,
  InfoCenter,
  Text,
  Title,
} from "../../common";
import { useAuth } from "../../hooks";
import {
  AlbumExpired,
  AlbumStatus,
  PhotoWallDashboard,
  Statistics,
  AlbumDashboard,
} from "./components";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const Dashboard = () => {
  const album = useSelector((state: RootState) => state.album.album);
  const { user } = useAuth();
  const t = useTranslations("Dashboard");

  const albumIsExpired =
    album?.expirationDate && new Date() > new Date(album.expirationDate);

  return (
    <div className="mx-auto flex flex-col md:max-w-[1248px] md:px-6">
      <div className="md:p-0 px-4 flex-1">
        {albumIsExpired ? <AlbumExpired /> : <AlbumWillExpire />}
      </div>
      <div className="md:px-0 px-4 mb-6">
        <Title size="H3">
          {t.rich("welcome", { name: () => user?.fullName?.split(" ")[0] })}
        </Title>
        <Text className="mt-2" color="gray">
          {t("welcomeDescription")}
        </Text>
      </div>

      <div className="flex flex-col lg:flex-row gap-6">
        <div className="lg:w-[840px] flex flex-col gap-6">
          <AlbumDashboard />
          <PhotoWallDashboard />
        </div>
        <div className="flex flex-col gap-6">
          <Statistics />
          <AlbumStatus />
        </div>
      </div>
      {/* How It works blocks, will work only while in dashboard */}
      <HowItWorksGeneral />
      <HowItWorksPhotoWall />
      {/* How it works end */}
      <InfoCenter />
    </div>
  );
};

export default Dashboard;
