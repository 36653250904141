import React from "react";
import { NavigationWrapper } from "../common";
import {
  AccountPage,
  AdminAlbumSettingsPage,
  AdminStatiscticsPage,
  AdminUsersPage,
  AlbumNotFoundPage,
  AlbumPage,
  AlbumPasswordProtectedPage,
  AlbumSettingsPage,
  AlbumsPage,
  BadgeRedirectPage,
  BadgesPage,
  DashboardPage,
  OnboardingPage,
  PhotoWallPage,
  PhotosVideosPage,
  PricingPage,
  SignInPage,
  SignUpPage,
  TemplatesPage,
  VerifyPage,
} from "../pages";
import ForgotPasswordPage from "../pages/ForgotPasswordPage/ForgotPasswordPage";
import PasswordResetPage from "../pages/PasswordResetPage/PasswordResetPage";

export interface Role {
  ADMIN?: boolean;
  USER?: boolean;
}

export interface RouteConfig {
  component: React.ComponentType;
  wrapperComponent?: React.ComponentType;
  path?: string;
  hasAccess?: boolean;
  index?: boolean;
}

export const applicationRoutes = (roles?: Role): RouteConfig[] => [
  {
    component: BadgesPage,
    wrapperComponent: NavigationWrapper,
    path: "admin/badges",
    ...(roles && { hasAccess: roles.ADMIN }),
  },
  {
    component: AlbumsPage,
    wrapperComponent: NavigationWrapper,
    path: "admin/albums",
    ...(roles && { hasAccess: roles.ADMIN }),
  },
  {
    component: AdminUsersPage,
    wrapperComponent: NavigationWrapper,
    path: "admin/users",
    ...(roles && { hasAccess: roles.ADMIN }),
  },
  {
    component: AdminAlbumSettingsPage,
    wrapperComponent: NavigationWrapper,
    path: "admin/album-settings/:id",
    ...(roles && { hasAccess: roles.ADMIN }),
  },
  {
    component: AdminStatiscticsPage,
    wrapperComponent: NavigationWrapper,
    path: "admin/statistics",
    ...(roles && { hasAccess: roles.ADMIN }),
  },
  {
    component: BadgeRedirectPage,
    path: "badge/:shortId",
    ...(roles && { hasAccess: true }),
  },
  {
    component: AlbumNotFoundPage,
    path: "album-not-found",
    ...(roles && { hasAccess: true }),
  },
  {
    component: SignUpPage,
    path: "signup",
    ...(roles && { hasAccess: true }),
  },
  {
    component: ForgotPasswordPage,
    path: "forgot-password",
    ...(roles && { hasAccess: true }),
  },
  {
    component: PasswordResetPage,
    path: "reset-password",
    ...(roles && { hasAccess: true }),
  },
  {
    component: SignInPage,
    path: "signin",
    ...(roles && { hasAccess: true }),
  },
  {
    component: VerifyPage,
    path: "verify",
    ...(roles && { hasAccess: true }),
  },
  {
    component: OnboardingPage,
    path: "onboarding",
    ...(roles && { hasAccess: roles.USER }),
  },
  {
    component: DashboardPage,
    wrapperComponent: NavigationWrapper,
    index: true,
    ...(roles && { hasAccess: roles.USER || roles.ADMIN }),
  },
  {
    component: PhotosVideosPage,
    wrapperComponent: NavigationWrapper,
    path: "photos-videos",
    ...(roles && { hasAccess: roles.USER || roles.ADMIN }),
  },
  {
    component: TemplatesPage,
    wrapperComponent: NavigationWrapper,
    path: "templates",
    ...(roles && { hasAccess: roles.USER || roles.ADMIN }),
  },
  {
    component: AlbumSettingsPage,
    wrapperComponent: NavigationWrapper,
    path: "album-settings",
    ...(roles && { hasAccess: roles.USER || roles.ADMIN }),
  },
  {
    component: AccountPage,
    wrapperComponent: NavigationWrapper,
    path: "account",
    ...(roles && { hasAccess: roles.USER || roles.ADMIN }),
  },
  {
    component: AlbumPasswordProtectedPage,
    path: "album/password-protected/:shortId",
    ...(roles && { hasAccess: true }),
  },
  {
    component: PhotoWallPage,
    path: "wall/:shortId",
    ...(roles && { hasAccess: true }),
  },
  {
    component: AlbumPage,
    path: "album/:shortId",
    ...(roles && { hasAccess: true }),
  },
  {
    component: PricingPage,
    wrapperComponent: NavigationWrapper,
    path: "pricing",
    ...(roles && { hasAccess: roles.USER || roles.ADMIN }),
  },
];
