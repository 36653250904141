import { useTranslations } from "use-intl";
import { Image } from "../../types/image.type";
import { ImageType } from "../../types/image-type.enum";
import { VideoComponent } from ".";
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "react-zoom-pan-pinch";
import { useIsSmallScreen } from "../../hooks";
import { useState } from "react";

const GalleryImage = ({
  image,
  url,
  thumbnailUrl,
  isVisible,
}: {
  image: Image;
  url: string;
  thumbnailUrl: string;
  isVisible?: boolean;
}) => {
  const t = useTranslations("Photos");
  return (
    <>
      {/* Render image/video here based on the type */}
      {image.type === ImageType.PICTURE ? (
        <ImageWrapper>
          <img
            src={url}
            alt={image.description || t("galleryImage")}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </ImageWrapper>
      ) : (
        <VideoComponent
          isVisible={isVisible}
          thumbnailUrl={thumbnailUrl}
          videoUrl={url}
        />
      )}
    </>
  );
};

const ImageWrapper = ({ children }: { children: React.ReactNode }) => {
  const isSmallScreen = useIsSmallScreen();
  const [panningEnabled, setPanningEnabled] = useState(false);

  const handleZoom = (ref: ReactZoomPanPinchRef) => {
    const { state } = ref;
    if (state.scale > 1) {
      setPanningEnabled(true);
    } else {
      setPanningEnabled(false);
    }
  };

  if (!isSmallScreen) {
    return <>{children}</>;
  }

  return (
    <TransformWrapper
      onZoom={handleZoom}
      panning={{
        disabled: !panningEnabled,
      }}
      doubleClick={{
        step: 0.5,
        mode: "zoomIn",
        animationTime: 1,
        animationType: "linear",
      }}
    >
      <TransformComponent wrapperStyle={{ overflow: "visible" }}>
        {children}
      </TransformComponent>
    </TransformWrapper>
  );
};

export default GalleryImage;
