import { Popover } from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";
import { Button, DownloadAll, Text } from "../../../common";
import { IconChevronDown, IconShare2 } from "@tabler/icons-react";
import { useTranslations } from "use-intl";
import { PublicAlbum } from "../../../types/album.type";

const MoreButton = ({
  opened,
  close,
  open,
  album,
  onShareClick,
  hasImages,
}: {
  opened: boolean;
  close: () => void;
  open: () => void;
  album: PublicAlbum;
  onShareClick: () => void;
  hasImages?: boolean;
}) => {
  const ref = useClickOutside(() => close());
  const t = useTranslations("Album");

  return (
    <Popover
      keepMounted
      opened={opened}
      width={272}
      position="bottom"
      shadow="md"
      styles={{
        dropdown: {
          transform: "translateX(-24px)",
        },
      }}
    >
      <Popover.Target>
        <Button
          onClick={() => open()}
          type="outline"
          iconRight={
            <IconChevronDown
              stroke={3}
              className=" stroke-black mt-1"
              size={16}
            />
          }
          className="flex-1"
          title={t("more")}
        />
      </Popover.Target>
      <Popover.Dropdown ref={ref} className="p-0 rounded-xl">
        <div className="p-6 flex flex-col gap-6">
          <DownloadAll
            title={t("downloadAlbum")}
            disabled={!hasImages}
            album={album}
            onClose={close}
          />
          <Text
            onClick={() => {
              onShareClick(), close();
            }}
            className="flex gap-2 items-center font-semibold cursor-pointer"
          >
            <IconShare2 stroke={3} size={16} className="stroke-black" />{" "}
            {t("share")}
          </Text>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

export default MoreButton;
