import { gql } from "@apollo/client";

const PaymentDetails = gql`
  fragment PaymentDetails on Payment {
    album {
      id
      name
      shortId
      user {
        email
        fullName
      }
      plan
      maxSize
      type
      eventDate
    }
    albumId
    createdAt
    updatedAt
    id
    customer
    processed
    shippingAddress
    paymentId
    currency
    paymentStatus
    productIds
    storage
    amount
    refundedAmount
    discount
    plan
  }
`;

// Query for getting current user data
export const badgePaymentsQuery = gql`
  query BadgePayments(
    $search: String
    $processed: Boolean
    $after: String
    $albumId: String
    $customer: String
    $first: Int
    $offset: Int
    $paymentId: String
    $createdAtEnd: DateTime
    $createdAtStart: DateTime
    $updatedAtEnd: DateTime
    $updatedAtStart: DateTime
    $operationType: OperationType
  ) {
    findPaymentsWithBadges(
      search: $search
      processed: $processed
      after: $after
      first: $first
      offset: $offset
      albumId: $albumId
      createdAtEnd: $createdAtEnd
      createdAtStart: $createdAtStart
      customer: $customer
      paymentId: $paymentId
      updatedAtEnd: $updatedAtEnd
      updatedAtStart: $updatedAtStart
      operationType: $operationType
    ) {
      nodes {
        ...PaymentDetails
      }
      hasNextPage
      totalCount
    }
  }
  ${PaymentDetails}
`;

export const markPaymentAsProcessedQuery = gql`
  mutation MarkPaymentAsProcessed($input: MarkPaymentAsProcessedInput!) {
    markPaymentAsProcessed(markPaymentAsProcessedInput: $input)
  }
`;

export const createCheckoutSessionMutation = gql`
  mutation CreateCheckoutSession($input: CreateCheckoutSessionInput!) {
    createCheckoutSession(createCheckoutSessionInput: $input)
  }
`;

export const findTotalIncomeQuery = gql`
  query FindTotalIncome {
    findTotalPaymentIncome {
      totalIncome
      paymentCount
    }
  }
`;

export const findDailyAccumulatedPaymentAmount = gql`
  query FindDailyAccumulatedPaymentAmount(
    $createdAtEnd: DateTime
    $createdAtStart: DateTime
  ) {
    findDailyAccumulatedPaymentAmount(
      createdAtEnd: $createdAtEnd
      createdAtStart: $createdAtStart
    ) {
      accumulated_amount
      date
    }
  }
`;
