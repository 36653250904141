import { PublicAlbum, Layout } from "../../../types/album.type";
import CardHeader from "./CardHeader";
import CircleHeader from "./CircleHeader";
import DoubleHeader from "./DoubleHeader";
import SingleHeader from "./SingleHeader";

const EventAlbumHeader = ({
  album,
  disabled,
  onUploadComplete,
  hasImages,
  loadData,
  fileUploadTooltip,
}: {
  hasImages?: boolean;
  album: PublicAlbum;
  disabled?: boolean | undefined;
  onUploadComplete: () => void;
  loadData: () => Promise<number | void>;
  fileUploadTooltip?: string;
}) => {
  if (album.layout === Layout.SINGLE) {
    return (
      <SingleHeader
        fileUploadTooltip={fileUploadTooltip}
        onUploadComplete={onUploadComplete}
        hasImages={hasImages}
        loadData={loadData}
        disabled={disabled}
        album={album}
      />
    );
  } else if (album.layout === Layout.CARD) {
    return (
      <CardHeader
        album={album}
        fileUploadTooltip={fileUploadTooltip}
        onUploadComplete={onUploadComplete}
        hasImages={hasImages}
        loadData={loadData}
        disabled={disabled}
      />
    );
  } else if (album.layout === Layout.CIRCLE) {
    return (
      <CircleHeader
        album={album}
        fileUploadTooltip={fileUploadTooltip}
        onUploadComplete={onUploadComplete}
        hasImages={hasImages}
        loadData={loadData}
        disabled={disabled}
      />
    );
  } else {
    return (
      <DoubleHeader
        fileUploadTooltip={fileUploadTooltip}
        onUploadComplete={onUploadComplete}
        hasImages={hasImages}
        loadData={loadData}
        disabled={disabled}
        album={album}
      />
    );
  }
};

export default EventAlbumHeader;
