import { CellContext, ColumnDef } from "@tanstack/react-table";
import { Text, TextWithTooltip } from "../common";
import { Album } from "../types/album.type";
import { User } from "../types/user.type";
import { Plan } from "../types/plan.enum";
import moment from "moment";
import { EventType } from "../types/event.enum";
import CountFilter from "../common/ReactTable/CountFilter";

export const albumColumns: ColumnDef<Album, any>[] = [
  {
    header: "Short Id",
    accessorKey: "shortId",
    enableColumnFilter: false,
    cell: (props: CellContext<Album, string>) => (
      <TextWithTooltip text={props.getValue()} />
    ),
  },
  {
    header: "Album Url",
    accessorKey: "shortId",
    enableColumnFilter: false,
    cell: (props: CellContext<Album, string>) => (
      <a className="hover:underline" href={`/album/${props.getValue()}`}>
        <Text>{`/album/${props.getValue()}`}</Text>
      </a>
    ),
  },
  {
    header: "User email",
    accessorKey: "userEmail",
    enableColumnFilter: false,
    accessorFn: (data) => data.user,
    cell: (props: CellContext<Album, User>) => (
      <Text size="sm">{`${props.getValue()?.email}`}</Text>
    ),
  },
  {
    header: "User name",
    accessorKey: "userName",
    enableColumnFilter: false,
    accessorFn: (data) => data.user,
    cell: (props: CellContext<Album, User>) => (
      <Text size="sm">{`${props.getValue()?.fullName}`}</Text>
    ),
  },
  {
    header: "Album",
    accessorKey: "name",
    enableColumnFilter: false,
    cell: (props: CellContext<Album, string>) => (
      <Text size="sm">{props.getValue()}</Text>
    ),
  },
  {
    header: "Type",
    accessorKey: "type",
    enableColumnFilter: true,
    cell: (props: CellContext<Album, EventType>) => (
      <Text size="sm">{props.getValue()}</Text>
    ),
    filterFn: "equalsString",
    meta: {
      filterType: "select",
      filterOptions: [
        ...Object.values(EventType).map((type) => ({
          label: type,
          value: type,
        })),
      ],
    },
  },
  {
    header: "Plan",
    accessorKey: "plan",
    enableColumnFilter: true,
    cell: (props: CellContext<Album, Plan>) => (
      <Text size="sm">{props.getValue()}</Text>
    ),
    filterFn: "equalsString",
    meta: {
      filterType: "select",
      filterOptions: [
        { label: "Free", value: Plan.free },
        { label: "PayOnce", value: Plan.payOnce },
        { label: "Susbcription", value: Plan.subscription },
      ],
    },
  },
  {
    header: "Images count",
    accessorKey: "imagesCount",
    cell: (props: CellContext<Album, number>) => (
      <Text size="sm">{props.getValue()}</Text>
    ),
    enableColumnFilter: false,
    meta: {
      filterType: "range",
      filterComponent: CountFilter,
    },
    filterFn: (row, columnId, filterValue) => {
      if (!filterValue) return true;
      const { from, to } = JSON.parse(filterValue);
      const value = row.getValue(columnId) as number;
      if (from !== null && to !== null) {
        return value >= from && value <= to;
      } else if (from !== null) {
        return value >= from;
      } else if (to !== null) {
        return value <= to;
      }
      return true;
    },
  },
  {
    header: "Max Storage",
    accessorKey: "maxSize",
    enableColumnFilter: false,
    cell: (props: CellContext<Album, number>) => (
      <Text size="sm">{props.getValue()}</Text>
    ),
  },
  {
    header: "Expiration date",
    accessorKey: "expirationDate",
    enableColumnFilter: false,
    cell: (props: CellContext<Album, Date>) => {
      return (
        <Text size="sm">
          {props.getValue()
            ? moment(props.getValue()).format("DD-MM-YYYY HH:mm")
            : "-"}
        </Text>
      );
    },
  },
];
