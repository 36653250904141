import { useTranslations } from "use-intl";
import { useFormikContext } from "formik";
import {
  FileButton,
  Group,
  Radio,
  RingProgress,
  SelectProps,
} from "@mantine/core";
import { Album, CreateAlbumInput, Layout } from "../../types/album.type";
import { getImageUrl } from "../../lib/utils";
import {
  AlbumColorPicker,
  Premium,
  SelectInput,
  SettingsBlock,
  Text,
  Toggle,
} from "..";
import { EventType } from "../../types/event.enum";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { removeProfileImageMutation } from "../../graphql/albumQueries";
import { IconCheck, IconTrash, IconUpload } from "@tabler/icons-react";
import { ErrorService } from "../../services";
import { setAlbum } from "../../store/album.reducer";
import axiosClient from "../../lib/axiosClient";
import SingleColumnStylePreview from "./StylePreviews/SingleColumnStylePreview";
import CardStylePreview from "./StylePreviews/CardStylePreview";
import CircleStylePreview from "./StylePreviews/CircleStylePreview";
import DoubleColumnStylePreview from "./StylePreviews/DoubleColumnStylePreview";
import { useIsSmallScreen } from "../../hooks";
import { DateFont, Font } from "../../types/font.enum";
import FontPreview from "./FontPreview";

const StyleSettings = ({
  album,
  handleFieldChange,
}: {
  album?: Album;
  handleFieldChange: (e: React.ChangeEvent<any> | string, value?: any) => void;
}) => {
  const t = useTranslations("AlbumSettings");
  const formik = useFormikContext<CreateAlbumInput>();
  const [file, setFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const dispatch = useDispatch();
  const uploadButtonRef = useRef<HTMLDivElement>(null);
  const thumbnailUrl =
    album?.profileThumbnail && getImageUrl(album?.profileThumbnail);
  const isSmallScreen = useIsSmallScreen();

  const [removeProfileImage] = useMutation<
    { removeProfileImage: boolean },
    { id: string }
  >(removeProfileImageMutation);

  const fonts: { value: Font; label: string }[] = [
    { value: Font.Inter, label: "Inter" },
    { value: Font.Poppins, label: "Poppins" },
    { value: Font.Playfair, label: "Playfair" },
    { value: Font.Aboreto, label: "Aboreto" },
    { value: Font.Afacad, label: "Afacad" },
    { value: Font.Hedvig, label: "Hedvig Letters Serif" },
    { value: Font.Julius, label: "Julius Sans One" },
    { value: Font.AlexBrush, label: "Alex Brush" },
    { value: Font.Allura, label: "Allura" },
    { value: Font.Bebas, label: "Bebas Neue" },
    { value: Font.Chonburi, label: "Chonburi" },
    { value: Font.OpenSans, label: "Open Sans" },
    { value: Font.Raleway, label: "Raleway" },
  ];

  useEffect(() => {
    const handleUpload = async () => {
      if (!file || !album) return;

      const formData = new FormData();
      try {
        formData.append("files", file, file.name);
      } catch (error) {
        console.error("Error processing file:", file.name, error);
      }
      try {
        const response = await axiosClient.post<{
          profileImage: string;
          profileThumbnail: string;
        }>(`/image/album-profile/${album?.id}`, formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / (progressEvent?.total ?? 0)
            );
            setUploadProgress(percentCompleted);
          },
        });

        dispatch(
          setAlbum({
            ...album,
            profileImage: response.data.profileImage,
            profileThumbnail: response.data.profileThumbnail,
          })
        );
      } catch (error) {
        ErrorService.showError(t("Files.errorUploading"));
      }
    };

    if (file) {
      handleUpload();
    }
  }, [file]);

  // Reset upload progress when it reaches 100
  useEffect(() => {
    if (uploadProgress === 100) {
      setUploadProgress(0);
    }
  }, [uploadProgress]);

  const isFree = album?.plan === "free";

  const renderSelectOption: SelectProps["renderOption"] = ({
    option,
    checked,
  }) => (
    <div className="flex items-center gap-2">
      {checked && <IconCheck className="stroke-black" size={16} />}
      <span
        style={{
          fontFamily: option.value,
        }}
      >
        {option.label}
      </span>
    </div>
  );

  return (
    <>
      <SettingsBlock description={t("fontDescription")}>
        {/* Fonts  */}
        <div className="flex gap-6 w-full">
          <div className="flex-1">
            <SelectInput
              onChange={(value) => handleFieldChange("titleFont", value)}
              value={formik.values.titleFont}
              data={fonts}
              name="titleFont"
              label={t("titleFont")}
              error={formik.errors.titleFont}
              renderOption={renderSelectOption}
            />
          </div>
          <div className="flex-1">
            <SelectInput
              onChange={(value) => handleFieldChange("descriptionFont", value)}
              value={formik.values.descriptionFont}
              data={fonts}
              name="descriptionFont"
              label={t("descriptionFont")}
              error={formik.errors.descriptionFont}
              renderOption={renderSelectOption}
            />
          </div>
        </div>
        <Radio.Group
          value={formik.values.dateFont}
          onChange={(val) => handleFieldChange("dateFont", val)}
          name="dateFont"
        >
          <Text className="mb-4" color="gray">
            {t("eventDateFont")}
          </Text>
          <Group>
            <Radio
              variant="outline"
              color="#222222"
              styles={{ label: { fontSize: 16 } }}
              value={DateFont.Title}
              label={t("sameAsTitle")}
            />
            <Radio
              variant="outline"
              color="#222222"
              styles={{ label: { fontSize: 16 } }}
              value={DateFont.Description}
              label={t("sameAsDescription")}
            />
          </Group>
        </Radio.Group>
        <FontPreview />
      </SettingsBlock>
      <SettingsBlock description={t("styleDescription")}>
        {album && (
          <div>
            <Text className="mb-2" color="gray">
              {formik.values.type === EventType.MEMORIAL
                ? t("profileImage")
                : t("coverImage")}
            </Text>
            <div className="flex items-center gap-4">
              <FileButton
                onChange={setFile}
                accept="image/png,image/webp,image/jpeg"
              >
                {(props) => (
                  <div
                    ref={uploadButtonRef}
                    {...props}
                    className={`rounded-lg border-dashed cursor-pointer ${
                      !album.profileImage && "border"
                    } border-light-black w-16 h-16 bg-light-gray flex items-center justify-center relative`}
                  >
                    {album?.profileThumbnail && uploadProgress === 0 ? (
                      <img
                        src={thumbnailUrl}
                        alt={album.name}
                        className="w-full h-full object-cover rounded-lg absolute"
                      />
                    ) : uploadProgress > 0 ? (
                      <RingProgress
                        size={60}
                        sections={[{ value: uploadProgress, color: "#222222" }]}
                      />
                    ) : (
                      <IconUpload
                        size={16}
                        className="stroke-black cursor-pointer"
                      />
                    )}
                  </div>
                )}
              </FileButton>
              {album.profileImage ? (
                <div className="flex gap-4">
                  <Text
                    className=" cursor-pointer font-medium flex gap-1 items-center "
                    onClick={() => {
                      uploadButtonRef.current?.click();
                    }}
                  >
                    <IconUpload
                      size={16}
                      className="stroke-black cursor-pointer"
                    />
                    {t("replace")}
                  </Text>
                  <Text
                    color="red"
                    className=" cursor-pointer font-medium flex gap-1 items-center"
                    onClick={() => {
                      album &&
                        removeProfileImage({ variables: { id: album.id } })
                          .then((resp) => {
                            if (resp.data?.removeProfileImage) {
                              dispatch(
                                setAlbum({
                                  ...album,
                                  profileImage: undefined,
                                  profileThumbnail: undefined,
                                })
                              );
                            } else {
                              ErrorService.showError(
                                t("errorRemovingProfileImage")
                              );
                            }
                          })
                          .catch((_) => {
                            ErrorService.showError(
                              t("errorRemovingProfileImage")
                            );
                          });
                    }}
                  >
                    <IconTrash
                      size={16}
                      className="stroke-red cursor-pointer"
                    />
                    {t("remove")}
                  </Text>
                </div>
              ) : (
                <Text color="gray">
                  {formik.values.type === EventType.MEMORIAL
                    ? t("profileImageDescription")
                    : t("bannerImageDescription")}
                </Text>
              )}
            </div>
          </div>
        )}
        <div>
          <Text className="mb-4" color="gray">
            {t("backgroundColor")}
          </Text>
          <AlbumColorPicker
            value={formik.values.color}
            name="color"
            handleChange={handleFieldChange}
          />
        </div>
        {formik.values.type !== EventType.MEMORIAL && (
          <>
            <Radio.Group
              value={formik.values.layout}
              onChange={(val) => handleFieldChange("layout", val)}
              name="layout"
            >
              <Text className="mb-4" color="gray">
                {t("coverImagePreferences")}
              </Text>
              <div className="flex gap-4 lg:flex-row flex-col mb-6">
                <div className="flex flex-col flex-1">
                  <CircleStylePreview
                    album={album}
                    formik={formik}
                    thumbnailUrl={thumbnailUrl}
                  />
                  <Radio
                    variant="outline"
                    color="#222222"
                    styles={{ label: { fontSize: 16 } }}
                    value={Layout.CIRCLE}
                    label={
                      isSmallScreen ? t("circleHalfMobile") : t("circleHalf")
                    }
                  />
                </div>
                <div className="flex flex-col flex-1">
                  <CardStylePreview
                    album={album}
                    formik={formik}
                    thumbnailUrl={thumbnailUrl}
                  />
                  <Radio
                    variant="outline"
                    color="#222222"
                    styles={{ label: { fontSize: 16 } }}
                    value={Layout.CARD}
                    label={isSmallScreen ? t("cardHalfMobile") : t("cardHalf")}
                  />
                </div>
              </div>
              <div className="flex gap-4 lg:flex-row flex-col">
                <div className="flex flex-col flex-1">
                  <SingleColumnStylePreview
                    album={album}
                    formik={formik}
                    thumbnailUrl={thumbnailUrl}
                  />
                  <Radio
                    variant="outline"
                    color="#222222"
                    styles={{ label: { fontSize: 16 } }}
                    value={Layout.SINGLE}
                    label={t("coverFullPage")}
                  />
                </div>
                <div className="flex flex-col flex-1">
                  <DoubleColumnStylePreview
                    album={album}
                    formik={formik}
                    thumbnailUrl={thumbnailUrl}
                  />
                  <Radio
                    variant="outline"
                    color="#222222"
                    styles={{ label: { fontSize: 16 } }}
                    value={Layout.DOUBLE}
                    label={
                      isSmallScreen
                        ? t("coverHalfPageMobile")
                        : t("coverHalfPage")
                    }
                  />
                </div>
              </div>
            </Radio.Group>

            {formik.values.layout === Layout.DOUBLE && (
              <div>
                <Text className="mb-4" color="gray">
                  {t("bannerBackgroundColor")}
                </Text>
                <AlbumColorPicker
                  value={formik.values.bannerColor}
                  name="bannerColor"
                  handleChange={handleFieldChange}
                />
              </div>
            )}
          </>
        )}
        <div>
          <Text className="mb-4" color="gray">
            {t("captionPreferences")}
          </Text>
          <div className="flex flex-col gap-4">
            <Toggle
              fontSize={16}
              label={t("displayCaption")}
              checked={formik.values.displayCaption ?? true}
              onChange={(value) => handleFieldChange("displayCaption", value)}
            />
            <Toggle
              fontSize={16}
              label={t("displayContributor")}
              checked={formik.values.displayContributor ?? true}
              onChange={(value) =>
                handleFieldChange("displayContributor", value)
              }
            />
          </div>
        </div>
        <div>
          <div className="mb-4 flex gap-2 items-center">
            <Text className="" color="gray">
              {t("headerPreferences")}
            </Text>
            {isFree && <Premium gray />}
          </div>

          <div className="flex flex-col gap-4">
            <Toggle
              tooltip={t("displayMoreActionsTooltip")}
              disabled={isFree}
              fontSize={16}
              label={t("displayMoreActions")}
              checked={formik.values.displayMoreActionsButton ?? true}
              onChange={(value) =>
                handleFieldChange("displayMoreActionsButton", value)
              }
            />
            <Toggle
              tooltip={t("displayPhotoWallTooltip")}
              disabled={isFree}
              fontSize={16}
              label={t("displayPhotoWall")}
              checked={formik.values.displayPhotowallButton ?? true}
              onChange={(value) =>
                handleFieldChange("displayPhotowallButton", value)
              }
            />
            <Toggle
              disabled={isFree}
              fontSize={16}
              label={t("removeBranding")}
              checked={formik.values.removeBranding ?? false}
              onChange={(value) => handleFieldChange("removeBranding", value)}
            />
          </div>
        </div>
      </SettingsBlock>
    </>
  );
};

export default StyleSettings;
