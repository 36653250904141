import { Select, SelectProps } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";

const SelectInput = ({
  colorScheme = "white",
  styles,
  ...props
}: {
  colorScheme?: "black" | "white";
} & SelectProps) => {
  const dynamicStyles = {
    ...styles,
    input: {
      height: 44,
      fontSize: 16,
      borderRadius: 8,
      backgroundColor: colorScheme === "black" ? "#222222" : "white",
      color: colorScheme === "black" ? "white" : "#222222",
      borderColor: colorScheme === "black" ? "#7A7A7A" : "#E0E0E0",
    },
    label: {
      fontSize: 16,
      fontWeight: 400,
      marginBottom: 8,
      color: colorScheme === "black" ? "#7A7A7A" : "#626262",
    },
    dropdown: {
      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
      fontSize: 16,
      borderRadius: 12,
    },
    option: {
      fontSize: 16,
    },
  };
  return (
    <Select
      allowDeselect={false}
      withCheckIcon={false}
      {...props}
      rightSection={
        <IconChevronDown
          size={16}
          className={` mr-4 ${
            colorScheme === "black" ? "stroke-white" : "stroke-black"
          }`}
        />
      }
      styles={dynamicStyles}
    />
  );
};

export default SelectInput;
