import { gql } from "@apollo/client";

const AlbumDetails = gql`
  fragment AlbumDetails on Album {
    id
    name
    shortId
    description
    color
    bannerColor
    type
    public
    requireApproval
    maxSize
    plan
    user {
      id
      fullName
      email
      banned
    }
    logo
    expirationDate
    activationDate
    invitationDate
    createdAt
    updatedAt
    profileImage
    profileThumbnail
    passwordProtected
    password
    yearsFrom
    yearsTo
    videoDuration
    imageDuration
    displayCaption
    optimizeImages
    displayContributor
    eventDate
    imagesCount
    layout
    passwordInInvite
    wallDisplayCode
    wallDisplayCaptions
    cleanupDate
    hashtags {
      id
      title
    }
    allowCreateHashtags
    displayMoreActionsButton
    displayPhotowallButton
    removeBranding
    showLogoOnAlbum
    titleFont
    descriptionFont
    dateFont
  }
`;

export const deleteAlbumMutation = gql`
  mutation RemoveAlbum($id: String!) {
    removeAlbum(id: $id)
  }
`;

export const removeProfileImageMutation = gql`
  mutation RemoveProfileImage($id: String!) {
    removeProfileImage(id: $id)
  }
`;

export const removeLogoMutation = gql`
  mutation RemoveLogo($id: String!) {
    removeLogo(id: $id)
  }
`;

// Query to get album by id,
export const findAlbumByIdQuery = gql`
  query AlbumById($id: String!) {
    albumById(id: $id) {
      ...AlbumDetails
    }
  }
  ${AlbumDetails}
`;

//Query to get album by shortId, we set fields seperatly to avoid unneccesary data
export const findAlbumByShortIdQuery = gql`
  query Album($shortId: String!) {
    album(id: $shortId) {
      id
      name
      shortId
      description
      color
      expirationDate
      createdAt
      profileImage
      profileThumbnail
      passwordProtected
      requireApproval
      wallDisplayCode
      wallDisplayCaptions
      user {
        id
        banned
      }
      hashtags {
        id
        title
      }
      public
      displayCaption
      displayContributor
      displayMoreActionsButton
      displayPhotowallButton
      removeBranding
      videoDuration
      imageDuration
      optimizeImages
      titleFont
      descriptionFont
      dateFont
      eventDate
      layout
      type
      yearsFrom
      yearsTo
    }
  }
`;

// Mutation for create new album
export const createAlbumMutation = gql`
  mutation CreateAlbum($input: CreateAlbumInput!) {
    createAlbum(createAlbumInput: $input) {
      ...AlbumDetails
    }
  }
  ${AlbumDetails}
`;

// Mutation for update album
export const updateAlbumMutation = gql`
  mutation UpdateAlbum($input: UpdateAlbumInput!) {
    updateAlbum(updateAlbumInput: $input) {
      ...AlbumDetails
    }
  }
  ${AlbumDetails}
`;

// Mutation for admin update album
export const adminUpdateAlbumMutation = gql`
  mutation AdminUpdateAlbum($input: AdminUpdateAlbumInput!) {
    adminUpdateAlbum(adminUpdateAlbumInput: $input) {
      ...AlbumDetails
    }
  }
  ${AlbumDetails}
`;

// Query for getting current user data
export const userAlbumsQuery = gql`
  query UserAlbums(
    $after: String
    $first: Int
    $id: String
    $name: String
    $operationType: OperationType
    $shortId: String
  ) {
    userAlbums(
      after: $after
      first: $first
      id: $id
      name: $name
      operationType: $operationType
      shortId: $shortId
    ) {
      edges {
        cursor
        node {
          ...AlbumDetails
        }
      }
      hasNextPage
      nodes {
        ...AlbumDetails
      }
      totalCount
    }
  }
  ${AlbumDetails}
`;

// Query for getting current user data
export const allAlbumsQuery = gql`
  query AllAlbums(
    $search: String
    $after: String
    $first: Int
    $id: String
    $name: String
    $operationType: OperationType
    $shortId: String
    $user: String
    $offset: Int
    $imagesCountFrom: Int
    $imagesCountTo: Int
    $type: AlbumType
    $plan: Plan
  ) {
    albums(
      search: $search
      after: $after
      first: $first
      id: $id
      name: $name
      operationType: $operationType
      shortId: $shortId
      user: $user
      imagesCountFrom: $imagesCountFrom
      imagesCountTo: $imagesCountTo
      offset: $offset
      type: $type
      plan: $plan
    ) {
      edges {
        cursor
        node {
          ...AlbumDetails
        }
      }
      hasNextPage
      nodes {
        ...AlbumDetails
      }
      totalCount
    }
  }
  ${AlbumDetails}
`;

// Mutation for signing in to album
export const signInToAlbumMutation = gql`
  mutation SignInToAlbum($albumId: String!, $password: String!) {
    signInToAlbum(albumId: $albumId, password: $password)
  }
`;
