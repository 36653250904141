import React, { useState, useEffect } from "react";
import { useTranslations } from "use-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { Helmet } from "react-helmet-async";
import { ScrollArea, Tabs, Badge } from "@mantine/core";
import {
  IconArtboard,
  IconHash,
  IconLockSquare,
  IconPhotoScan,
  IconPresentation,
  IconSettings2,
} from "@tabler/icons-react";

import {
  Button,
  Text,
  Title,
  GeneralSettings,
  PrivacySettings,
  SlideShowSettings,
  StyleSettings,
  AlbumHashTagsSettings,
} from "../../common";
import { RootState } from "../../store";
import { Album, Layout, UpdateAlbumInput } from "../../types/album.type";
import { EventType } from "../../types/event.enum";
import { updateAlbumMutation } from "../../graphql/albumQueries";
import { ErrorService } from "../../services";
import { setAlbum } from "../../store/album.reducer";
import { COLORS } from "../../common/AlbumColorPicker";
import { useConfirmNavigationWithSave, useScrollToHash } from "../../hooks";
import { ImageSettings } from "../../common/Settings";
import { DateFont, Font } from "../../types/font.enum";

const TABS = [
  { value: "general", label: "AlbumSettings.general", icon: IconSettings2 },
  {
    value: "image",
    label: "AlbumSettings.imageOptimization",
    icon: IconPhotoScan,
  },
  { value: "style", label: "AlbumSettings.style", icon: IconArtboard },
  { value: "privacy", label: "AlbumSettings.privacy", icon: IconLockSquare },
  {
    value: "slideshow",
    label: "AlbumSettings.slideShowSettings",
    icon: IconPresentation,
  },
  {
    value: "hashtags",
    label: "AlbumSettings.hashtagsSettings",
    icon: IconHash,
  },
];

const AlbumSettingsPage: React.FC = () => {
  const album = useSelector((root: RootState) => root.album.album);
  const t = useTranslations();
  const dispatch = useDispatch();
  const location = useLocation();

  const [updateAlbum, { loading }] = useMutation<
    { updateAlbum: Album },
    { input: UpdateAlbumInput }
  >(updateAlbumMutation);
  useScrollToHash([album]);

  const [modifiedTabs, setModifiedTabs] = useState<Set<string>>(new Set());
  const [activeTab, setActiveTab] = useState<string | null>("general");

  useEffect(() => {
    const hash = location.hash.replace("#", "");
    if (hash && TABS.some((tab) => tab.value === hash)) {
      setActiveTab(hash);
    }
  }, [location]);

  const handleSubmit = (values: UpdateAlbumInput) => {
    updateAlbum({ variables: { input: values } })
      .then((response) => {
        if (response.data?.updateAlbum) {
          dispatch(setAlbum(response.data.updateAlbum));
          ErrorService.showMessage(t("Album.successUpdate"));
          setModifiedTabs(new Set());
        }
      })
      .catch(() => {
        ErrorService.showError(t("Album.errorOnAlbumUpdate"));
      });
  };

  const handleFieldChange =
    (
      handleChange: (e: React.ChangeEvent<any>) => void,
      setFieldTouched: (
        field: string,
        isTouched?: boolean,
        shouldValidate?: boolean
      ) => void,
      tab: string,
      setFieldValue?: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void
    ) =>
    (e: React.ChangeEvent<any> | any, value?: any) => {
      if (e && e.target) {
        handleChange(e);
        setFieldTouched(e.target.name, true, false);
      } else if (setFieldValue) {
        setFieldValue(e, value);
        setFieldTouched(e, true, false);
      }

      setModifiedTabs((prev) => new Set(prev).add(tab));
    };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(200, t("Validation.tooLong"))
      .required(t("Validation.required")),
    password: Yup.string().nullable(),
    color: Yup.string().required(t("Validation.required")),
    description: Yup.string().max(2000, t("Validation.tooLong")),
    public: Yup.boolean().required(t("Validation.required")),
    type: Yup.string()
      .oneOf(Object.values(EventType), t("Validation.invalidType"))
      .required(t("Validation.required")),
    layout: Yup.string()
      .oneOf(Object.values(Layout), t("Validation.invalidType"))
      .required(t("Validation.required")),
    requireApproval: Yup.boolean(),
    eventDate: Yup.date().nullable(),
    yearsFrom: Yup.date().when("type", {
      is: (value: EventType) => value === EventType.MEMORIAL,
      then: (schema) => schema.required(t("Validation.required")),
    }),
    yearsTo: Yup.date().when("type", {
      is: (value: EventType) => value === EventType.MEMORIAL,
      then: (schema) =>
        schema
          .required(t("Validation.required"))
          .min(
            Yup.ref("yearsFrom"),
            t("Validation.yearsToMustBeAfterYearsFrom")
          ),
    }),
    hashtags: Yup.array()
      .of(
        Yup.object().shape({
          title: Yup.string()
            .max(50, t("Validation.tooLong"))
            .required(t("Validation.required")),
        })
      )
      .optional(),
    videoDuration: Yup.number().required(t("Validation.required")),
    imageDuration: Yup.number().required(t("Validation.required")),
    displayCaption: Yup.boolean().required(t("Validation.required")),
    displayContributor: Yup.boolean().required(t("Validation.required")),
    wallDisplayCode: Yup.boolean().required(t("Validation.required")),
    wallDisplayCaptions: Yup.boolean().required(t("Validation.required")),
    optimizeImages: Yup.boolean().required(t("Validation.required")),
    titleFont: Yup.string()
      .oneOf(Object.values(Font), t("Validation.invalidType"))
      .required(t("Validation.required")),
    descriptionFont: Yup.string()
      .oneOf(Object.values(Font), t("Validation.invalidType"))
      .required(t("Validation.required")),
    dateFont: Yup.string()
      .oneOf(Object.values(DateFont), t("Validation.invalidType"))
      .required(t("Validation.required")),
  });

  const initialValues: UpdateAlbumInput = {
    id: album?.id ?? "",
    name: album?.name ?? "",
    description: album?.description ?? "",
    color: album?.color ?? COLORS["light-gray"],
    bannerColor: album?.bannerColor ?? COLORS["light-beige"],
    public: album?.public ?? true,
    type: album?.type ?? EventType.OTHER,
    password: album?.password ?? undefined,
    requireApproval: album?.requireApproval ?? false,
    yearsFrom: album?.yearsFrom ?? undefined,
    yearsTo: album?.yearsTo ?? undefined,
    eventDate: album?.eventDate ?? undefined,
    layout: album?.layout ?? Layout.DOUBLE,
    videoDuration: album?.videoDuration ?? 8,
    imageDuration: album?.imageDuration ?? 5,
    displayCaption: album?.displayCaption ?? true,
    displayContributor: album?.displayContributor ?? true,
    wallDisplayCode: album?.wallDisplayCode ?? true,
    wallDisplayCaptions: album?.wallDisplayCaptions ?? true,
    hashtags: album?.hashtags?.map((h) => ({ id: h.id, title: h.title })) ?? [],
    allowCreateHashtags: album?.allowCreateHashtags ?? false,
    displayMoreActionsButton: album?.displayMoreActionsButton ?? true,
    displayPhotowallButton: album?.displayPhotowallButton ?? true,
    removeBranding: album?.removeBranding ?? false,
    showLogoOnAlbum: album?.showLogoOnAlbum ?? false,
    optimizeImages: album?.optimizeImages ?? true,
    passwordInInvite: album?.passwordInInvite ?? false,
    titleFont: album?.titleFont ?? Font.Playfair,
    descriptionFont: album?.descriptionFont ?? Font.Inter,
    dateFont: album?.dateFont ?? DateFont.Title,
  };

  return (
    <>
      <Helmet>
        <title>Rompolo - {t("Menu.albumSettings")}</title>
      </Helmet>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange={false}
        enableReinitialize
      >
        {(props: FormikProps<UpdateAlbumInput>) => {
          useConfirmNavigationWithSave(props.dirty, props.submitForm);

          return (
            <div className="flex-1 md:px-6 flex flex-col max-w-[1248px]">
              <div className="md:mb-6 mb-4 md:px-0 px-4">
                <Title size="H4">{t("AlbumSettings.albumSettings")}</Title>
                <Text className="mt-2" color="gray">
                  {t("AlbumSettings.albumSettingsDescription")}
                </Text>
              </div>
              <Tabs
                color="#222"
                classNames={{
                  list: "px-4 md:px-0 flex-nowrap md:justify-center",
                }}
                radius="md"
                value={activeTab}
                onChange={setActiveTab}
              >
                <div className="relative">
                  <div className="sticky md:top-[56px] top-[104px] z-20 bg-light-gray">
                    <ScrollArea offsetScrollbars className="">
                      <Tabs.List grow>
                        {TABS.map((tab) => (
                          <Tabs.Tab
                            key={tab.value}
                            value={tab.value}
                            leftSection={
                              <tab.icon className="stroke-black" size={16} />
                            }
                          >
                            <div className="flex gap-2 items-center">
                              <Text wrap="nowrap">{t(tab.label)}</Text>
                              {modifiedTabs.has(tab.value) && (
                                <Badge color="red" size="xs">
                                  {t("AlbumSettings.modified")}
                                </Badge>
                              )}
                            </div>
                          </Tabs.Tab>
                        ))}
                      </Tabs.List>
                    </ScrollArea>
                  </div>
                  <div>
                    <Tabs.Panel value="general">
                      <GeneralSettings
                        album={album}
                        handleFieldChange={(e, value) =>
                          handleFieldChange(
                            props.handleChange,
                            props.setFieldTouched,
                            "general",
                            props.setFieldValue
                          )(e, value)
                        }
                      />
                    </Tabs.Panel>
                    <Tabs.Panel value="image">
                      <ImageSettings
                        album={album}
                        handleFieldChange={(e, value) =>
                          handleFieldChange(
                            props.handleChange,
                            props.setFieldTouched,
                            "image",
                            props.setFieldValue
                          )(e, value)
                        }
                      />
                    </Tabs.Panel>
                    <Tabs.Panel value="style">
                      <StyleSettings
                        album={album}
                        handleFieldChange={(e, value) =>
                          handleFieldChange(
                            props.handleChange,
                            props.setFieldTouched,
                            "style",
                            props.setFieldValue
                          )(e, value)
                        }
                      />
                    </Tabs.Panel>
                    <Tabs.Panel value="privacy">
                      <PrivacySettings
                        album={album}
                        handleFieldChange={(e, value) =>
                          handleFieldChange(
                            props.handleChange,
                            props.setFieldTouched,
                            "privacy",
                            props.setFieldValue
                          )(e, value)
                        }
                      />
                    </Tabs.Panel>
                    <Tabs.Panel value="slideshow">
                      <SlideShowSettings
                        album={album}
                        handleFieldChange={(e, value) =>
                          handleFieldChange(
                            props.handleChange,
                            props.setFieldTouched,
                            "slideshow",
                            props.setFieldValue
                          )(e, value)
                        }
                      />
                    </Tabs.Panel>
                    <Tabs.Panel value="hashtags">
                      <AlbumHashTagsSettings
                        album={album}
                        handleFieldChange={(e, value) =>
                          handleFieldChange(
                            props.handleChange,
                            props.setFieldTouched,
                            "hashtags",
                            props.setFieldValue
                          )(e, value)
                        }
                      />
                    </Tabs.Panel>
                  </div>
                </div>
              </Tabs>
              <div className="flex justify-end mt-6 md:px-0 px-4">
                <Button
                  loading={loading}
                  onClick={props.submitForm}
                  title={t("Album.save")}
                />
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default AlbumSettingsPage;
