import { useEffect, useState } from "react";
import { Modal, Title } from "@mantine/core";
import { useTranslations } from "use-intl";
import { IconX } from "@tabler/icons-react";
import Text from "./Text";
import TextArea from "./TextArea";
import { Hashtag } from "../types/hashtag.type";
import Button from "./Button";
import { useAuth, useIsSmallScreen } from "../hooks";
import BottomToolbar from "./BottomToolbar";
import Input from "./Input";
import { Role } from "../types/user.type";
import { Album, PublicAlbum } from "../types/album.type";
import { Image } from "../types/image.type";
import HashtagSelector from "./HashtagSelector";

const CaptionEdit = ({
  loading = false,
  opened,
  onClose,
  currentCaption,
  albumHashtags,
  onSave,
  selectedHashtags,
  onlyDesktop = false,
  currentContributor,
  withContributor,
  album,
  image,
}: {
  opened: boolean;
  onClose: () => void;
  currentCaption: string;
  currentContributor?: string;
  withContributor?: boolean;
  albumHashtags: Hashtag[];
  onlyDesktop?: boolean;
  onSave: ({
    caption,
    hashtags,
    contributor,
  }: {
    caption: string;
    hashtags: string[];
    contributor?: string;
  }) => void;
  selectedHashtags: Hashtag[];
  album: Album | PublicAlbum;
  image?: Image;
  loading?: boolean;
}) => {
  const { user } = useAuth();
  const t = useTranslations("Photos");
  const [hashtagsSelected, setHashtagsSelected] = useState<string[]>(
    selectedHashtags.map((h) => h.id)
  );
  const isSmallScreen = useIsSmallScreen();
  const [caption, setCaption] = useState(currentCaption);
  const [contributor, setContributor] = useState(currentContributor ?? "");

  const isAlbumOwnerOrAdmin =
    album?.user?.id === user.id || user.role === Role.ADMIN;

  useEffect(() => {
    const dialog = document.getElementById("file-upload-dialog");
    if (dialog) {
      if (opened) {
        dialog.style.overflow = "hidden";
      } else {
        dialog.style.overflow = "auto";
      }
    }
  }, [opened]);

  useEffect(() => {
    return () => {
      const dialog = document.getElementById("file-upload-dialog");
      if (dialog) {
        dialog.style.overflow = "auto";
      }
    };
  }, []);

  useEffect(() => {
    setCaption(currentCaption);
  }, [currentCaption]);

  useEffect(() => {
    setContributor(currentContributor ?? "");
  }, [currentContributor]);

  useEffect(() => {
    setHashtagsSelected(selectedHashtags.map((h) => h.id));
  }, [selectedHashtags]);

  const handleClose = () => {
    onClose();
    setCaption(currentCaption);
    setContributor(currentContributor ?? "");
    setHashtagsSelected([]);
  };

  const handleHashtagSelect = (id?: string) => {
    if (!id) {
      return;
    }

    // If user is not uploader, if user is not admin or owner, if user is not guest user uploaded image, then do nothing
    if (user.id !== image?.userId) {
      if (!isAlbumOwnerOrAdmin) {
        if (image && image?.guestUserId !== user.id) {
          //If new image is uploaded by guest user, image will be undefined
          return;
        }
      }
    }

    setHashtagsSelected((prev) => {
      if (prev.some((ht) => ht === id)) {
        return prev.filter((ht) => ht !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const captionBody = (
    <>
      <div>
        <div className="flex justify-between mb-4">
          <Title size="H3" className="font-semibold">
            {t("addCaptionOnly")}
          </Title>
          <IconX
            onClick={handleClose}
            width={24}
            height={24}
            className="stroke-medium-black cursor-pointer"
          />
        </div>
        <div>
          <Text size="sm" color="gray">
            {t("caption")}:
          </Text>
          <TextArea
            value={caption}
            className="mt-2"
            placeholder={t("addHere")}
            onChange={(e) => setCaption(e.target.value)}
          />
        </div>
        {withContributor && (
          <div className="mt-6">
            <Text size="sm" color="gray">
              {t("contributor")}:
            </Text>
            <Input
              value={contributor}
              className="mt-2"
              placeholder={t("addHere")}
              onChange={(e) => setContributor(e.target.value)}
            />
          </div>
        )}
        <div>
          {albumHashtags && albumHashtags.length > 0 && (
            <div className="mt-6">
              <Text size="sm" color="gray">
                {t("selectHashtags")}:
              </Text>
              <div className="mt-2 flex gap-4 flex-wrap max-w-[calc(100vw-32px)]">
                <HashtagSelector
                  withStroke
                  album={album}
                  onClick={handleHashtagSelect}
                  selectedHashtags={hashtagsSelected}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className=" pt-10 flex gap-4">
        <Button
          fullWidth
          type="outline"
          onClick={handleClose}
          title={t("cancel")}
        />
        <Button
          loading={loading}
          fullWidth
          onClick={() => {
            onSave({ caption, hashtags: hashtagsSelected, contributor });
          }}
          title={t("saveChanges")}
        />
      </div>
    </>
  );

  if (isSmallScreen && !onlyDesktop) {
    return (
      <BottomToolbar opened={opened} onClose={onClose}>
        {captionBody}
      </BottomToolbar>
    );
  } else if (!isSmallScreen) {
    return (
      <Modal
        radius={8}
        padding={40}
        size={600}
        onClose={onClose}
        centered
        opened={opened}
        withCloseButton={false}
      >
        {captionBody}
      </Modal>
    );
  } else {
    return null;
  }
};

export default CaptionEdit;
