import { Loader, Tooltip } from "@mantine/core";

export interface IconProps {
  icon: React.ElementType;
  onClick: () => void;
  className: string;
  width: number;
  height: number;
  tooltip?: string;
  loading?: boolean;
}

interface IconsProps {
  icons: IconProps[];
  loaderColor?: "white" | "black";
}

const ActionIcons: React.FC<IconsProps> = ({
  icons,
  loaderColor = "black",
}) => {
  return (
    <>
      {icons.map((icon, index) => (
        <Tooltip key={index} label={icon.tooltip} disabled={!icon.tooltip}>
          <div
            onClick={icon.onClick}
            className={`p-2 rounded-sm ${icon.className} cursor-pointer flex`}
          >
            {icon.loading ? (
              <Loader
                size={icon.width}
                color={loaderColor === "white" ? "#fff" : "#222"}
              />
            ) : (
              <icon.icon
                className={icon.className}
                width={icon.width}
                height={icon.height}
              />
            )}
          </div>
        </Tooltip>
      ))}
    </>
  );
};

export default ActionIcons;
