export enum Font {
  Inter = "inter",
  Poppins = "poppins",
  Playfair = "playfair",
  Aboreto = "aboreto",
  Afacad = "afacad",
  Hedvig = "hedvig",
  Julius = "julius",
  AlexBrush = "alexbrush",
  Allura = "allura",
  Bebas = "bebas",
  Chonburi = "chonburi",
  OpenSans = "opensans",
  Raleway = "raleway",
}

export enum DateFont {
  Title = "title",
  Description = "description",
}

// Map Font enum to actual CSS font-family values
export const fontFamilyMap: Record<Font, string> = {
  [Font.Inter]: "'Inter', sans-serif",
  [Font.Poppins]: "'Poppins', sans-serif",
  [Font.Playfair]: "'Playfair', serif",
  [Font.Aboreto]: "'Aboreto', cursive",
  [Font.Afacad]: "'Afacad', sans-serif",
  [Font.Hedvig]: "'Hedvig Letters Serif', serif",
  [Font.Julius]: "'Julius Sans One', sans-serif",
  [Font.AlexBrush]: "'Alex Brush', cursive",
  [Font.Allura]: "'Allura', cursive",
  [Font.Bebas]: "'Bebas Neue', sans-serif",
  [Font.Chonburi]: "'Chonburi', cursive",
  [Font.OpenSans]: "'Open Sans', sans-serif",
  [Font.Raleway]: "'Raleway', sans-serif",
};
