import { useSelector } from "react-redux";
import moment from "moment";
import { useTranslations } from "use-intl";
import { RootState } from "../store";
import { DownloadAll, RoundedBox, Text } from ".";

const AlbumWillExpire = ({
  withDownload = true,
}: {
  withDownload?: boolean;
}) => {
  const album = useSelector((rootState: RootState) => rootState.album.album);
  const t = useTranslations("Album");

  if (!album?.cleanupDate && album?.expirationDate) {
    // Parse the expiration date using moment
    const expirationDate = moment(album.expirationDate).startOf("day");
    const daysLeft = expirationDate.diff(moment().startOf("day"), "days");
    const isToday = moment().isSame(expirationDate, "day");
    // Check if the expiration date is within the next 3 days
    if (expirationDate.isBefore(moment().add(3, "days"))) {
      return (
        <RoundedBox
          data-testid={"album-will-expire"}
          color="red"
          className="mb-6 flex lg:flex-row flex-col lg:gap-20 gap-4 justify-between lg:items-center"
        >
          <div>
            <Text className="font-semibold">
              {isToday
                ? t("uploadsWillBeDeletedToday")
                : t.rich("uploadsWillBeDeleted", {
                    days: () => daysLeft,
                  })}
            </Text>
            <Text className="mt-2">
              {isToday
                ? t.rich("planIsExpiringToday", {
                    bold: (chunk) => <strong>{chunk}</strong>,
                    date: () => (
                      <strong>{expirationDate.format("DD-MM-YYYY")}</strong>
                    ),
                  })
                : t.rich("planIsExpiring", {
                    days: () => <strong>{daysLeft}</strong>,
                    date: () => (
                      <strong>{expirationDate.format("DD-MM-YYYY")}</strong>
                    ),
                  })}
            </Text>
          </div>
          {withDownload && (
            <div className="flex justify-end">
              <DownloadAll
                album={album}
                title={t("downloadAll")}
                type="button"
              />
            </div>
          )}
        </RoundedBox>
      );
    }
  }

  return <></>;
};

export default AlbumWillExpire;
