import React, { useCallback, useEffect, useRef, useState } from "react";
import { Play } from "..";
import { IconVolume, IconVolume3 } from "@tabler/icons-react";
import { useIsInIframe, useIsInPhotoWall, useIsSmallScreen } from "../../hooks";
import { debounce } from "lodash";

interface VideoComponentProps {
  videoUrl: string;
  thumbnailUrl: string;
  isVisible?: boolean;
  className?: string;
  withPlayIcon?: boolean;
  withSoundIcon?: boolean;
  withControls?: boolean;
}

const VideoComponent: React.FC<VideoComponentProps> = ({
  videoUrl,
  thumbnailUrl,
  isVisible = false,
  className,
  withPlayIcon = true,
  withSoundIcon = true,
  withControls = true,
}) => {
  const [thumbnailVisible, setThumbnailVisible] = useState(true);
  const [mute, setMute] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const isInIframe = useIsInIframe();
  const isInPhotoWall = useIsInPhotoWall();
  const isSmallScreen = useIsSmallScreen();

  const videoRef = useRef<HTMLVideoElement>(null);
  const playPromiseRef = useRef<Promise<void> | null>(null);

  const handleVideoPlay = useCallback(() => {
    setIsPlaying(true);
  }, []);

  const handleVideoPause = useCallback(() => {
    setIsPlaying(false);
  }, []);

  const handleVideoEnd = useCallback(() => {
    setThumbnailVisible(true);
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("play", handleVideoPlay);
      videoElement.addEventListener("pause", handleVideoPause);
      videoElement.addEventListener("ended", handleVideoEnd);

      return () => {
        videoElement.removeEventListener("play", handleVideoPlay);
        videoElement.removeEventListener("pause", handleVideoPause);
        videoElement.removeEventListener("ended", handleVideoEnd);
      };
    }
  }, [handleVideoPlay, handleVideoPause, handleVideoEnd]);

  const handleVideoClick = useCallback(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (videoElement.paused) {
        playVideo();
      } else {
        pauseVideo();
      }
    }
  }, []);

  const handleMuteClick = useCallback(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.muted = !videoElement.muted;
      setMute(videoElement.muted);
    }
  }, []);

  const playVideo = useCallback(() => {
    const videoElement = videoRef.current;
    if (videoElement && !playPromiseRef.current) {
      playPromiseRef.current = videoElement.play();
      playPromiseRef.current
        .then(() => {
          setThumbnailVisible(false);
          playPromiseRef.current = null;
        })
        .catch((error) => {
          if (error.name !== "AbortError") {
            console.error("Error playing video:", error);
          }
          playPromiseRef.current = null;
        });
    }
  }, []);

  const pauseVideo = useCallback(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      if (playPromiseRef.current) {
        playPromiseRef.current
          .then(() => {
            videoElement.pause();
            playPromiseRef.current = null;
          })
          .catch(() => {
            playPromiseRef.current = null;
          });
      } else {
        videoElement.pause();
      }
    }
  }, []);

  useEffect(() => {
    const handleVisibilityChange = debounce(() => {
      if (isVisible) {
        playVideo();
      } else {
        pauseVideo();
      }
    }, 300);

    handleVisibilityChange();

    return () => {
      handleVisibilityChange.cancel();
    };
  }, [isVisible, playVideo, pauseVideo]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement.pause();
        videoElement.removeAttribute("src");
        videoElement.load(); //we ensure that the browser releases any resources it was holding for video playback.
      }
      if (playPromiseRef.current) {
        playPromiseRef.current
          .then(() => {
            videoRef.current?.pause();
          })
          .catch(() => {});
      }
    };
  }, []);

  return (
    <div className="relative w-full h-full flex items-center justify-center">
      <video
        style={{ visibility: thumbnailVisible ? "hidden" : "visible" }}
        controls={
          !isInIframe && !isInPhotoWall && !isSmallScreen && withControls
        }
        playsInline
        ref={videoRef}
        onClick={handleVideoClick}
        muted={mute}
        loop
        className={`max-w-full max-h-full ${className || ""} mx-auto`}
        preload="auto"
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
      {!isInIframe && !isInPhotoWall && withSoundIcon && (
        <div
          className="z-20 absolute top-6 right-6 bg-light-black/60 rounded-full p-2 cursor-pointer"
          onClick={handleMuteClick}
        >
          {mute ? (
            <IconVolume3 width={24} height={24} className="stroke-white" />
          ) : (
            <IconVolume width={24} height={24} className="stroke-white" />
          )}
        </div>
      )}

      {!isPlaying && isSmallScreen && withPlayIcon && (
        <Play onClick={playVideo} className="z-30" />
      )}
      {thumbnailVisible && (
        <div className="absolute inset-0 flex items-center justify-center">
          <img
            className={`max-w-full max-h-full object-contain ${
              className || ""
            }`}
            src={thumbnailUrl}
            alt="Video thumbnail"
            style={{
              cursor: "pointer",
            }}
            onClick={playVideo}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(VideoComponent);
