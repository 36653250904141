import { Tabs } from "@mantine/core";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { IconUser } from "@tabler/icons-react";
import { UsersTable } from "./components";
import { Helmet } from "react-helmet-async";

const AdminUsersPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const defaultTab = searchParams.get("tab") || "users";

  const clearQueryParams = () => {
    navigate(location.pathname, { replace: true });
  };

  return (
    <div className="flex-1 md:px-6 px-4 flex flex-col">
      <Helmet>
        <title>Rompolo - Users</title>
      </Helmet>
      <Tabs
        keepMounted={false}
        defaultValue={defaultTab}
        color="#222"
        radius="md"
        onChange={() => {
          clearQueryParams();
        }}
      >
        <Tabs.List>
          <Tabs.Tab
            leftSection={<IconUser className="stroke-black" size={16} />}
            value={"users"}
          >
            Users
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="users">
          <UsersTable />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default AdminUsersPage;
