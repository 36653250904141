import React from "react";
import { Checkbox, Modal } from "@mantine/core";
import { useMutation } from "@apollo/client";
import { ErrorService } from "../../../services";
import { markPaymentAsProcessedQuery } from "../../../graphql/paymentsQueries";
import {
  MarkPaymentAsProcessedInput,
  Payment,
} from "../../../types/payment.type";
import { Text, Title } from "../../../common";
import { IconX } from "@tabler/icons-react";
import moment from "moment";

interface BadgePaymentDetailsProps {
  payment: Payment | null;
  opened: boolean;
  close: () => void;
  onUpdated: () => void;
}

const BadgePaymentDetails: React.FC<BadgePaymentDetailsProps> = ({
  payment,
  opened,
  close,
  onUpdated,
}) => {
  const [markPaymentAsProcessed, { loading: updating }] = useMutation<
    { markPaymentAsProcessed: boolean },
    { input: MarkPaymentAsProcessedInput }
  >(markPaymentAsProcessedQuery);

  const process = (processed: boolean) => {
    if (!payment) return;

    markPaymentAsProcessed({
      variables: { input: { processed, paymentId: payment.id } },
    })
      .then((response) => {
        const updatedPayment = response.data?.markPaymentAsProcessed;

        if (updatedPayment) {
          onUpdated();
          close();
          ErrorService.showMessage("✅ Payment updated successfully");
        } else {
          ErrorService.showError("Error updating payment");
        }
      })
      .catch(() => {
        ErrorService.showError("Error updating payment");
      });
  };

  return (
    <Modal
      radius={8}
      padding={40}
      size={600}
      onClose={close}
      centered
      opened={opened}
      withCloseButton={false}
    >
      {payment ? (
        <div>
          <div className="flex justify-between mb-6">
            <Title size="H5">Payment details</Title>
            <IconX
              onClick={close}
              size={24}
              className=" cursor-pointer stroke-medium-black"
            />
          </div>
          <div className="space-y-4">
            <Text>
              <b>Payment ID:</b> {payment.paymentId}
            </Text>
            <Text>
              <b>Amount:</b> {payment.amount / 100}
            </Text>
            <Text>
              <b>Customer:</b> {payment.customer}
            </Text>
            <Text>
              <b>Purchased at:</b>{" "}
              {moment(payment.createdAt).format("YYYY-MM-DD LT")}
            </Text>
            <Text>
              <b>Album name:</b> {payment.album?.name ?? "-"}
            </Text>
            <Text>
              <b>Album type:</b> {payment.album?.type ?? "-"}
            </Text>
            <Text>
              <b>Plan:</b> {payment.album?.plan ?? "-"}
            </Text>
            <Text>
              <b>Shipping address:</b> {payment.shippingAddress ?? "-"}
            </Text>
            <div>
              <Checkbox
                className="mt-6"
                disabled={updating}
                checked={payment.processed}
                onChange={(event) => process(event.currentTarget.checked)}
                label="Processed"
              />
            </div>
          </div>
        </div>
      ) : (
        <p>No payment selected</p>
      )}
    </Modal>
  );
};

export default BadgePaymentDetails;
