import { useLazyQuery, useQuery } from "@apollo/client";
import { AlbumSizes, PublicAlbum } from "../../types/album.type";
import { findAlbumByShortIdQuery } from "../../graphql/albumQueries";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { IEdgeType, PaginatedType } from "../../types/paginated.type";
import { Image, ImageSearchProps } from "../../types/image.type";
import { apolloClient } from "../../lib/apolloClient";
import { albumImagesQuery, findAlbumSizes } from "../../graphql/imageQueries";
import { ErrorService } from "../../services";
import { useTranslations } from "use-intl";
import {
  HashtagSelector,
  ImageGrid,
  Loading,
  RompoloMessage,
  SortBy,
  Text,
} from "../../common";
import { AlbumFooter, AlbumHeader, RequireApprovalModal } from "./components";
import { useAuth, useSearchParamsState } from "../../hooks";
import { useDisclosure } from "@mantine/hooks";
import {
  getImageUrl,
  isForbiddenError,
  isNotFoundError,
  isUnauthenticatedError,
} from "../../lib/utils";
import { Helmet } from "react-helmet-async";
import _ from "lodash";
import TechnicalIssueModalAlert from "../../common/TechnicalIssueModalAlert";

const AlbumPage = () => {
  const t = useTranslations("Photos");
  const { user } = useAuth();
  // Get shortId from url
  const { shortId } = useParams<{ shortId: string }>();
  const [images, setImages] = useState<IEdgeType<Image>[]>([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [error, setError] = useState();
  const lastCursor = useRef<string | null>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const loadCount = 20;
  const [filteredImages, setFilteredImages] = useState<IEdgeType<Image>[]>([]);
  const [isStorageLimitReached, setIsStorageLimitReached] = useState(false);
  const [queryParams, setQueryParams] = useSearchParamsState<{
    selectedHashtags: string[];
    orderBy: "createdAt" | "exifCreatedAt";
  }>({
    selectedHashtags: [],
    orderBy: "createdAt",
  });
  const navigate = useNavigate();

  //Find album sizes
  const [loadAlbumSize, { data: albumSizes }] = useLazyQuery<
    { findAlbumSize: AlbumSizes },
    { albumId: string }
  >(findAlbumSizes, {
    context: {
      headers: {
        ...(user?.isGuest && { guestuserid: user?.id }),
      },
    },
  });

  //Load album
  const {
    loading: albumLoading,
    error: albumError,
    data: { album } = {},
  } = useQuery<{ album: PublicAlbum }, { shortId: string }>(
    findAlbumByShortIdQuery,
    {
      variables: {
        shortId: shortId ?? "",
      },
      skip: !shortId,
    }
  );

  const albumIsExpired = album?.expirationDate
    ? new Date(album?.expirationDate) < new Date()
    : false;

  //If album is expired, navigate to expired page
  useEffect(() => {
    if (albumIsExpired) navigate("/album-not-found");
  }, [albumIsExpired]);

  useEffect(() => {
    //If forbidden and is password protected
    if (error && isForbiddenError(error) && album?.passwordProtected) {
      navigate(`/album/password-protected/${album?.shortId}`);
    } else if (error && isUnauthenticatedError(error)) {
      //If unauthenticated, navigate to signin, when user is not logged in
      navigate(`/signin`);
    } else if (error) ErrorService.showError(t("errorFetching"));
  }, [error]);

  useEffect(() => {
    //If album not found navigate to not found page
    if (albumError && isNotFoundError(albumError)) {
      navigate("/album-not-found");
    }
    if (albumError) ErrorService.showError(t("errorLoadingAlbum"));
  }, [albumError]);

  // Refresh images when selected hashtags change or order by changes
  useEffect(() => {
    loadInitialData();
  }, [queryParams.selectedHashtags, queryParams.orderBy]);

  const loadInitialData = useCallback(async () => {
    if (album) {
      console.log("load initial");

      return apolloClient
        .query<{ albumImages: PaginatedType<Image> }, ImageSearchProps>({
          query: albumImagesQuery,
          variables: {
            orderBy: queryParams.orderBy,
            albumId: album.id,
            first: loadCount,
            published: true,
            approved: true,
            hashtags: queryParams.selectedHashtags,
          },
          fetchPolicy: "no-cache",
          context: {
            headers: {
              ...(user?.isGuest && { guestuserid: user?.id }),
            },
          },
        })
        .then((resp) => {
          if (resp.data?.albumImages) {
            setImages(resp.data?.albumImages.edges);
            setHasNextPage(resp.data.albumImages.hasNextPage);
            setTotalCount(resp.data.albumImages.totalCount);
            return resp.data.albumImages.totalCount;
          } else {
            return undefined;
          }
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
          setInitialLoadDone(true);
        });
    }
  }, [album, queryParams.selectedHashtags, queryParams.orderBy, user]);

  const loadMore = useCallback(
    (cursor: string) => {
      if (album) {
        if (!hasNextPage || lastCursor.current === cursor || loading) return;
        console.log("loadmore");

        apolloClient
          .query<{ albumImages: PaginatedType<Image> }, ImageSearchProps>({
            query: albumImagesQuery,
            variables: {
              orderBy: queryParams.orderBy,
              after: cursor,
              albumId: album.id,
              first: loadCount,
              published: true,
              approved: true,
              hashtags: queryParams.selectedHashtags,
            },
            fetchPolicy: "no-cache",
            context: {
              headers: {
                ...(user?.isGuest && { guestuserid: user?.id }),
              },
            },
          })
          .then((resp) => {
            if (resp.data?.albumImages) {
              lastCursor.current = cursor;
              //Merge new images with old images
              setImages((img) => {
                const concatenatedArray = [
                  ...img,
                  ...resp.data.albumImages.edges,
                ];
                const mergedArray = _.uniqBy(concatenatedArray, "cursor"); //Ensuring that we do not have duplicates
                return mergedArray;
              });
              setHasNextPage(resp.data.albumImages.hasNextPage);
              setTotalCount(resp.data.albumImages.totalCount);
            }
          })
          .catch((err) => {
            setError(err);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [
      album,
      hasNextPage,
      queryParams.selectedHashtags,
      queryParams.orderBy,
      loading,
    ]
  );

  const onRemoved = useCallback(
    (ids: string | string[]) => {
      // Ensure ids is always an array
      const idsArray = Array.isArray(ids) ? ids : [ids];

      // Filter out images with ids present in idsArray
      const updatedImages = images.filter(
        (image) => !idsArray.includes(image.cursor)
      );

      //Update total count after removal
      setTotalCount((count) => count - idsArray.length);

      ErrorService.showMessage(t("imageRemoved")); // Update message as needed
      setImages(updatedImages);
      refreshAlbumSizes();

      // Load inital data when we delete all visible images from album, but there is still images on server
      if (images.length === idsArray.length && totalCount > images.length) {
        loadInitialData();
      }
    },
    [images, totalCount]
  );

  const onUpdated = useCallback(
    (updatedImages: Image | Image[]) => {
      // Ensure updatedImages is always an array
      const updatedImagesArray = Array.isArray(updatedImages)
        ? updatedImages
        : [updatedImages];

      // Create a new array by merging the updated images into the existing images
      const newImages = images.map((imageEdge) => {
        const updatedImage = updatedImagesArray.find(
          (img) => img.id === imageEdge.cursor
        );
        return updatedImage
          ? { cursor: imageEdge.cursor, node: updatedImage }
          : imageEdge;
      });

      setImages(newImages);
    },
    [images]
  );

  const onUploadComplete = useCallback(() => {
    if (album?.requireApproval && user.id !== album.user.id) {
      open(); // Open the approval modal
    }
    refreshAlbumSizes();
  }, [album, images]);

  const onHashtagSelected = useCallback(
    (hashtag?: string) => {
      const { selectedHashtags, ...params } = queryParams;

      if (!hashtag) {
        setQueryParams({ ...params, selectedHashtags: [] });
        return;
      }

      if (selectedHashtags.includes(hashtag)) {
        // If the clicked hashtag is already selected, deselect it
        setQueryParams({
          ...params,
          selectedHashtags: [],
        });
      } else {
        // If a different hashtag is clicked, select only that one
        setQueryParams({
          ...params,
          selectedHashtags: [hashtag],
        });
      }
    },
    [album, queryParams.selectedHashtags]
  );

  //Load the images
  useEffect(() => {
    if (album && !loading) {
      setLoading(true);
      loadInitialData();
      refreshAlbumSizes();
    }
  }, [album]);

  //Fetch current album size to check if limit reached
  const refreshAlbumSizes = () => {
    album &&
      loadAlbumSize({
        variables: { albumId: album.id },
        fetchPolicy: "no-cache",
      });
  };

  useEffect(() => {
    if (albumSizes && albumSizes.findAlbumSize) {
      const { currentSize, maxSize } = albumSizes.findAlbumSize; //TODO: implement same as in photos and videos page
      setIsStorageLimitReached(currentSize >= maxSize);
    }
  }, [albumSizes]);

  useEffect(() => {
    // Filter images based on the consolidated filters
    const filtered = images.filter(({ node }) => {
      const publishedFilterCheck = node.published === true;
      const approvedFilterCheck = node.approved === true;
      return publishedFilterCheck && approvedFilterCheck;
    });

    setFilteredImages(filtered);
  }, [images]);

  //Set body background color
  useEffect(() => {
    if (album) {
      if (album.color) {
        document.body.style.backgroundColor = album.color;
      }
      return () => {
        //remove body background color
        document.body.style.backgroundColor = "";
      };
    }
  }, [album]);

  if (albumLoading)
    return (
      <div className="absolute flex justify-center items-center w-screen h-screen">
        <Loading />
      </div>
    );

  // When defined, tooltip will be shown on file upload button
  const fileUploadTooltip = albumIsExpired
    ? t("albumExpiredTooltip")
    : isStorageLimitReached
    ? t("storageLimitTooltip")
    : undefined;

  const albumHasHashtags = album?.hashtags && album?.hashtags?.length > 0;

  return (
    <div
      id="main-container"
      className="flex-1 flex flex-col relative min-h-screen min-w-screen"
    >
      <TechnicalIssueModalAlert />
      {/* Add page metadata */}
      <Helmet>
        <title>Rompolo - {album?.name ?? "album"}</title>
        <meta property="og:title" content={album?.name ?? "Rompolo album"} />
        {album?.description && (
          <meta property="og:description" content={album?.description} />
        )}
        {album?.profileImage && (
          <meta property="og:image" content={getImageUrl(album.profileImage)} />
        )}
        {/* Dynamically set the URL */}
        <meta
          property="og:url"
          content={typeof window !== "undefined" ? window.location.href : ""}
        />
        <meta property="og:type" content="website" />
      </Helmet>

      {/* Show modal when user uploads to album which requires approval */}
      <RequireApprovalModal close={close} show={opened} />
      <div className="absolute right-0 left-0 min-h-screen flex flex-1 flex-col justify-between">
        <div className="flex-1 flex flex-col">
          {album && (
            <AlbumHeader
              fileUploadTooltip={fileUploadTooltip}
              loadData={loadInitialData}
              hasImages={images.length > 0}
              disabled={
                isStorageLimitReached || albumIsExpired || album.user.banned
              }
              album={album}
              onUploadComplete={onUploadComplete}
            />
          )}

          <div>
            <div className="max-w-[1100px] p-4 pt-0 md:mb-10 mx-auto flex flex-col">
              <div className="flex w-full md:items-center items-end justify-between md:flex-row flex-col">
                <div className="w-full md:w-auto flex-1 overflow-hidden">
                  {albumHasHashtags && (
                    <HashtagSelector
                      wrap={false}
                      withSeeAll
                      className="justify-start mt-6 md:mt-10"
                      disabled={
                        queryParams.selectedHashtags.length === 0 &&
                        images.length === 0
                      }
                      album={album}
                      onClick={onHashtagSelected}
                      selectedHashtags={queryParams.selectedHashtags}
                    />
                  )}
                </div>
                <div className="ml-6 flex-shrink-0">
                  {album?.createdAt && (
                    <SortBy
                      albumCreatedAt={new Date(album.createdAt)}
                      onChange={(orderBy) => {
                        setQueryParams({ orderBy });
                      }}
                      orderBy={queryParams.orderBy}
                      className={albumHasHashtags ? "mt-4 md:mt-7" : "mt-6"}
                    />
                  )}
                </div>
              </div>
              <div className={` ${albumHasHashtags ? "mt-2" : "mt-4"}`}>
                {album &&
                  initialLoadDone &&
                  !loading &&
                  filteredImages.length === 0 && (
                    <div className="flex flex-1 items-center p-4 py-16">
                      <RompoloMessage>
                        <Text>{t("emptyAlbum")}</Text>
                      </RompoloMessage>
                    </div>
                  )}
                {album && images.length > 0 && (
                  <ImageGrid
                    album={album}
                    albumMode
                    withContributors={album.displayContributor}
                    withCaptions={album.displayCaption}
                    loading={loading}
                    hasNextPage={hasNextPage}
                    loadMore={loadMore}
                    items={filteredImages}
                    onRemoved={onRemoved}
                    onUpdated={onUpdated}
                    count={totalCount}
                  />
                )}
                {loading && (
                  <div className="py-10 w-full flex items-center justify-center">
                    <Loading />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {!album?.removeBranding && <AlbumFooter />}
      </div>
    </div>
  );
};

export default AlbumPage;
