import { useMutation } from "@apollo/client";
import {
  removeGuestImageMutation,
  removeImageMutation,
  updateGuestImageMutation,
  updateImageMutation,
} from "../graphql/imageQueries";
import { Image, UpdateImageInput } from "../types/image.type";
import { useAuth } from ".";
import { useState } from "react";

// Define a common response type for both mutations
type UpdateImageResponseCommon = {
  updateImage?: Image;
  updateGuestImage?: Image;
};

type RemoveImageResponseCommon = {
  removeImage?: Boolean;
  removeGuestImage?: Boolean;
};

const useImageActions = (albumId?: string, onCompleted?: () => void) => {
  const { user } = useAuth();
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [loadingUnPublish, setLoadingUnPublish] = useState(false);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [removeImage] = useMutation<
    RemoveImageResponseCommon,
    {
      id: string;
      guestUserId?: string;
    }
  >(user?.isGuest ? removeGuestImageMutation : removeImageMutation, {
    context: {
      headers: {
        ...(user?.isGuest && { guestuserid: user.id }),
        ...(albumId && { albumId }),
      },
    },
  });

  const [updateImage] = useMutation<
    UpdateImageResponseCommon,
    {
      input: UpdateImageInput;
    }
  >(user?.isGuest ? updateGuestImageMutation : updateImageMutation, {
    fetchPolicy: "network-only",
    context: {
      headers: {
        ...(user?.isGuest && { guestuserid: user.id }),
        ...(albumId && { albumId }),
      },
    },
  });

  // Normalize the response for removeImage
  const normalizeRemoveResponse = (response: RemoveImageResponseCommon) => {
    return "removeImage" in response
      ? response.removeImage
      : response.removeGuestImage;
  };

  // Normalize the response for updateImage
  const normalizeUpdateResponse = (response: UpdateImageResponseCommon) => {
    return "updateImage" in response
      ? response.updateImage
      : response.updateGuestImage;
  };

  const remove = (id: string) => {
    setLoadingRemove(true);
    return removeImage({ variables: { id } })
      .then((res) => {
        if (res.data) {
          return normalizeRemoveResponse(res.data);
        } else {
          throw new Error("No data received from removeImage mutation");
        }
      })
      .finally(() => {
        onCompleted && onCompleted();
        setLoadingRemove(false);
      });
  };

  const unPublish = (id: string) => {
    setLoadingUnPublish(true);

    return updateImage({
      variables: {
        input: {
          published: false,
          approved: true,
          id,
        },
      },
    })
      .then((res) => {
        if (res.data) {
          return normalizeUpdateResponse(res.data);
        } else {
          throw new Error("No data received from updateImage mutation");
        }
      })
      .finally(() => {
        onCompleted && onCompleted();
        setLoadingUnPublish(false);
      });
  };

  const publish = (id: string) => {
    setLoadingPublish(true);

    return updateImage({
      variables: {
        input: {
          published: true,
          approved: true,
          id,
        },
      },
    })
      .then((res) => {
        if (res.data) {
          return normalizeUpdateResponse(res.data);
        } else {
          throw new Error("No data received from updateImage mutation");
        }
      })
      .finally(() => {
        onCompleted && onCompleted();
        setLoadingPublish(false);
      });
  };

  const approve = (id: string) => {
    setLoadingApprove(true);

    return updateImage({
      variables: {
        input: {
          approved: true,
          id,
        },
      },
    })
      .then((res) => {
        if (res.data) {
          return normalizeUpdateResponse(res.data);
        } else {
          throw new Error("No data received from updateImage mutation");
        }
      })
      .finally(() => {
        onCompleted && onCompleted();
        setLoadingApprove(false);
      });
  };

  const update = (id: string, imageUpdate: Partial<Image>) => {
    setLoadingUpdate(true);

    return updateImage({
      variables: {
        input: {
          id,
          ...imageUpdate,
        },
      },
    })
      .then((res) => {
        if (res.data) {
          return normalizeUpdateResponse(res.data);
        } else {
          throw new Error("No data received from updateImage mutation");
        }
      })
      .finally(() => {
        onCompleted && onCompleted();
        setLoadingUpdate(false);
      });
  };

  return {
    remove,
    unPublish,
    publish,
    approve,
    update,
    loadingRemove,
    loadingUnPublish,
    loadingPublish,
    loadingApprove,
    loadingUpdate,
  };
};

export default useImageActions;
