import { useQuery } from "@apollo/client";
import { usersQuery } from "../../../graphql/userQueries";
import { useSearchParamsState } from "../../../hooks";
import { OperationType } from "../../../types/operation-type.enum";
import { PaginatedType } from "../../../types/paginated.type";
import { User } from "../../../types/user.type";
import { Button, Input, ReactTable } from "../../../common";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Loader, Pagination } from "@mantine/core";
import { usersColumns } from "../../../columns/UserColumns";
import { ErrorService } from "../../../services";
import { IconPlus } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import CreateUserModal from "./CreateUserModal";
import UpdateUserModal from "./UpdateUserModal";
import { parseBooleanOrUndefined } from "../../../lib/utils";
import debounce from "lodash/debounce";

const UsersTable = () => {
  const [queryParams, setQueryParams] = useSearchParamsState<{
    search?: string;
    page: number;
    limit: number;
    email?: string;
    fullName?: string;
    banned?: boolean;
    onboarded?: boolean;
    verified?: boolean;
  }>({
    search: "",
    page: 1,
    limit: 12,
    email: undefined,
    fullName: undefined,
    banned: undefined,
    onboarded: undefined,
    verified: undefined,
  });
  const [
    createUserModalOpened,
    { open: openCreateUserModal, close: closeCreateUserModal },
  ] = useDisclosure(false);
  const [
    updateModalOpened,
    { open: openUpdateModal, close: closeUpdateModal },
  ] = useDisclosure(false);

  const [searchValue, setSearchValue] = useState(queryParams.search);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  // Set search value to queryParams.search if it is different from searchValue
  useEffect(() => {
    if (queryParams.search !== searchValue) {
      setSearchValue(queryParams.search);
    }
  }, [queryParams.search]);

  useEffect(() => {
    if (selectedUser) {
      openUpdateModal();
    }
  }, [selectedUser]);

  const { loading, error, data, refetch } = useQuery<
    { users: PaginatedType<User & { albumCount: number }> },
    {
      search?: string;
      first?: number;
      offset?: number;
      fullName?: string;
      email?: string;
      banned?: boolean;
      operationType?: OperationType;
      onboarded?: boolean;
      verified?: boolean;
    }
  >(usersQuery, {
    variables: {
      first: queryParams.limit,
      offset: (queryParams.page - 1) * queryParams.limit,
      ...(queryParams.search && {
        search: String(queryParams.search),
      }),
      email: queryParams.email,
      fullName: queryParams.fullName,
      banned: parseBooleanOrUndefined(queryParams.banned),
      onboarded: parseBooleanOrUndefined(queryParams.onboarded),
      verified: parseBooleanOrUndefined(queryParams.verified),
    },
    fetchPolicy: "cache-and-network",
  });

  const debouncedSetQueryParams = useMemo(
    () =>
      debounce((search?: string) => {
        setQueryParams({ search, page: 1 });
      }, 300),
    []
  );

  useEffect(() => {
    debouncedSetQueryParams(searchValue);
  }, [searchValue]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const search = event.target.value;
      setSearchValue(search);
    },
    []
  );

  const loadMore = useCallback(
    (newPage: number) => {
      setQueryParams({ page: newPage });
    },
    [setQueryParams]
  );

  useEffect(() => {
    if (error) {
      ErrorService.showError("Error fetching users");
    }
  }, [error]);

  const handleFilterChange = useCallback(
    (fieldName: string, value: any) => {
      setQueryParams({ [fieldName]: value || undefined, page: 1 });
    },
    [setQueryParams]
  );

  const totalCount = data?.users.totalCount ?? 0;

  return (
    <div className="mt-8">
      <CreateUserModal
        opened={createUserModalOpened}
        close={closeCreateUserModal}
        onCreated={() => refetch()}
      />
      <UpdateUserModal
        opened={updateModalOpened}
        close={() => {
          setSelectedUser(null);
          closeUpdateModal();
        }}
        onUpdated={() => {
          setSelectedUser(null);
          refetch();
        }}
        user={selectedUser}
      />
      <div className="flex md:items-center gap-4 flex-col md:flex-row mt-8">
        <div className="flex flex-col md:flex-row gap-6 w-full flex-1">
          <Input
            rightSection={loading && <Loader color="#222222" size={16} />}
            onChange={handleSearchChange}
            value={searchValue}
            placeholder={"Search users"}
            className="w-full flex-1"
          />
          <Button
            onClick={openCreateUserModal}
            icon={<IconPlus stroke={3} size={16} className=" stroke-white" />}
            title={"Add new user"}
          />
        </div>
      </div>
      <div className="mt-8">
        <ReactTable<User>
          manualFiltering={true}
          withFilters
          loading={loading}
          onRowClick={(user) => {
            setSelectedUser(user);
          }}
          columns={usersColumns}
          data={data?.users.nodes ?? []}
          initialPageSize={queryParams.limit}
          emptyMessage={"No users found"}
          filterOnChange={handleFilterChange}
        />
        <Pagination
          className="mt-6"
          classNames={{
            control:
              "text-black hover:bg-black hover:text-white border-medium-black data-[active=true]:bg-black data-[active=true]:text-white",
          }}
          size="sm"
          value={queryParams.page}
          total={Math.ceil(totalCount / queryParams.limit)}
          onChange={loadMore}
        />
      </div>
    </div>
  );
};

export default UsersTable;
