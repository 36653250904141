import { useTranslations } from "use-intl";
import Text from "./Text";
import Info from "./Info";

const isIOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream
  );
};

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return (
    ua.includes("safari") && !ua.includes("chrome") && !ua.includes("android")
  );
};

const getIOSVersion = () => {
  const match = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return match ? parseInt(match[1], 10) : null;
};

const isIOS18OrHigher = () => {
  const version = getIOSVersion();
  return version !== null && version >= 18;
};

const TechnicalIssueAlert = () => {
  const showAlert = isIOS() && isSafari() && isIOS18OrHigher();
  const t = useTranslations("TechnicalIssueAlert");

  return (
    <>
      {showAlert && (
        <Info className="bg-light-orange my-4" name="technicalIssueAlert1">
          <Text size="lg" className="font-bold">
            {t("title")}
          </Text>
          <Text className="mt-2">{t("start")}</Text>
          <ul className="list-disc my-2 ml-6">
            <li>
              <Text>{t("one")}</Text>
            </li>
            <Text className="-ml-4">{t("orAnd")}</Text>
            <li>
              <Text>{t("two")}</Text>
            </li>
          </ul>
          <Text>{t("conculsion")}</Text>
        </Info>
      )}
    </>
  );
};

export default TechnicalIssueAlert;
