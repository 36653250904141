import { CellContext, ColumnDef } from "@tanstack/react-table";
import { Text } from "../common";
import { User } from "../types/user.type";

export const usersColumns: ColumnDef<User, any>[] = [
  {
    header: "Full name",
    accessorKey: "fullName",
    cell: (props: CellContext<User, string>) => (
      <Text size="sm">{props.getValue()}</Text>
    ),
    enableColumnFilter: false, // disable column filtering for this column
  },
  {
    header: "Email",
    accessorKey: "email",
    cell: (props: CellContext<User, string>) => (
      <Text size="sm">{props.getValue()}</Text>
    ),
    enableColumnFilter: false, // disable column filtering for this column
  },
  {
    header: "Role",
    accessorKey: "role",
    cell: (props: CellContext<User, string>) => (
      <Text size="sm">{props.getValue()}</Text>
    ),
    enableColumnFilter: false, // disable column filtering for this column
  },
  {
    header: "Album count",
    accessorKey: "albumCount",
    cell: (props: CellContext<User, number>) => (
      <Text size="sm">{props.getValue()}</Text>
    ),
    enableColumnFilter: false, // disable column filtering for this column
  },
  {
    header: "Two factor enabled",
    accessorKey: "isTwoFactorAuthEnabled",
    enableColumnFilter: false,
    cell: (props: CellContext<User, Boolean>) => (
      <Text size="sm">{props.getValue() ? "Yes" : "No"}</Text>
    ),
    meta: {
      filterType: "select",
      filterOptions: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
    },
  },
  {
    header: "News and releases",
    accessorKey: "newsAndReleases",
    enableColumnFilter: false,
    cell: (props: CellContext<User, Boolean>) => (
      <Text size="sm">{props.getValue() ? "Yes" : "No"}</Text>
    ),
    meta: {
      filterType: "select",
      filterOptions: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
    },
  },
  {
    header: "Verified",
    accessorKey: "verified",
    enableColumnFilter: true,
    cell: (props: CellContext<User, Date>) => (
      <Text size="sm">{props.getValue() ? "Yes" : "No"}</Text>
    ),
    meta: {
      filterType: "select",
      filterOptions: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
    },
  },
  {
    header: "Onboarded",
    accessorKey: "onboarded",
    enableColumnFilter: true,
    cell: (props: CellContext<User, Date>) => (
      <Text size="sm">{props.getValue() ? "Yes" : "No"}</Text>
    ),
    meta: {
      filterType: "select",
      filterOptions: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
    },
  },
  {
    header: "Banned",
    accessorKey: "banned",
    enableColumnFilter: true,
    cell: (props: CellContext<User, Boolean>) => (
      <Text size="sm">{props.getValue() ? "Yes" : "No"}</Text>
    ),
    meta: {
      filterType: "select",
      filterOptions: [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ],
    },
  },
];
