import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslations } from "use-intl";
import { useLocalizedNavigate, useSearchParamsState } from "../../../hooks";
import { useQuery } from "@apollo/client";
import { PaginatedType } from "../../../types/paginated.type";
import { Album } from "../../../types/album.type";
import { OperationType } from "../../../types/operation-type.enum";
import { allAlbumsQuery } from "../../../graphql/albumQueries";
import { ErrorService } from "../../../services";
import { Input, ReactTable } from "../../../common";
import { Loader, Pagination } from "@mantine/core";
import { albumColumns } from "../../../columns/AlbumColumns";
import debounce from "lodash/debounce";
import { EventType } from "../../../types/event.enum";
import { Plan } from "../../../types/plan.enum";

const AlbumsTable: React.FC = () => {
  const t = useTranslations("AdminAlbums");
  const navigate = useLocalizedNavigate();
  const [queryParams, setQueryParams] = useSearchParamsState<{
    search: string;
    page: number;
    limit: number;
    type?: EventType;
    plan?: Plan;
  }>({
    search: "",
    page: 1,
    limit: 12,
    type: undefined,
    plan: undefined,
  });

  const [searchValue, setSearchValue] = useState(queryParams.search);

  const { loading, error, data } = useQuery<
    { albums: PaginatedType<Album> },
    {
      first?: number;
      offset?: number;
      id?: string;
      name?: string;
      operationType?: OperationType;
      shortId?: string;
      user?: string;
      type?: EventType;
      plan?: Plan;
    }
  >(allAlbumsQuery, {
    fetchPolicy: "no-cache",
    variables: {
      first: queryParams.limit,
      offset: (queryParams.page - 1) * queryParams.limit,
      type: queryParams.type,
      plan: queryParams.plan,
      ...(queryParams.search && {
        search: String(queryParams.search),
      }),
    },
  });

  // Set search value to queryParams.search if it is different from searchValue
  useEffect(() => {
    if (queryParams.search !== searchValue) {
      setSearchValue(queryParams.search);
    }
  }, [queryParams.search]);

  useEffect(() => {
    if (error) {
      ErrorService.showError(t("errorFetching"));
    }
  }, [error, t]);

  const debouncedSetQueryParams = useMemo(
    () =>
      debounce((search: string) => {
        setQueryParams({ search, page: 1 });
      }, 300),
    []
  );

  useEffect(() => {
    debouncedSetQueryParams(searchValue);
  }, [searchValue]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const search = event.target.value;
      setSearchValue(search);
    },
    []
  );

  const handleFilterChange = useCallback(
    (fieldName: string, value: any) => {
      setQueryParams({ [fieldName]: value || undefined, page: 1 });
    },
    [setQueryParams]
  );

  const loadMore = useCallback(
    (newPage: number) => {
      setQueryParams({ page: newPage });
    },
    [setQueryParams]
  );

  const totalCount = data?.albums.totalCount ?? 0;

  return (
    <div className="flex-1 flex flex-col mt-8">
      <div className="flex justify-between md:items-center gap-4 flex-col md:flex-row">
        <div className="flex flex-col md:flex-row gap-6 w-full">
          <Input
            rightSection={loading && <Loader color="#222222" size={16} />}
            onChange={handleSearchChange}
            value={searchValue}
            placeholder={t("search")}
            className="w-full flex-1"
          />
        </div>
      </div>
      <div className="mt-8">
        <ReactTable<Album>
          withFilters={true}
          onRowClick={(album) => {
            navigate(`/admin/album-settings/${album.id}`, { state: { album } });
          }}
          manualFiltering={true}
          loading={loading}
          columns={albumColumns}
          data={data?.albums.nodes ?? []}
          initialPageSize={queryParams.limit}
          emptyMessage={t("noAlbums")}
          filterOnChange={handleFilterChange}
        />
        <Pagination
          className="mt-6"
          classNames={{
            control:
              "text-black hover:bg-black hover:text-white border-medium-black data-[active=true]:bg-black data-[active=true]:text-white",
          }}
          size="sm"
          value={queryParams.page}
          total={Math.ceil(totalCount / queryParams.limit)}
          onChange={loadMore}
        />
      </div>
    </div>
  );
};

export default AlbumsTable;
