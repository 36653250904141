import { useSelector } from "react-redux";
import { useTranslations } from "use-intl";
import { RootState } from "../../store";
import { Button, Text } from "..";
import moment from "moment";
import { Plan } from "../../types/plan.enum";
import { useLocalizedNavigate } from "../../hooks";

const NavigationFooter = ({ closeMenu }: { closeMenu: () => void }) => {
  const t = useTranslations("Album");
  const album = useSelector((state: RootState) => state.album.album);
  const navigate = useLocalizedNavigate();

  const albumIsExpired =
    album?.expirationDate && new Date(album?.expirationDate) < new Date();
  const daysLeft =
    album?.expirationDate &&
    moment(album?.expirationDate)
      .startOf("day")
      .diff(moment().startOf("day"), "days");

  return (
    <div>
      {album && (
        <div className=" rounded-lg bg-light-beige p-3 mt-3">
          <Text className=" font-semibold text-sm">
            {albumIsExpired ? t("noPlan") : t(album.plan)}
          </Text>
          <Text className=" text-xs mt-2 mb-4">
            {albumIsExpired
              ? t("upgradeToContinueUse")
              : album.expirationDate
              ? [
                  t("uploadsWillBeStored"),
                  moment(album.expirationDate).format("DD-MM-YYYY"),
                  daysLeft && daysLeft <= 30
                    ? ` (${daysLeft} ${t("daysLeft")})`
                    : "",
                ]
              : t("notStarted")}
            .
          </Text>

          <Button
            fullWidth
            small
            onClick={() => {
              navigate("/pricing");
              closeMenu();
            }}
            title={
              album.plan === Plan.free || albumIsExpired
                ? t("upgrade")
                : t("extend")
            }
          />
        </div>
      )}
    </div>
  );
};

export default NavigationFooter;
