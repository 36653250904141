import { AlbumSizes } from "../types/album.type";
import { useTranslations } from "use-intl";
import { Text } from ".";
import { LocalizedLink } from "../router";
import { Tooltip } from "@mantine/core";

const BadgeAlbumSize = ({ sizes }: { sizes: AlbumSizes }) => {
  const t = useTranslations("Photos");

  //Take sizes and convert them to gb as sizes are in bytes
  const maxSize = sizes.maxSize / 1024 / 1024 / 1024;
  const currentSize = sizes.currentSize / 1024 / 1024 / 1024;

  //Calculate percentage of used space
  const percentage = (currentSize / maxSize) * 100;

  return (
    <Tooltip
      events={{
        hover: true,
        focus: true,
        touch: true,
      }}
      label={`${t("storageWord")} ${currentSize.toPrecision(
        2
      )} GB of ${maxSize.toFixed(2)} GB used (${percentage.toFixed(0)}%)`}
    >
      <div className="px-4 py-2 rounded-lg bg-light-beige">
        <Text wrap="nowrap" size={"xs"}>
          <span className="md:hidden">{t("storageUsedMobile")}</span>
          <span className="hidden md:inline">{t("storageUsed")}</span>:{" "}
          {percentage.toFixed(0) + "%"}.
          <LocalizedLink
            to="/pricing"
            className="font-semibold ml-2 hover:underline"
          >
            {t("upgrade")}
          </LocalizedLink>
        </Text>
      </div>
    </Tooltip>
  );
};

export default BadgeAlbumSize;
