import Input from "../Input";

interface CountFilterProps {
  column: {
    getFilterValue: () => string | undefined;
  };
  onChange: (value: any) => void;
}

const CountFilter: React.FC<CountFilterProps> = ({
  column: { getFilterValue },
  onChange,
}) => {
  const filterValue = (() => {
    const value = getFilterValue();
    if (typeof value === "string") {
      return JSON.parse(value) as { from?: number; to?: number };
    }
    return value ?? { from: undefined, to: undefined };
  })();

  const handleFromChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : null;
    if (onChange) {
      onChange({ from: value, to: filterValue.to });
    }
  };

  const handleToChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? parseInt(e.target.value, 10) : null;
    if (onChange) {
      onChange({ from: filterValue.from, to: value });
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      <Input
        type="number"
        value={filterValue.from || ""}
        onChange={handleFromChange}
        placeholder="From"
        min={0}
      />
      <Input
        type="number"
        value={filterValue.to || ""}
        onChange={handleToChange}
        placeholder="To"
        min={0}
      />
    </div>
  );
};

export default CountFilter;
