import { useMutation } from "@apollo/client";
import {
  EmailShareButton,
  FacebookShareButton,
  Input,
  Text,
  Title,
  WhatsAppShareButton,
} from ".";
import { CopyButton, Modal } from "@mantine/core";
import { useTranslations } from "use-intl";
import { Album, UpdateAlbumInput } from "../types/album.type";
import { updateAlbumMutation } from "../graphql/albumQueries";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { setAlbum } from "../store/album.reducer";

const ShareAlbumModal = ({
  opened,
  close,
  albumUrl,
  photoWall,
}: {
  opened: boolean;
  close: () => void;
  albumUrl: string;
  photoWall?: boolean;
}) => {
  const album = useSelector((state: RootState) => state.album.album);
  const t = useTranslations("Album");
  const dispatch = useDispatch();
  const [updateAlbum] = useMutation<
    { updateAlbum: Album },
    { input: UpdateAlbumInput }
  >(updateAlbumMutation);

  // Save info if user has shared his album, needed for getting started block on dashboard
  const onShareClick = () => {
    if (album && !album.invitationDate) {
      updateAlbum({
        variables: {
          input: { id: album.id, invitationDate: moment().toDate() },
        },
      }).then((response) => {
        response.data?.updateAlbum &&
          dispatch(setAlbum(response.data?.updateAlbum));
      });
    }
  };

  const paswordIncluded = album?.passwordInInvite ? album?.password : undefined;

  return (
    <Modal
      centered
      withCloseButton={false}
      opened={opened}
      onClose={close}
      size={370}
      padding={0}
      radius={12}
    >
      <div className="p-6 px-4 md:px-6">
        <Title size="H5">
          {photoWall ? t("shareThisPhotowall") : t("shareThisAlbum")}
        </Title>
        <div className="mt-4 flex flex-col justify-end gap-2">
          <div className="flex flex-1 md:w-auto w-full bg-light-gray rounded-lg items-center px-4 py-3 justify-between">
            <Input
              className="flex-1"
              styles={{
                input: {
                  border: "none",
                  fontSize: 16,
                  backgroundColor: "transparent",
                  height: "auto",
                  minHeight: "auto",
                  lineHeight: 0,
                  padding: 0,
                },
              }}
              onChange={() => {}}
              value={albumUrl}
            />
            <CopyButton value={albumUrl}>
              {({ copied, copy }) => (
                <Text
                  size="sm"
                  onClick={() => {
                    onShareClick();
                    copy();
                  }}
                  className="font-semibold ml-4 cursor-pointer"
                >
                  {copied ? t("copied") : t("copy")}
                </Text>
              )}
            </CopyButton>
          </div>
          <div className="flex justify-between mt-6">
            <WhatsAppShareButton
              onClick={onShareClick}
              password={paswordIncluded}
              link={albumUrl}
            />
            <FacebookShareButton onClick={onShareClick} link={albumUrl} />
            <EmailShareButton
              password={paswordIncluded}
              onClick={onShareClick}
              link={albumUrl}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShareAlbumModal;
