import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslations } from "use-intl";
import { Button, Text } from "..";
import { IEdgeType } from "../../types/paginated.type";
import { Image } from "../../types/image.type";
import {
  IconArchive,
  IconCheck,
  IconDownload,
  IconPhotoUp,
  IconTrash,
} from "@tabler/icons-react";
import { ActionIcons } from ".";
import useImageActions from "../../hooks/useImageActions";
import { ErrorService } from "../../services";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";
import { useAuth, useIsSmallScreen } from "../../hooks";
import { IconProps } from "./ActionIcons";
import { RootState } from "../../store";
import { useSelector } from "react-redux";

interface SelectedProps {
  selectedImages: string[];
  allImages: IEdgeType<Image>[];
  selectImages: (imageIds: string[]) => void;
  onUpdated: (images: Image[]) => void;
  onRemoved: (ids: string[]) => void;
  albumMode?: boolean;
}

const Selected: React.FC<SelectedProps> = ({
  selectedImages,
  allImages,
  selectImages,
  onUpdated,
  onRemoved,
  albumMode = false,
}) => {
  const album = useSelector((state: RootState) => state.album.album);
  const { user } = useAuth();
  const t = useTranslations("Photos");
  const [selectedStyle, setSelectedStyle] = useState<React.CSSProperties>({});
  const {
    remove,
    unPublish,
    publish,
    approve,
    loadingApprove,
    loadingPublish,
    loadingRemove,
    loadingUnPublish,
  } = useImageActions();
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const isSmallScreen = useIsSmallScreen();

  const handleDownload = useCallback(async () => {
    setDownloadLoading(true);
    const batchSize = 100; // Adjust based on your average ID length

    for (let i = 0; i < selectedImages.length; i += batchSize) {
      const batch = selectedImages.slice(i, i + batchSize);
      const downloadUrl = new URL(
        `${import.meta.env.VITE_API_URL}/image/download/multiple/${album?.id}`
      );

      batch.forEach((id) => downloadUrl.searchParams.append("imageIds", id));

      if (user && user.isGuest) {
        downloadUrl.searchParams.append("guestUserId", user.id);
      }

      // Trigger download for this batch
      const link = document.createElement("a");
      link.href = downloadUrl.toString();
      link.setAttribute("download", `images_batch_${i / batchSize + 1}.zip`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Wait a bit before next batch to avoid overwhelming the browser
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }

    setDownloadLoading(false);
    ErrorService.showMessage(t("imagesDownloading"));
  }, [selectedImages, album, user, t]);

  const handleAction = useCallback(
    async (
      action: (id: string) => Promise<Image | undefined>,
      successMessage: string,
      errorMessage: string
    ) => {
      try {
        const updatedImages = await Promise.all(
          selectedImages.map((imageId) => action(imageId))
        );
        selectImages([]);
        onUpdated(
          updatedImages.filter((img): img is Image => img !== undefined)
        );
        ErrorService.showMessage(successMessage);
      } catch {
        ErrorService.showError(errorMessage);
      }
    },
    [selectedImages, selectImages, onUpdated]
  );

  const handlePublish = useCallback(
    () => handleAction(publish, t("imagePublished"), t("errorPublishing")),
    [handleAction, publish, t]
  );

  const handleUnPublish = useCallback(
    () =>
      handleAction(unPublish, t("imageUnpublished"), t("errorUnpublishing")),
    [handleAction, unPublish, t]
  );

  const handleApprove = useCallback(
    () => handleAction(approve, t("imageApproved"), t("errorApproving")),
    [handleAction, approve, t]
  );

  const handleRemove = useCallback(async () => {
    try {
      const removedIds = await Promise.all(
        selectedImages.map(async (imageId) => {
          const removed = await remove(imageId);
          return removed ? imageId : undefined;
        })
      );
      selectImages([]);
      onRemoved(removedIds.filter((id): id is string => id !== undefined));
      close();
    } catch {
      ErrorService.showError(t("errorRemoval"));
    }
  }, [selectedImages, remove, selectImages, onRemoved, close, t]);

  const handleSelectAll = useCallback(() => {
    const newSelection =
      selectedImages.length === allImages.length
        ? []
        : allImages.map((image) => image.node.id);
    selectImages(newSelection);
  }, [selectedImages, allImages, selectImages]);

  const updateSelectedPosition = useCallback(() => {
    const container = document.getElementById("image-grid");
    if (container) {
      const containerRect = container.getBoundingClientRect();
      const leftPosition = containerRect.left + containerRect.width / 2;

      setSelectedStyle(
        isSmallScreen
          ? {
              width: window.innerWidth,
              left: 0,
              bottom: 0,
            }
          : {
              width: container.offsetWidth,
              left: `${leftPosition}px`,
              bottom: "56px",
              transform: "translateX(-50%)",
            }
      );
    }
  }, [isSmallScreen]);

  useEffect(() => {
    window.addEventListener("resize", updateSelectedPosition);
    updateSelectedPosition();
    return () => {
      window.removeEventListener("resize", updateSelectedPosition);
    };
  }, [updateSelectedPosition]);

  const notApproved = useMemo(
    () => allImages.some((image) => !image.node.approved),
    [allImages]
  );
  const notPublished = useMemo(
    () => allImages.some((image) => !image.node.published),
    [allImages]
  );
  const published = useMemo(
    () => allImages.some((image) => image.node.published),
    [allImages]
  );

  const iconSize = isSmallScreen ? 24 : 16;

  if (selectedImages.length === 0) return null;

  const renderActionIcons = (): IconProps[] => {
    const baseIcons: IconProps[] = [
      {
        loading: downloadLoading,
        icon: IconDownload,
        onClick: handleDownload,
        className: "stroke-white bg-black download",
        width: iconSize,
        height: iconSize,
        tooltip: t("download"),
      },
    ];

    if (!albumMode) {
      if (notApproved) {
        baseIcons.push({
          loading: loadingApprove,
          icon: IconCheck,
          onClick: handleApprove,
          className: "stroke-white bg-black approve",
          width: iconSize,
          height: iconSize,
          tooltip: t("approve"),
        });
      }
      if (notPublished) {
        baseIcons.push({
          loading: loadingPublish,
          icon: IconPhotoUp,
          onClick: handlePublish,
          className: "stroke-green bg-black publish",
          width: iconSize,
          height: iconSize,
          tooltip: t("publish"),
        });
      }
      if (published) {
        baseIcons.push({
          loading: loadingUnPublish,
          icon: IconArchive,
          onClick: handleUnPublish,
          className: "stroke-white bg-black unpublish",
          width: iconSize,
          height: iconSize,
          tooltip: t("unpublish"),
        });
      }
      baseIcons.push({
        icon: IconTrash,
        onClick: open,
        className: "stroke-red bg-black remove",
        width: iconSize,
        height: iconSize,
        tooltip: t("remove"),
      });
    }

    return baseIcons;
  };

  return (
    <div
      id="selector"
      style={selectedStyle}
      className="fixed z-10 md:h-11 py-2 md:py-0 w-full max-w-[800px] md:rounded-lg bg-black flex flex-row items-center justify-between px-4"
    >
      <Modal
        radius={8}
        withCloseButton={false}
        opened={opened}
        onClose={close}
        centered
      >
        <Text color="gray">{t("confirmRemoval")}</Text>
        <div className="flex items-end justify-end gap-2 mt-6">
          <Button
            small
            type="outline"
            color="black"
            onClick={close}
            title={t("cancel")}
          />
          <Button
            loading={loadingRemove}
            small
            onClick={handleRemove}
            color="red"
            title={t("remove")}
          />
        </div>
      </Modal>
      <div className="flex gap-2 md:flex-row flex-col">
        <Text size="sm" color="white">
          {t.rich("uploadsSelected", {
            selected: () => selectedImages.length,
            total: () => allImages.length,
          })}
        </Text>
        <Text
          size="sm"
          color="white"
          className="font-semibold cursor-pointer"
          onClick={handleSelectAll}
        >
          {selectedImages.length === allImages.length
            ? t("deselectAll")
            : t("selectAllVisible")}
        </Text>
      </div>
      <div className="flex items-center">
        <ActionIcons loaderColor="white" icons={renderActionIcons()} />
        <Text
          onClick={() => selectImages([])}
          className="font-semibold cursor-pointer md:ml-8 ml-4"
          color="white"
          size="sm"
        >
          {t("cancel")}
        </Text>
      </div>
    </div>
  );
};

export default Selected;
