import { ApolloError } from "@apollo/client";
import { LOCALES, Messages } from "../locales/Locales";

/**
 * Get image url with spaces url
 * @param url url of spaces image
 * @returns image url with spaces url
 */
export const getImageUrl = (url: string) =>
  `${import.meta.env.VITE_SPACES_URL}${url}`;

/**
 * Check if the error is due to unauthenticated status
 */
export function isUnauthenticatedError(error: ApolloError): boolean {
  return error.graphQLErrors?.some(
    (e) =>
      e?.extensions?.code === "UNAUTHENTICATED" || e?.message === "Unauthorized"
  );
}

/**
 * Check if the error is due to forbidden status
 */
export function isForbiddenError(error: ApolloError): boolean {
  return error.graphQLErrors?.some(
    (e) => e?.extensions?.code === "FORBIDDEN" || e?.message === "Forbidden"
  );
}

/**
 * Check if the error is due to not found status
 */
export function isNotFoundError(error: ApolloError): boolean {
  return error.graphQLErrors?.some((e) => e?.extensions?.status === 404);
}

/**
 * Check for file type
 *
 * @param file file to check
 * @returns wheter the file is an image, video or unknown
 */
export function checkMediaType(
  file: File
): Promise<"image" | "video" | "unknown"> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = function (evt) {
      if (evt?.target?.readyState === FileReader.DONE) {
        const array = new Uint8Array(evt.target.result as ArrayBuffer);
        const magicNumber = array.subarray(0, 12); // Read more bytes to check for MOV
        // Check for JPEG
        if (
          magicNumber[0] === 0xff &&
          magicNumber[1] === 0xd8 &&
          magicNumber[2] === 0xff
        ) {
          resolve("image");
        }
        // Enhanced Check for PNG
        else if (
          magicNumber[0] === 0x89 &&
          magicNumber[1] === 0x50 &&
          magicNumber[2] === 0x4e &&
          magicNumber[3] === 0x47 &&
          magicNumber[4] === 0x0d &&
          magicNumber[5] === 0x0a &&
          magicNumber[6] === 0x1a &&
          magicNumber[7] === 0x0a
        ) {
          resolve("image");
        }
        // Check for WebP
        else if (
          magicNumber[0] === 0x52 &&
          magicNumber[1] === 0x49 &&
          magicNumber[2] === 0x46 &&
          magicNumber[3] === 0x46 &&
          magicNumber[8] === 0x57 &&
          magicNumber[9] === 0x45 &&
          magicNumber[10] === 0x42 &&
          magicNumber[11] === 0x50
        ) {
          resolve("image");
        }
        // Check for MP4
        else if (
          magicNumber[0] === 0x00 &&
          magicNumber[1] === 0x00 &&
          magicNumber[2] === 0x00 &&
          (magicNumber[3] === 0x18 || magicNumber[3] === 0x20)
        ) {
          resolve("video");
        }
        // Check for WebM
        else if (
          magicNumber[0] === 0x1a &&
          magicNumber[1] === 0x45 &&
          magicNumber[2] === 0xdf &&
          magicNumber[3] === 0xa3
        ) {
          resolve("video");
        }
        // Check for MOV
        else if (
          magicNumber[4] === 0x66 &&
          magicNumber[5] === 0x74 &&
          magicNumber[6] === 0x79 &&
          magicNumber[7] === 0x70
        ) {
          resolve("video");
        }
        // Add additional checks here for other formats if needed
        else {
          resolve("unknown");
        }
      }
    };

    // Read the first 12 bytes of the file
    const blob = file.slice(0, 12);
    reader.readAsArrayBuffer(blob);
  });
}

export const removeLocaleFromPath = (pathname: string) => {
  const segments = pathname.split("/").filter(Boolean);
  if (LOCALES.includes(segments[0] as keyof Messages)) {
    // If the first segment is a locale, remove it
    segments.shift();
  }
  return `${segments.join("/")}`; // Reassemble the pathname without the locale
};

export const parseBooleanOrUndefined = (
  value: string | boolean | undefined
): boolean | undefined => {
  if (value === undefined) return undefined;
  return value === "true" || value === true;
};
