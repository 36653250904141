import React from "react";
import { Font, fontFamilyMap } from "../types/font.enum";

type TitleSize = "H1" | "H2" | "H3" | "H4" | "H5";
type Wrap = "nowrap" | "wrap";

const sizeClasses: Record<TitleSize, string> = {
  H1: "text-[32px] leading-9 md:text-[58px] md:leading-[69px]",
  H2: "text-2xl md:text-4xl",
  H3: "text-[20px] md:text-2xl",
  H4: "text-lg md:text-[20px]",
  H5: "text-lg",
};

interface TitleProps {
  children: React.ReactNode;
  size: TitleSize;
  className?: string;
  wrap?: Wrap;
  font?: Font;
  style?: React.CSSProperties;
}

const Title: React.FC<TitleProps> = ({
  children,
  size,
  className,
  wrap = "wrap",
  font = Font.Poppins,
  style,
}) => {
  const customStyle: React.CSSProperties = {
    fontFamily: fontFamilyMap[font],
    ...style,
  };

  return (
    <p
      style={customStyle}
      className={`${
        className ? className : ""
      } font-semibold tracking-tight text-black ${sizeClasses[size]} ${
        wrap === "wrap" ? "whitespace-pre-line" : "whitespace-nowrap"
      }`}
    >
      {children}
    </p>
  );
};

export default Title;
