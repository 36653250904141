import { Tabs } from "@mantine/core";
import { IconAlbum } from "@tabler/icons-react";
import { AlbumsTable } from "./components";
import { Helmet } from "react-helmet-async";

const AlbumsPage = () => {
  return (
    <div className="flex-1 md:px-6 px-4 flex flex-col">
      <Helmet>
        <title>Rompolo - Albums</title>
      </Helmet>
      <Tabs defaultValue={"albums"} color="#222" radius="md">
        <Tabs.List>
          <Tabs.Tab
            leftSection={<IconAlbum className="stroke-black" size={16} />}
            value={"albums"}
          >
            Albums
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="albums">
          <AlbumsTable />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default AlbumsPage;
